import { useEffect, useState } from 'react';

import cn from 'classnames';
import { Controller, useForm, useWatch } from 'react-hook-form';

import { Button, Input, InputGroup, PhoneNumberPicker, Select } from 'components/FormComponents';

import { useGetCompanyUsersQuery } from 'api/user-api';

export const CandidateInformation = ({
  candidate,
  company,
  brand,
  teams,
  user,
  onUpdate = () => {},
}) => {
  const { register, reset, control, handleSubmit } = useForm({ defaultValues: candidate });

  useEffect(() => reset(candidate), [candidate]);

  const team_id = useWatch({ control, name: 'team_id' });
  const user_id = useWatch({ control, name: 'user_id' });

  const { data: companyUsers } = useGetCompanyUsersQuery(user?.id, {
    refetchOnMountOrArgChange: true,
  });

  // Reset candidate details
  useEffect(() => reset(candidate), [candidate]);

  return (
    <form className="background-secondary u-padding" onSubmit={handleSubmit(onUpdate)}>
      <div className={cn('card', 'card-with-border', 'u-padding', 'u-margin-bottom')}>
        <div>
          <div className={cn('u-bold')}>Candidate Assignment</div>
          <InputGroup
            title="The user who is assigned to manage the candidate and receive notifications. This can be updated at any time."
            className="u-margin-bottom--0">
            <Select
              name="user_id"
              placeholder="User"
              register={register}
              value={user_id}
              useDefault
              useSearch>
              {companyUsers?.map(user => (
                <Select.Item key={user.id} value={user.id}>
                  {user.first_name} {user.last_name} • {user.email}
                </Select.Item>
              ))}
            </Select>
          </InputGroup>
        </div>
      </div>

      <div className={cn('card', 'card-with-border', 'u-padding')}>
        <div className="inline">
          <InputGroup title="First name">
            <Input
              name="first_name"
              register={register}
              validators={{ required: true }}
              placeholder="John"
            />
          </InputGroup>
          <InputGroup title="Last name">
            <Input
              name="last_name"
              register={register}
              validators={{ required: true }}
              placeholder="Doe"
            />
          </InputGroup>
        </div>
        <InputGroup title="Email">
          <Input
            name="email"
            register={register}
            validators={{ required: true }}
            placeholder="john@checkmate.com"
          />
        </InputGroup>
        <InputGroup title="Contact Number">
          <Controller
            control={control}
            name="contact_number"
            render={({ field: { onChange, value } }) => (
              <PhoneNumberPicker
                value={value}
                onChange={onChange}
                defaultCountry={company?.country}
              />
            )}
          />
        </InputGroup>
        <InputGroup title="Job title">
          <Input name="job_role" register={register} placeholder="General Manager" />
        </InputGroup>
        <InputGroup title="Department">
          <Input name="team_name" register={register} placeholder="Department" />
        </InputGroup>

        {candidate.cc_notification_emails && (
          <InputGroup title="CC Notification Emails">
            <Input name="cc_notification_emails" register={register} />
          </InputGroup>
        )}

        <InputGroup title="Brand">
          <Input disabled={true} inputProps={{ value: brand?.name }} />
        </InputGroup>

        {teams.length > 1 && (
          <InputGroup title="Team" className="u-margin-bottom--large">
            <Select
              name="team_id"
              placeholder="Team"
              register={register}
              value={team_id}
              useDefault>
              {teams.map(team => (
                <Select.Item key={team.id} value={team.id}>
                  {team.name}
                </Select.Item>
              ))}
            </Select>
          </InputGroup>
        )}

        <InputGroup title="Document">
          <Input disabled={true} register={register} name="document_name" placeholder="-" />
        </InputGroup>

        <Button submit className="u-width-100 u-margin-top--large u-margin-bottom">
          Update
        </Button>
      </div>
    </form>
  );
};
