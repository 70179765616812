import { useEffect, useState } from 'react';

import cn from 'classnames';
import FeatherIcon from 'feather-icons-react';

import styles from './StarRating.module.scss';

export const StarRating = ({
  options: { use_custom_stars, stars, label_start, label_end },
  value,
  onChange,
  disabled,
}) => {
  const [starsArray, setStarsArray] = useState([]);

  const handleClick = index => {
    if (disabled) return;
    onChange(index);
  };

  useEffect(() => {
    const starsInt = use_custom_stars ? parseInt(stars) : 5;
    const array = Array(starsInt)
      .fill()
      .map((_, index) => index + 1);
    setStarsArray(array);
  }, [use_custom_stars, stars]);

  return (
    <div className={cn(styles.root, { [styles.rootWithLabel]: label_start || label_end })}>
      <div className={styles.starsWrapper}>
        {starsArray.map(index => (
          <FeatherIcon
            className={cn(styles.star, { [styles.disabled]: disabled })}
            key={index}
            icon="star"
            size={35}
            color={index <= value ? '#4932d0' : '#e5e5e5'}
            fill={index <= value ? '#4932d0' : '$surface-secondary'}
            onClick={() => handleClick(index)}
          />
        ))}
      </div>
      <label className={styles.labelStart}>{label_start}</label>
      <label className={styles.labelEnd}>{label_end}</label>
    </div>
  );
};
