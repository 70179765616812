import { useState } from 'react';
import { useEffect } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AdminRoles } from 'constants/permissions';
import { useDocumentTitle } from 'hooks/document-title';
import AdminPage from 'layouts/AdminPage/AdminPage';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';

import { Table } from 'components/Table/Table';

import { useLazyGetAllCandidateChecksQuery } from 'api/candidate-checks-api';
import { useGetCheckTypesQuery } from 'api/check-types-api';
import { useGetAllCompaniesListQuery } from 'api/company-api';

const AdminCandidateChecks = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [candidateChecks, setCandidateChecks] = useState([]);
  const [companiesOptions, setCompaniesOptions] = useState([]);

  const [getAllCandidateChecks] = useLazyGetAllCandidateChecksQuery();
  const { data: companiesData } = useGetAllCompaniesListQuery();
  const { data: checkTypes } = useGetCheckTypesQuery();

  const [checkType, setCheckType] = useState();

  useDocumentTitle('Candidate Checks');

  const [pagination, setPagination] = useState({
    page: 1,
    records_per_page: 15,
    total_pages: 1,
    total_records: 0,
  });

  useEffect(() => {
    if (!companiesData) return;

    setCompaniesOptions([
      { name: t('common.all', { element: 'companies' }), value: 'all' },
      ...companiesData.result.map(c => ({ name: c.name, value: c.id })),
    ]);
  }, [companiesData]);

  const fetchCandidateChecks = async (params = {}) => {
    setLoading(true);
    const { result } = await getAllCandidateChecks(params).unwrap();
    setPagination(result.pagination);
    setCandidateChecks(result.candidate_checks);
    if (params.check_type && params.check_type !== 'all')
      setCheckType(checkTypes.find(checkType => checkType.slug === params.check_type));
    setLoading(false);
  };

  return (
    <AdminPage
      loading={loading}
      title="Checks"
      allowedRoles={[AdminRoles.SuperAdmin, AdminRoles.Support]}>
      {checkTypes?.length > 0 && (
        <Table.Context
          onQueryUpdate={fetchCandidateChecks}
          defaultQuery={{ page: 1, status: 'all', search: '', company: 'all' }}>
          <Table.TableContent className="u-margin--large">
            <Table.Filters>
              <Table.LeftFilters>
                <Table.Search placeholder="Search for a candidate..." />
              </Table.LeftFilters>
              <Table.RightFilters>
                <Table.Filter
                  title="Type"
                  filterName="check_type"
                  options={[
                    { name: t('common.all', { element: 'types' }), value: 'all' },
                    ...checkTypes?.map(checkType => ({
                      name: checkType.title,
                      value: checkType.slug,
                    })),
                  ]}
                />
                <Table.Filter title="Company" filterName="company" options={companiesOptions} />
                <Table.Filter
                  title="Status"
                  filterName="status"
                  options={[
                    { name: t('common.all', { element: 'statuses' }), value: 'all' },
                    { name: t('common.inProgress'), value: 'in-progress' },
                    { name: t('common.submitted'), value: 'submitted' },
                    { name: t('common.completed'), value: 'completed' },
                    { name: t('common.overdue'), value: 'overdue' },
                    { name: t('common.canceled'), value: 'canceled' },
                  ]}
                />
              </Table.RightFilters>
            </Table.Filters>
            <Table className="u-margin--large">
              <Table.Head>
                <Table.Row key="header-row">
                  <Table.Header key="id">{t('common.id')}</Table.Header>
                  <Table.Header key="status">{t('common.status')}</Table.Header>
                  <Table.Header key="name" sortKey="candidates.first_name">
                    {t('common.name')}
                  </Table.Header>
                  <Table.Header key="email">{t('common.email')}</Table.Header>
                  <Table.Header key="created" sortKey="candidate_checks.created_at">
                    {t('common.createdAt')}
                  </Table.Header>
                  <Table.Header key="submitted" sortKey="candidate_checks.submitted_at">
                    {t('common.submittedAt')}
                  </Table.Header>
                  <Table.Header key="completed" sortKey="candidate_checks.completed_at">
                    {t('common.completedAt')}
                  </Table.Header>
                </Table.Row>
              </Table.Head>
              <Table.Body>
                {candidateChecks.map((candidateCheck, index) => {
                  const currentCheckType =
                    checkType ||
                    checkTypes.find(checkType => checkType.slug === candidateCheck.type);
                  return (
                    <Table.Row key={`check-${candidateCheck.id}-${index}`}>
                      <Table.Col>
                        <Link
                          to={`/admin/candidates/${candidateCheck.candidate_id}`}
                          className="u-link dark">
                          {candidateCheck.candidate_id}
                        </Link>
                      </Table.Col>
                      <Table.Col>
                        <OverlayTrigger overlay={<Tooltip>{currentCheckType.title}</Tooltip>}>
                          <span className={`u-txt-status-${candidateCheck.status}`}>
                            <FontAwesomeIcon icon={currentCheckType.icon} />
                          </span>
                        </OverlayTrigger>
                      </Table.Col>
                      <Table.Col>{`${candidateCheck.first_name} ${candidateCheck.last_name}`}</Table.Col>
                      <Table.Col>{candidateCheck.email}</Table.Col>
                      <Table.Col>
                        <Moment format="DD/MM/YYYY">{candidateCheck.created_at}</Moment>
                      </Table.Col>
                      <Table.Col>
                        {candidateCheck.submitted_at ? (
                          <Moment format="DD/MM/YYYY">{candidateCheck.submitted_at}</Moment>
                        ) : (
                          '-'
                        )}
                      </Table.Col>
                      <Table.Col>
                        {candidateCheck.completed_at ? (
                          <Moment format="DD/MM/YYYY">{candidateCheck.completed_at}</Moment>
                        ) : (
                          '-'
                        )}
                      </Table.Col>
                    </Table.Row>
                  );
                })}
              </Table.Body>
              <Table.Pagination {...pagination} />
            </Table>
          </Table.TableContent>
        </Table.Context>
      )}
    </AdminPage>
  );
};

export default AdminCandidateChecks;
