import { useEffect, useMemo, useRef, useState } from 'react';

import cn from 'classnames';
import { MESSAGE_STATE_ERROR, MESSAGE_STATE_SUCCESS } from 'constants/message-app-state-contants';
import { Permissions } from 'constants/permissions';
import { useDocumentTitle } from 'hooks/document-title';
import AppPage from 'layouts/AppPage/AppPage';
import { Tab, Tabs } from 'react-bootstrap';
import ReactGA from 'react-ga';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { messagePopUp } from 'api/app-slice';
import { selectCompanyChecks } from 'api/company-checks-slice';
import { selectCompany } from 'api/company-slice';
import { archiveForm, deleteForm, getForms, restoreForm, saveForm } from 'api/forms-slice';
import { selectForms } from 'api/forms-slice';
import { getTeams } from 'api/teams-slice';
import { selectTeams } from 'api/teams-slice';

import styles from './Questionnaires.module.scss';
import { ArchivedForms } from './tabs/ArchivedForms/ArchivedForms';
import { CandidateQuestionnaires } from './tabs/CandidateQuestionnaires/CandidateQuestionnaires';
import { CandidateReference } from './tabs/CandidateReference/CandidateReference';
import { CustomForms } from './tabs/CustomForms/CustomForms';
import { IQForms } from './tabs/IQForms/IQForms';
import { LibraryForms } from './tabs/LibraryForms/LibraryForms';
import { ReferenceForms } from './tabs/ReferenceForms/ReferenceForms';

const Questionnaires = () => {
  useDocumentTitle('Questionnaires');
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { tab: targetTab, id } = useParams();

  const dispatch = useDispatch();

  const company = useSelector(selectCompany);
  const forms = useSelector(selectForms);
  const companyChecks = useSelector(selectCompanyChecks);
  const teams = useSelector(selectTeams);

  const [loading, setLoading] = useState(true);
  const [tab, setTab] = useState(1);

  const [referenceCheckDetails, setReferenceCheckDetails] = useState();
  const [customReferenceEnabled, setCustomReferenceEnabled] = useState(false);
  const [candidateQuestionnaireEnabled, setCandidateQuestionnaireEnabled] = useState(false);

  const archivedForms = useMemo(() => {
    return Object.values(forms).reduce((acc, category) => {
      return acc.concat(category.filter(form => form.archived));
    }, []);
  }, [forms]);

  // Initialize
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
    Promise.all([dispatch(getTeams()), dispatch(getForms())]).then(_ => {
      setLoading(false);
    });
  }, []);

  // Set tab
  useEffect(() => setTab(targetTab), [targetTab]);

  // Set custom reference enabled
  useEffect(() => {
    setCandidateQuestionnaireEnabled(
      !!companyChecks.find(companyCheck => companyCheck.type === 'candidate_questionnaire'),
    );
    setCustomReferenceEnabled(
      !!companyChecks.find(companyCheck => companyCheck.type === 'custom_reference'),
    );
    setReferenceCheckDetails(companyChecks.find(companyCheck => companyCheck.type === 'reference'));
  }, [companyChecks]);

  // Delete form
  const handleDeleteForm = async form => {
    setLoading(true);
    await dispatch(deleteForm(form));
    dispatch(
      messagePopUp({ text: 'Form successfully deleted', state: MESSAGE_STATE_SUCCESS, hide: true }),
    );
    setLoading(false);
  };

  const handleArchiveForm = async form => {
    setLoading(true);
    try {
      await dispatch(archiveForm(form));
      await dispatch(getForms());
      dispatch(
        messagePopUp({
          text: 'Form successfully archived',
          state: MESSAGE_STATE_SUCCESS,
          hide: true,
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  const handleRestoreForm = async form => {
    setLoading(true);
    try {
      await dispatch(restoreForm(form));
      await dispatch(getForms());
      dispatch(
        messagePopUp({
          text: 'Form successfully restored',
          state: MESSAGE_STATE_SUCCESS,
          hide: true,
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  const handleSaveForm = async formData => {
    setLoading(true);
    try {
      const { payload: response } = await dispatch(saveForm(formData));
      if (response.error) throw response.error.message;
      dispatch(
        messagePopUp({ text: 'Form successfully saved', state: MESSAGE_STATE_SUCCESS, hide: true }),
      );
      return response.result.form;
    } catch (error) {
      showError(error);
    } finally {
      setLoading(false);
    }
  };

  const showError = error =>
    dispatch(messagePopUp({ text: error, state: MESSAGE_STATE_ERROR, hide: true }));

  return (
    <AppPage loading={loading} requiredPermissions={[Permissions.FormsWrite]}>
      <div className={styles.root}>
        <AppPage.Header
          title={t('forms.questionnaires.title')}
          subtitle={t('forms.questionnaires.subtitle')}
        />
        <Tabs
          id="accountTabs"
          onSelect={key => navigate(`/questionnaires/${key}`)}
          activeKey={tab}
          className={cn('tabs', styles.tabs)}>
          {!!referenceCheckDetails && !referenceCheckDetails.configuration?.hide_public_library && (
            <Tab eventKey="library" title="Library">
              <LibraryForms forms={forms.library} />
            </Tab>
          )}
          {!!referenceCheckDetails && (
            <Tab eventKey="custom" title={t('forms.custom.title')}>
              <CustomForms
                forms={(forms?.customs || []).filter(form => !form.archived)}
                onSave={handleSaveForm}
                onDelete={handleDeleteForm}
                onArchive={handleArchiveForm}
                copyFrom={forms.library.find(f => f.typeform_id === id)}
              />
            </Tab>
          )}
          {!!referenceCheckDetails && (
            <Tab
              eventKey="iq"
              title={
                <div className={styles.iqTitle}>
                  Checkmate <img src="assets/images/icons/iq_logo.svg" alt="iq" />
                </div>
              }>
              <IQForms forms={forms.intelligent} />
            </Tab>
          )}
          {candidateQuestionnaireEnabled && (
            <Tab eventKey="candidate_questionnaire" title={t('forms.candidateQuestionnaire.title')}>
              <CandidateQuestionnaires
                forms={(forms.questionnaires || []).filter(form => !form.archived)}
                teams={teams}
                deleteForm={handleDeleteForm}
                onSave={handleSaveForm}
                onArchive={handleArchiveForm}
              />
            </Tab>
          )}
          {customReferenceEnabled && (
            <Tab eventKey="candidate_reference" title={t('forms.candidateReference.title')}>
              <CandidateReference
                forms={(forms.candidates || []).filter(form => !form.archived)}
                teams={teams}
                deleteForm={handleDeleteForm}
                onSave={handleSaveForm}
                onArchive={handleArchiveForm}
              />
            </Tab>
          )}
          {customReferenceEnabled && (
            <Tab
              eventKey="referees_reference"
              title={t('forms.referencePlus.title')}
              className="questionnaires-tabs">
              <ReferenceForms
                forms={(forms.referees || []).filter(form => !form.archived)}
                teams={teams}
                deleteForm={handleDeleteForm}
                onSave={handleSaveForm}
                onArchive={handleArchiveForm}
                company={company}
                setLoading={setLoading}
              />
            </Tab>
          )}
          <Tab eventKey="archived" title={`${t('forms.archived.title')} (${archivedForms.length})`}>
            <ArchivedForms
              forms={archivedForms}
              teams={teams}
              onRestore={handleRestoreForm}
              onDelete={handleDeleteForm}
            />
          </Tab>
        </Tabs>
      </div>
    </AppPage>
  );
};

export default Questionnaires;
