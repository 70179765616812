import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { toQueryParams } from 'helpers/api';
import cookie from 'react-cookies';

export const adminRolesApi = createApi({
  reducerPath: 'adminRolesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URl}/admin_roles`,
    prepareHeaders: headers => {
      headers.set('Authorization', `Bearer ${cookie.load('token')}`);
      return headers;
    },
  }),
  endpoints: builder => ({
    getAdminRoles: builder.query({
      query: params => `?${toQueryParams(params)}`,
    }),
    createAdminRole: builder.mutation({
      query: data => ({
        url: '/',
        method: 'POST',
        body: data,
      }),
    }),
    deleteAdminRole: builder.mutation({
      query: id => ({
        url: `/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const { useLazyGetAdminRolesQuery, useCreateAdminRoleMutation, useDeleteAdminRoleMutation } =
  adminRolesApi;
