import { useEffect, useState } from 'react';

import { MESSAGE_STATE_ERROR, MESSAGE_STATE_SUCCESS } from 'constants/message-app-state-contants';
import { AdminRoles, AdminRolesHuman } from 'constants/permissions';
import { useDocumentTitle } from 'hooks/document-title';
import AdminPage from 'layouts/AdminPage/AdminPage';
import { useForm } from 'react-hook-form';
import Moment from 'react-moment';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Alert from 'components/Alert/Alert';
import { AnimatedModal } from 'components/AnimatedModal/AnimatedModal';
import { AsyncSearch, Button, InputGroup, Select } from 'components/FormComponents';
import { IconButton } from 'components/IconButton/IconButton';
import { Table } from 'components/Table/Table';

import {
  useCreateAdminRoleMutation,
  useDeleteAdminRoleMutation,
  useLazyGetAdminRolesQuery,
} from 'api/admin-roles-api';
import { messagePopUp } from 'api/app-slice';
import { useLazyGetAllUsersQuery } from 'api/user-api';

const AdminAdminUsers = () => {
  useDocumentTitle('Admin Users');

  const dispatch = useDispatch();

  const [getAdminUsers] = useLazyGetAdminRolesQuery();
  const [getUsers] = useLazyGetAllUsersQuery();
  const [createAdminRole] = useCreateAdminRoleMutation();
  const [deleteAdminRole] = useDeleteAdminRoleMutation();

  const [showNewAdminUserModal, setShowNewAdminUserModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [actionUser, setActionUser] = useState();

  const [users, setUsers] = useState([]);

  const [pagination, setPagination] = useState({
    page: 1,
    records_per_page: 15,
    total_pages: 1,
    total_records: 0,
  });

  const fetchUsers = async (params = {}) => {
    setLoading(true);
    const { result } = await getAdminUsers(params).unwrap();
    setPagination(result.pagination);
    setUsers(result.users);
    setLoading(false);
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const onDeleteUser = async () => {
    setDeleteConfirmation(false);
    setLoading(true);
    const { data } = await deleteAdminRole(actionUser.admin_role_id);

    if (data?.error) {
      dispatch(messagePopUp({ text: data.error.log, state: MESSAGE_STATE_ERROR, hide: true }));
    } else {
      dispatch(
        messagePopUp({
          text: `Admin access removed for ${actionUser.email}.`,
          state: MESSAGE_STATE_SUCCESS,
          hide: true,
        }),
      );
      setUsers(curr => curr.filter(user => user.id !== actionUser.id));
    }
    setLoading(false);
  };

  const handleDeleteUser = user => {
    setActionUser(user);
    setDeleteConfirmation(true);
  };

  const handleCreateAdminRole = async adminRoleData => {
    setLoading(true);
    const data = await createAdminRole(adminRoleData);

    if (data?.error) {
      dispatch(
        messagePopUp({ text: data.error.data.error.log, state: MESSAGE_STATE_ERROR, hide: true }),
      );
    } else {
      const user = data?.data.result;
      dispatch(
        messagePopUp({
          text: `Admin access granted to ${user.email}.`,
          state: MESSAGE_STATE_SUCCESS,
          hide: true,
        }),
      );
      setUsers(curr => [...curr, user]);
      setShowNewAdminUserModal(false);
    }
    setLoading(false);
  };

  return (
    <AdminPage
      loading={loading}
      title="Users"
      button
      buttonText="Grant Admin Access to User"
      onClick={() => setShowNewAdminUserModal(true)}
      allowedRoles={[AdminRoles.SuperAdmin]}>
      <Alert
        show={deleteConfirmation}
        title={`Are you sure you want to remove admin access for ${actionUser?.first_name}`}
        message={`You are about to remove admin access for ${actionUser?.email}. This user will no longer have access to the admin panel.`}
        ok="Delete"
        onOk={onDeleteUser}
        cancel="Cancel"
        onCancel={() => setDeleteConfirmation(false)}
      />
      <NewAdminUserModal
        visible={showNewAdminUserModal}
        onClose={() => setShowNewAdminUserModal(false)}
        getUsers={getUsers}
        onSave={handleCreateAdminRole}
      />

      <Table.Context onQueryUpdate={fetchUsers} defaultQuery={{ page: 1 }}>
        <Table.TableContent className="u-margin--large">
          <Table.Filters>
            <Table.Search placeholder="Search for a user..." />
          </Table.Filters>
          <Table className="u-margin--large">
            <Table.Head>
              <Table.Row>
                <Table.Header>ID</Table.Header>
                <Table.Header sortKey="users.first_name">Name</Table.Header>
                <Table.Header sortKey="users.email">Email</Table.Header>
                <Table.Header sortKey="users.created_at">Created At</Table.Header>
                <Table.Header>Last Login</Table.Header>
                <Table.Header>Role</Table.Header>
                <Table.Header>Actions</Table.Header>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {users.map(user => (
                <Table.Row key={user.id}>
                  <Table.Col>{user.id}</Table.Col>
                  <Table.Col>
                    {user.first_name} {user.last_name}
                  </Table.Col>
                  <Table.Col>{user.email}</Table.Col>
                  <Table.Col>
                    <Moment format="DD/MM/YYYY">{user.created_at}</Moment>
                  </Table.Col>
                  <Table.Col>
                    {user.last_login && (
                      <Moment fromNow utc>
                        {user.last_login}
                      </Moment>
                    )}
                  </Table.Col>
                  <Table.Col> {AdminRolesHuman[user.role]}</Table.Col>
                  <Table.Col>
                    <IconButton
                      small
                      icon="trash-2"
                      tip="Delete user"
                      onClick={() => handleDeleteUser(user)}
                    />
                  </Table.Col>
                </Table.Row>
              ))}
            </Table.Body>
            <Table.Pagination {...pagination} />
          </Table>
        </Table.TableContent>
      </Table.Context>
    </AdminPage>
  );
};

export default AdminAdminUsers;

const NewAdminUserModal = ({
  visible,
  onClose = () => {},
  onSave = () => {},
  getUsers = () => {},
}) => {
  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const asyncUsersSearch = async query => {
    const { data: response } = await getUsers({ search: query });

    if (response?.result?.users?.length < 1) return [];

    return response.result.users.map(user => ({
      name: `${user.first_name} ${user.last_name} • ${user.email}`,
      value: user.id,
    }));
  };

  return (
    <AnimatedModal visible={visible} onClose={onClose} title="Enable Admin Access" small>
      <form onSubmit={handleSubmit(onSave)}>
        <InputGroup title="Role *">
          <Select name="role" register={register} value={watch('role')} useDefault>
            {Object.values(AdminRoles).map(role => (
              <Select.Item key={role} value={role}>
                {AdminRolesHuman[role]}
              </Select.Item>
            ))}
          </Select>
        </InputGroup>
        <InputGroup title="User *">
          <AsyncSearch
            name="user_id"
            register={register}
            placeholder="Search for a user..."
            asyncSearch={asyncUsersSearch}
          />
        </InputGroup>
        <Button submit className="u-margin-top--large u-width-100">
          Enable Admin Access
        </Button>
      </form>
    </AnimatedModal>
  );
};
