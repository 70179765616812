import { useMemo } from 'react';

export const useBrandingStyleVariables = brand => {
  const defaultBranding = {
    '--button-color': '#4E18BB',
    '--button-text-color': '#FFF',
    '--link-color': '#4E18BB',
  };

  return useMemo(() => {
    if (!brand || !brand.color_active) return defaultBranding;

    return {
      '--button-color': brand.color_button,
      '--button-text-color': brand.color_button_text,
      '--link-color': brand.color_link,
    };
  }, [brand]);
};
