import React from 'react';

import cn from 'classnames';
import FeatherIcon from 'feather-icons-react';
import { useLocale } from 'hooks/locale';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';

import { checkHistoryActions } from './../../constants/check-history-actions';
import styles from './HistoryRow.module.scss';

export const HistoryRow = ({
  entry,
  candidate,
  companyChecks,
  referees,
  className,
  companyUsers = [],
  company,
  disableLinks = false,
  onCandidateRequest = () => {},
  onRefereeRequest = () => {},
}) => {
  const { dateSlashFormat } = useLocale(company?.locale);

  // Derived values
  const checkHuman = entry?.check_type
    ? companyChecks?.find(companyCheck => companyCheck.type === entry.check_type)?.check_type.title
    : null;

  const userName = <b className={styles.userRef}>@{entry.user_name || 'User'}&#160;</b>;

  const refCandidate = disableLinks ? (
    <b className={cn(styles.userRef, 'u-padding-right--xSmall')}>{candidate.name}</b>
  ) : (
    <Link to={`/check/${candidate.id}/candidate`}>{candidate.name}</Link>
  );

  const backgroundChecks = disableLinks ? null : (
    <Link to={`/check/${candidate.id}/background_checks`}>View</Link>
  );

  const country =
    entry.country && entry.country !== 'Not specified.' ? `from ${entry.country}` : '';
  const reportType = entry.check_type || null;

  const referee = entry.referee_id ? referees.find(r => r.id === entry.referee_id) : null;

  const refReferee = referee ? (
    disableLinks ? (
      <b className={cn(styles.userRef, 'u-padding-right--xSmall')}>{referee.name}</b>
    ) : (
      <Link
        to={`/check/${candidate.id}/${
          reportType === 'custom_reference' ? 'reference' : 'referees'
        }/${referee.id}`}>
        {referee.name}
      </Link>
    )
  ) : null;

  const referenceLink =
    referee && !disableLinks ? (
      <Link
        to={`/check/${candidate.id}/${
          reportType === 'custom_reference' ? 'reference' : 'reports'
        }/${referee.id}`}>
        View check
      </Link>
    ) : null;

  // For CompletedCandidate case: joining multiple referee links if needed
  const candidateRefereeLinks =
    referees && referees.length > 0
      ? referees.map((ref, index) => (
          <span key={ref.id}>
            {index > 0 && ' & '}
            {disableLinks ? (
              <b className={cn(styles.userRef, 'u-padding-right--xSmall')}>{ref.name}</b>
            ) : (
              <Link
                to={`/check/${candidate.id}/${
                  reportType === 'custom_reference' ? 'reference' : 'referees'
                }/${ref.id}`}>
                {ref.name}
              </Link>
            )}
          </span>
        ))
      : null;

  // Build the data object to pass into our mapping function
  const actionData = {
    refCandidate,
    refReferee,
    referenceLink,
    candidateRefereeLinks,
    backgroundChecks,
    country,
    userName,
    candidate,
    checkHuman,
    entry,
    referee,
    onCandidateRequest,
    onRefereeRequest,
    referees,
    companyUsers,
  };

  // Use the mapping constant to get the action
  const action = checkHistoryActions[entry.type]
    ? checkHistoryActions[entry.type](actionData)
    : { icon: 'help-circle', description: <p>Unknown action.</p> };

  return (
    <div className={cn(styles.root, { [className]: className })}>
      <div className={styles.date}>
        <Moment format={dateSlashFormat}>{entry.date}</Moment>
      </div>
      <div className={styles.time}>
        <Moment format="@HH:mm">{entry.date}</Moment>
      </div>
      <FeatherIcon icon={action.icon} className={styles.icon} />
      <div className={styles.description}>{action.description}</div>
    </div>
  );
};
