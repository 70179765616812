import { useMemo } from 'react';

import cn from 'classnames';
import { AdminRoles } from 'constants/permissions';
import { useSelector } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';

import { selectAuthentication } from 'api/authentication-slice';

import styles from './AdminNavbar.module.scss';

const navigationItems = [
  {
    path: '/admin/check_types',
    label: 'Check Types',
    roles: [AdminRoles.SuperAdmin],
  },
  {
    path: '/admin/companies',
    label: 'Companies',
    roles: [AdminRoles.SuperAdmin, AdminRoles.Sales, AdminRoles.Support],
  },
  {
    path: '/admin/users',
    label: 'Users',
    roles: [AdminRoles.SuperAdmin, AdminRoles.Sales, AdminRoles.Support],
  },
  {
    path: '/admin/candidates',
    label: 'Candidates',
    roles: [AdminRoles.SuperAdmin, AdminRoles.Support],
  },
  {
    path: '/admin/checks',
    label: 'Candidate Checks',
    roles: [AdminRoles.SuperAdmin, AdminRoles.Support],
  },
  {
    path: '/admin/reporting',
    label: 'Reporting',
    roles: [AdminRoles.SuperAdmin, AdminRoles.Sales],
  },
  {
    path: '/admin/log',
    label: 'Logs',
    roles: [AdminRoles.SuperAdmin],
  },
  {
    path: '/admin/admin_users',
    label: 'Admin Users',
    roles: [AdminRoles.SuperAdmin],
  },
];

export const AdminNavbar = () => {
  const authentication = useSelector(selectAuthentication);

  const allowedAdminTabs = useMemo(() => {
    if (!authentication.checked) return [];

    return navigationItems.filter(item => item.roles.includes(authentication.admin_role));
  }, [authentication.checked, authentication.admin_role]);

  return (
    <div className={styles.root}>
      <div className={styles.left}>
        <Link to="/dashboard" className={styles.logo}>
          <img
            className={cn(styles.logo, 'u-margin-bottom--0')}
            src="./assets/images/checkmate/icon-label.svg"
            alt="checkmate"
          />
        </Link>
        <div className={cn(styles.hideXS, styles.links)}>
          {allowedAdminTabs.map(item => (
            <NavLink key={item.path} to={item.path}>
              {item.label}
            </NavLink>
          ))}
        </div>
      </div>
    </div>
  );
};
