import { useEffect, useState } from 'react';

import cn from 'classnames';
import { AdminRoles } from 'constants/permissions';
import { useDocumentTitle } from 'hooks/document-title';
import AdminPage from 'layouts/AdminPage/AdminPage';
import { Tab, Tabs } from 'react-bootstrap';
import cookie from 'react-cookies';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import Alert from 'components/Alert/Alert';
import { AnimatedModal } from 'components/AnimatedModal/AnimatedModal';
import { Button, DatePicker, InputGroup, Select, ToggleSwitch } from 'components/FormComponents';
import { RowDetails } from 'components/RowDetails/RowDetails';

import { loginAs } from 'api/authentication-slice';
import { useGetCheckTypesQuery } from 'api/check-types-api';
import {
  useGetCompanyBillingInfoQuery,
  useGetCompanyChecksQuery,
  useGetCompanyOwnerQuery,
  useGetCompanyQuery,
  useLazyGetCompanyUsageReportQuery,
  useUpdateCompanyMutation,
} from 'api/company-api';
import { useGetCompanyUsersQuery } from 'api/company-api';
import {
  useCreateCompanyCheckMutation,
  useDeleteCompanyCheckMutation,
  useUpdateCompanyCheckMutation,
} from 'api/company-checks-api';
import { useCreatePurchaseMutation } from 'api/purchases-api';

import styles from './AdminCompany.module.scss';
import { ChecksTab } from './tabs/ChecksTab/ChecksTab';

const AdminCompany = () => {
  let { company_id: companyId } = useParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { data: companyData } = useGetCompanyQuery(companyId);
  const { data: user } = useGetCompanyOwnerQuery(companyId);
  const { data: usersData } = useGetCompanyUsersQuery({ id: companyId });
  const { data: checkTypes } = useGetCheckTypesQuery();
  const { data: billingInfo } = useGetCompanyBillingInfoQuery(companyId);
  const { data: companyChecksData, isLoading: loadingCompanyChecks } =
    useGetCompanyChecksQuery(companyId);

  const [getCompanyUsageReport] = useLazyGetCompanyUsageReportQuery();
  const [updateCompanyCheck] = useUpdateCompanyCheckMutation();
  const [deleteCompanyCheck] = useDeleteCompanyCheckMutation();
  const [createCompanyCheck] = useCreateCompanyCheckMutation();
  const [companyUpdate] = useUpdateCompanyMutation();
  const [createPurchase] = useCreatePurchaseMutation();

  const [loading, setLoading] = useState(false);
  const [company, setCompany] = useState();
  const [companyChecks, setCompanyChecks] = useState();
  const [users, setUsers] = useState([]);

  const [showFiltersModal, setShowFiltersModal] = useState(false);
  const [showUpdateAlert, setShowUpdateAlert] = useState(false);

  useDocumentTitle(company ? company.name : 'Loading...');

  const { watch, register, reset, handleSubmit } = useForm();

  useEffect(() => {
    if (!companyData) return;

    setCompany(companyData);
    reset(companyData);
  }, [companyData]);

  watch('name');

  useEffect(() => {
    if (companyChecksData) setCompanyChecks(companyChecksData);
  }, [companyChecksData]);
  useEffect(() => {
    if (usersData) setUsers(usersData.result.users);
  }, [usersData]);

  const onUpdate = async data => {
    setLoading(true);
    setShowUpdateAlert(false);
    await companyUpdate({ id: companyId, data });
    setLoading(false);
  };

  const handleCompanyCheckUpdate = async (id, data = {}) => {
    setLoading(true);
    const { data: updatedCompanyCheck } = await updateCompanyCheck({ id, data });
    setCompanyChecks(
      companyChecks.map(companyCheck =>
        companyCheck.id === updatedCompanyCheck.id ? updatedCompanyCheck : companyCheck,
      ),
    );
    setLoading(false);
  };

  const toggleCompanyCheck = async (id, type, value) => {
    setLoading(true);
    if (value) {
      const { data: companyCheck } = await createCompanyCheck({ company_id: companyId, type });
      setCompanyChecks([...companyChecks, companyCheck]);
    } else {
      await deleteCompanyCheck(id);
      setCompanyChecks(curr => curr.filter(companyCheck => companyCheck.id !== id));
    }
    setLoading(false);
  };

  const handleLoginAs = async () => {
    setLoading(true);
    const { payload: result } = await dispatch(loginAs(user.id));
    cookie.save('admin-token', cookie.load('token'), { path: '/' });
    cookie.save('admin-route', `/admin/companies/${companyId}`, { path: '/' });
    cookie.save('token', result.token, { path: '/' });
    setLoading(false);
    navigate('/dashboard');
  };

  const downloadUsageReport = async filters => {
    setLoading(true);
    await getCompanyUsageReport({ id: companyId, data: filters });
    setLoading(false);
  };

  return (
    <AdminPage
      loading={loading || loadingCompanyChecks}
      title={company?.name}
      allowedRoles={[AdminRoles.SuperAdmin, AdminRoles.Support, AdminRoles.Sales]}>
      <ReportFiltersModal
        visible={showFiltersModal}
        onSubmit={downloadUsageReport}
        onClose={() => setShowFiltersModal(false)}
      />
      <RowDetails className={styles.details}>
        <RowDetails.Column title="ID" data={company?.id} />
        <RowDetails.Column title="Name" data={company?.name} />
        <RowDetails.Column title="Owner Name" data={user?.name} />
        <RowDetails.Column title="Owner Email" data={user?.email} />
        <RowDetails.Action
          icon="key"
          tooltip="Login into company as admin"
          onClick={handleLoginAs}
        />
        <RowDetails.Action
          icon="file-text"
          tooltip="Download company usage report"
          onClick={() => setShowFiltersModal(true)}
        />
      </RowDetails>

      <Tabs defaultActiveKey="config" className="tabs no-padding padding-small">
        <Tab eventKey="config" title="Configuration" className="u-padding--large">
          <form className="card card-with-border u-padding u-width-25">
            <Alert
              show={showUpdateAlert}
              title="⚠️ Read before updating"
              message={
                <>
                  <p>
                    Updating a company "User Type" might have an impact on the billing. Make sure
                    you understand the consequences before updating.
                  </p>
                  <p>
                    Updating user type to "Enterprise" will start generating an automatic report at
                    the end of each month with the client usage.
                  </p>
                </>
              }
              ok="Update"
              cancel="Cancel"
              onOk={() => handleSubmit(onUpdate)()}
              onCancel={() => setShowUpdateAlert(false)}
            />

            <ToggleSwitch
              register={register}
              name="checkmate_branding_disabled"
              label="Deactivate Checkmate branding"
            />
            <ToggleSwitch register={register} name="audit_logs_enabled" label="Audit Logs" />
            <ToggleSwitch
              register={register}
              name="renewal_dashboard_enabled"
              label="Renewal Dashboard"
            />
            <ToggleSwitch register={register} name="ff_ai_forms" label="AI Forms FF" />
            <ToggleSwitch
              register={register}
              name="id_verification_enabled"
              label="ID Verification"
            />

            <InputGroup title="User Type" className="u-width-100">
              <Select name="user_type" register={register} value={watch('user_type')} useDefault>
                <Select.Item value="casual">Casual PAYG</Select.Item>
                <Select.Item value="internal">Internal</Select.Item>
                <Select.Item value="enterprise">Enterprise</Select.Item>
              </Select>
            </InputGroup>
            <InputGroup title="Account Manager" className="u-width-100">
              <Select
                name="account_manager"
                register={register}
                value={watch('account_manager')}
                useDefault>
                {process.env.REACT_APP_ACCOUNT_MANAGERS.split(',').map(account_manager => (
                  <Select.Item className="u-capitalize" value={account_manager}>
                    {account_manager}
                  </Select.Item>
                ))}
                <Select.Item value="other">Other</Select.Item>
              </Select>
            </InputGroup>

            <Button
              onClick={() => setShowUpdateAlert(true)}
              className="u-width-100 u-margin-top--large">
              Save
            </Button>
          </form>
        </Tab>
        <Tab eventKey="checks" title="Checks" className="u-padding--large">
          <ChecksTab
            company={company}
            users={users}
            checkTypes={checkTypes}
            companyChecks={companyChecks}
            billingInfo={billingInfo}
            setLoading={setLoading}
            onToggle={toggleCompanyCheck}
            onSave={handleCompanyCheckUpdate}
            onAddCredits={createPurchase}
          />
        </Tab>
      </Tabs>
    </AdminPage>
  );
};

export default AdminCompany;

const ReportFiltersModal = ({ visible, onSubmit = () => {}, onClose = () => {} }) => {
  const { control, handleSubmit } = useForm();

  return (
    <AnimatedModal title="Filters" visible={visible} onClose={onClose}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={cn('u-margin-top', styles.filtersModalForm)}>
        <InputGroup title="Date From">
          <Controller
            control={control}
            name="date_from"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <DatePicker value={value} onChange={onChange} outputFormat="YYYY-MM-DD" />
            )}
          />
        </InputGroup>
        <InputGroup title="Date To">
          <Controller
            control={control}
            name="date_to"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <DatePicker value={value} onChange={onChange} outputFormat="YYYY-MM-DD" />
            )}
          />
        </InputGroup>
        <Button submit className="u-width-100 u-margin-top--large">
          Download
        </Button>
      </form>
    </AnimatedModal>
  );
};
