import { useEffect } from 'react';
import { useState } from 'react';

import FeatherIcon from 'feather-icons-react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import { AnimatedModal } from 'components/AnimatedModal/AnimatedModal';
import { Button, Input, InputGroup } from 'components/FormComponents';

import styles from './CandidateCheckValues.module.scss';

export const CandidateCheckValues = ({ checkValues, updateCandidateChecksValues = () => {} }) => {
  const [valuesKeys, setValuesKeys] = useState([]);

  const [valueToUpdate, setValueToUpdate] = useState(false);

  useEffect(() => {
    if (checkValues?.values && typeof checkValues.values === 'object') {
      setValuesKeys(Object.keys(checkValues.values).sort());
    } else {
      setValuesKeys([]);
    }
  }, [checkValues]);

  const onCopy = value => {
    navigator.clipboard.writeText(value);
  };

  const onUpdateValues = (name, value) => {
    setValueToUpdate();
    updateCandidateChecksValues({ ...checkValues.values, [name]: value });
  };

  const renderValue = value => {
    if (value === null || value === undefined) return '-';
    if (typeof value === 'boolean') return value ? 'true' : 'false';

    switch (typeof value) {
      case 'object':
        if (Array.isArray(value)) {
          if (typeof value[0] === 'string') {
            return (
              <ul>
                {value.map((item, i) => (
                  <li key={i}>{item}</li>
                ))}
              </ul>
            );
          }
          return value.map((obj, i) => {
            if (!obj) return null;

            return (
              <ul key={i}>
                {Object.keys(obj).map(key => (
                  <li key={key}>
                    <span className={styles.key}>{key.replaceAll('_', ' ')}</span> :{' '}
                    {renderValue(obj[key])}
                  </li>
                ))}
              </ul>
            );
          });
        }
        return (
          <ul>
            {Object.keys(value).map(key => (
              <li key={key}>
                <span className={styles.key}>{key.replaceAll('_', ' ')}</span> :{' '}
                {renderValue(value[key])}
              </li>
            ))}
          </ul>
        );
      default:
        return value;
    }
  };

  return (
    <table className={styles.root}>
      {valueToUpdate && (
        <UpdateValueModal
          visible={true}
          value={valueToUpdate}
          onClose={() => setValueToUpdate()}
          onSubmit={onUpdateValues}
        />
      )}
      <tbody>
        {valuesKeys.map(key => (
          <tr key={key} className={styles.valuesItem}>
            <td className={styles.key} onClick={() => onCopy(checkValues.values[key])}>
              {key.replaceAll('_', ' ')}
            </td>
            <td className={styles.value} onClick={() => onCopy(checkValues.values[key])}>
              {renderValue(checkValues.values[key])}
            </td>
            <td>
              <OverlayTrigger overlay={<Tooltip>Copy value</Tooltip>}>
                <div style={{ display: 'inline-block' }} className="u-margin-right">
                  <FeatherIcon
                    icon="copy"
                    size={14}
                    onClick={() => onCopy(checkValues.values[key])}
                  />
                </div>
              </OverlayTrigger>
              <OverlayTrigger overlay={<Tooltip>Edit value</Tooltip>}>
                <div style={{ display: 'inline-block' }} className="u-margin-right">
                  <FeatherIcon
                    icon="edit"
                    size={14}
                    onClick={() => setValueToUpdate({ name: key, value: checkValues.values[key] })}
                  />
                </div>
              </OverlayTrigger>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const UpdateValueModal = ({ visible, value, onClose = () => {}, onSubmit = () => {} }) => {
  const isObject =
    typeof value.value === 'object' && value.value !== null && !Array.isArray(value.value);

  const isArrayOfObjects =
    Array.isArray(value.value) && value.value.every(v => typeof v === 'object' && v !== null);

  const [updatedValue, setUpdatedValue] = useState(isObject || isArrayOfObjects ? '' : value.value);
  const [updatedFields, setUpdatedFields] = useState(isObject ? value.value : {});
  const [updatedArrayFields, setUpdatedArrayFields] = useState(isArrayOfObjects ? value.value : []);

  return (
    <AnimatedModal
      title={`Update '${value.name.replaceAll('_', ' ')}'`}
      visible={visible}
      onClose={onClose}>
      {isObject && (
        <>
          {Object.entries(updatedFields).map(([key, val]) => (
            <InputGroup key={key} title={key.replaceAll('_', ' ')}>
              <Input
                inputProps={{
                  value: val,
                  onChange: e =>
                    setUpdatedFields(prev => ({
                      ...prev,
                      [key]: e.target.value,
                    })),
                }}
              />
            </InputGroup>
          ))}
        </>
      )}

      {isArrayOfObjects && (
        <>
          {updatedArrayFields.map((obj, index) => (
            <div key={index} className="u-margin-bottom">
              <h4 className="u-margin-top">Item {index + 1}</h4>
              {Object.entries(obj).map(([key, val]) => {
                if (typeof val === 'object' && val !== null && !Array.isArray(val)) {
                  return (
                    <div key={key} className="u-margin-bottom">
                      <h5>{key.replaceAll('_', ' ')}</h5>
                      {Object.entries(val).map(([subKey, subVal]) => (
                        <InputGroup key={subKey} title={subKey.replaceAll('_', ' ')}>
                          <Input
                            inputProps={{
                              value: subVal,
                              onChange: e => {
                                const updated = [...updatedArrayFields];
                                updated[index] = {
                                  ...updated[index],
                                  [key]: {
                                    ...updated[index][key],
                                    [subKey]: e.target.value,
                                  },
                                };
                                setUpdatedArrayFields(updated);
                              },
                            }}
                          />
                        </InputGroup>
                      ))}
                    </div>
                  );
                }

                return (
                  <InputGroup key={key} title={key.replaceAll('_', ' ')}>
                    <Input
                      inputProps={{
                        value: val,
                        onChange: e => {
                          const updated = [...updatedArrayFields];
                          updated[index] = {
                            ...updated[index],
                            [key]: e.target.value,
                          };
                          setUpdatedArrayFields(updated);
                        },
                      }}
                    />
                  </InputGroup>
                );
              })}
            </div>
          ))}
        </>
      )}

      {!isObject && !isArrayOfObjects && (
        <InputGroup title="Value" className="u-margin-top">
          <Input
            inputProps={{
              value: updatedValue,
              onChange: e => setUpdatedValue(e.target.value),
            }}
          />
        </InputGroup>
      )}

      <Button
        className="u-width-100 u-margin-top--large"
        onClick={() => {
          const parsed = isObject
            ? updatedFields
            : isArrayOfObjects
              ? updatedArrayFields
              : (() => {
                  try {
                    return JSON.parse(updatedValue);
                  } catch {
                    return updatedValue;
                  }
                })();

          onSubmit(value.name, parsed);
        }}>
        Update
      </Button>
    </AnimatedModal>
  );
};
