import { useRef } from 'react';

import cn from 'classnames';

import { IconComponent } from 'components/Icon/IconComponent';
import { IconButton } from 'components/IconButton/IconButton';

import { useClickOutside } from '../../hooks/click-outside';
import styles from './AnimatedModal.module.scss';

export const AnimatedModal = ({
  visible,
  children,
  className,
  title,
  description,
  icon,
  noClose = false,
  onClose = () => {},
  small = false,
}) => {
  const wrapperRef = useRef(null);

  useClickOutside(wrapperRef, onClose);

  return (
    <div className={cn(styles.root, { [styles.rootVisible]: visible })}>
      <div
        className={cn(styles.contentWrapper, {
          [styles.contentWrapperSmall]: small,
        })}
        ref={wrapperRef}>
        <div
          className={cn(
            styles.header,
            'd-flex',
            'justify-content-between',
            'align-items-start',
            'u-margin-y',
          )}>
          <div
            className={cn(
              styles.headerContent,
              'd-flex',
              'align-items-start',
              'u-margin-right--large',
            )}>
            {icon && (
              <div className="u-margin-right">
                <IconComponent icon={icon} />
              </div>
            )}
            <div className="d-flex flex-column align-items-start">
              {title && <h1 className={cn(styles.title, 'primary-text')}>{title}</h1>}
              {description && (
                <p
                  className="u-light secondary-text"
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              )}
            </div>
          </div>
          {!noClose && <IconButton icon="x" onClick={onClose} />}
        </div>
        <div className={cn({ [className]: className })}>{children}</div>
      </div>
    </div>
  );
};
