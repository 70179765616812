import React, { useEffect, useState } from 'react';

import numberToWords from 'number-to-words';
import { useFieldArray } from 'react-hook-form';

import { AnimatedModal } from 'components/AnimatedModal/AnimatedModal';
import { InputGroup, Select } from 'components/FormComponents';
import { Button, FilePicker, ToggleSwitch } from 'components/FormComponents';
import { ToggleSwitchInput } from 'components/FormComponents/ToggleSwitchInput/ToggleSwitchInput';

import styles from './CustomReferenceCheckDetails.module.scss';

export const CustomReferenceCheckDetails = ({
  index,
  append,
  remove,
  register,
  control,
  watch,
  bulk,
  documents,
  credits = 0,
  forms = [],
  allowUploads = false,
  setValue = () => {},
  signUpload = () => {},
  createUpload = () => {},
  handleDeleteUpload = () => {},
}) => {
  const fieldName = `checks.${index}.details`;

  const {
    fields: referees,
    append: appendReferee,
    remove: removeReferee,
  } = useFieldArray({ control, name: `checks.${index}.details.form_details` });

  const refereesValue = watch(`checks.${index}.details.referees`);
  const documentId = watch(`checks.${index}.details.referee_document_id`);

  // Data
  const [enabled, setEnabled] = useState(false);

  const [upload, setUpload] = useState();
  const [showNewUploadModal, setShowNewUploadModal] = useState(false);

  useEffect(() => {
    if (enabled && isNaN(index)) {
      append({
        type: 'custom_reference',
        details: {
          referees: JSON.parse(localStorage.getItem('customReference:referees') || '2'),
          backup_referee: JSON.parse(
            localStorage.getItem('customReference:backupReferee', 'false'),
          ),
          bypassed: false,
          form_details: [],
        },
      });
      appendReferee();
    } else if (!enabled && index >= 0) {
      remove(index);
    }
  }, [enabled]);

  useEffect(() => {
    if (index >= 0 && !enabled) setEnabled(true);
    else if (isNaN(index) && enabled) setEnabled(false);
  }, [index]);

  useEffect(() => {
    if (refereesValue === referees.length) {
      return;
    } else if (refereesValue > referees.length) {
      const refereesToAdd = refereesValue - referees.length;
      for (let i = 0; i < refereesToAdd; i++) {
        appendReferee();
      }
    } else {
      for (let i = 0; i < referees.length; i++) {
        if (i >= refereesValue) removeReferee(i);
      }
    }
  }, [refereesValue, referees.length]);

  const setUploadedDocument = () => {
    setValue(`checks.${index}.details.referee_document_id`, upload.id);
    setShowNewUploadModal(false);
  };

  return (
    <ToggleSwitchInput
      text="Reference Check Plus"
      subtext={(credits < 0 ? 'Unlimited' : credits) + ' remaining'}
      logo="https://checkmate-prod.s3.ap-southeast-2.amazonaws.com/assets/checkmate_check_logo.svg"
      onChange={e => setEnabled(e.target.checked)}
      value={enabled}>
      {enabled && index >= 0 && (
        <>
          <AnimatedModal
            className="u-width-small"
            visible={showNewUploadModal}
            title="Upload file"
            onClose={() => setShowNewUploadModal(false)}>
            <FilePicker
              value={upload}
              onChange={setUpload}
              accept={{ 'application/pdf': ['.pdf'], 'application/msword': ['.doc', '.docx'] }}
              useModel
              className="u-margin-y"
              signUpload={signUpload}
              createUpload={createUpload}
              deleteUpload={handleDeleteUpload}
            />
            <Button className="u-width-100" onClick={setUploadedDocument}>
              Done
            </Button>
          </AnimatedModal>

          <InputGroup title="Select reference type">
            <Select
              name={`checks.${index}.details.referees`}
              placeholder="Select.."
              register={register}
              value={refereesValue}
              useDefault>
              <Select.Item value={1}>1 Referee</Select.Item>
              <Select.Item value={2}>2 Referees</Select.Item>
              <Select.Item value={3}>3 Referees</Select.Item>
              <Select.Item value={4}>4 Referees</Select.Item>
              <Select.Item value={5}>5 Referees</Select.Item>
            </Select>
          </InputGroup>
          {referees.map((_, index) => (
            <RefereeDetails
              key={index}
              register={register}
              watch={watch}
              fieldName={fieldName}
              index={index}
              forms={forms}
            />
          ))}
          {(allowUploads || documents?.length > 0) && (
            <InputGroup title="Documents">
              <Select
                name={`checks.${index}.details.referee_document_id`}
                placeholder="Document"
                register={register}
                value={documentId}>
                {upload && <Select.Item value={upload.id}>{upload.file_name}</Select.Item>}
                {documents?.map(document => (
                  <Select.Item key={document.id} value={document.id}>
                    {document.file_name}
                  </Select.Item>
                ))}
              </Select>
              {allowUploads && (
                <a className="u-link dark t-small" onClick={() => setShowNewUploadModal(true)}>
                  Upload new document
                </a>
              )}
            </InputGroup>
          )}
          <ToggleSwitch
            register={register}
            name={`checks.${index}.details.backup_referee`}
            tooltip="In the event that a referee is overdue, we'll automatically ask the candidate to chase up the current reference or nominate a substitute."
            label="Backup Referee"
          />
          <ToggleSwitch
            register={register}
            name={`checks.${index}.details.referee_confirmation`}
            tooltip="Once a candidate has nominated referees you will need to confirm them before proceeding."
            label="Reference Confirmation"
          />
          {!bulk && (
            <ToggleSwitch
              register={register}
              name={`checks.${index}.details.bypassed`}
              tooltip="You'll be able to enter the referee's contact details manually instead of needing the candidate to do so."
              label="Bypass the Candidate"
            />
          )}
        </>
      )}
    </ToggleSwitchInput>
  );
};

const RefereeDetails = ({ register, watch, fieldName, index, forms }) => {
  const candidateValue = watch(`${fieldName}.form_details.${index}.candidate`);
  const refereeValue = watch(`${fieldName}.form_details.${index}.referee`);

  return (
    <div className={styles.refereeDetails}>
      <div className={styles.refereeCardinal}>
        {numberToWords.toWordsOrdinal(index + 1)} Referee
      </div>
      <div className={styles.details}>
        <InputGroup title="Candidate Form">
          <Select
            name={`${fieldName}.form_details.${index}.candidate`}
            placeholder="Select.."
            register={register}
            value={candidateValue}
            useDefault>
            {(forms.candidates || [])
              .filter(form => !form.archived)
              .map(form => (
                <Select.Item key={form.id} value={form.id}>
                  {form.name}
                </Select.Item>
              ))}
          </Select>
        </InputGroup>
        <InputGroup title="Referees Form">
          <Select
            name={`${fieldName}.form_details.${index}.referee`}
            placeholder="Select.."
            register={register}
            value={refereeValue}
            useDefault>
            {(forms.referees || [])
              .filter(form => !form.archived)
              .map(form => (
                <Select.Item key={form.id} value={form.id}>
                  {form.name}
                </Select.Item>
              ))}
          </Select>
        </InputGroup>
      </div>
    </div>
  );
};
