import { createSlice } from '@reduxjs/toolkit';
import { PartnersEnum } from 'constants/partners';
import { createThunk } from 'helpers/api';

import { revokePartnerToken } from './partner-token-version-slice';

const initialState = {
  config: {},
  tas: {},
  job_adder: {},
  jazzhr: {},
  teamtailor: {},
  bullhorn: {},
  onboarded: {},
  lever: {},
  ukg: {},
  account_api: {},
  bambooHR: {},
  pageup: {},
  livehire: {},
  smartrecruiters: {},

  fetched: false,
};

export const getPartnersConfig = createThunk('getPartnersConfig', 'partners_config', 'get');

export const updatePartnersConfig = createThunk('updatePartnersConfig', 'partners_config', 'put');

export const connectLever = createThunk('connectLever', 'partners_config/lever', 'post');

export const disconnectLever = createThunk('disconnectLever', 'partners_config/lever', 'delete');

export const connectJobAdder = createThunk('connectJobAdder', 'partners_config/jobadder', 'post');

export const disconnectJobAdder = createThunk(
  'disconnectJobAdder',
  'partners_config/jobadder',
  'delete',
);

export const connectBullhorn = createThunk('connectBullhorn', 'partners_config/bullhorn', 'post');

export const disconnectBullhorn = createThunk(
  'disconnectBullhorn',
  'partners_config/bullhorn',
  'delete',
);

export const connectLivehire = createThunk('connectLivehire', 'partners_config/livehire', 'post');

export const disconnectLivehire = createThunk(
  'disconnectLivehire',
  'partners_config/livehire',
  'delete',
);

export const connectSmartRecruiters = createThunk(
  'connectSmartRecruiters',
  'partners_config/smartrecruiters',
  'post',
);

const parnerConfigSlice = createSlice({
  name: 'partners_config',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getPartnersConfig.fulfilled, (_, { payload }) => {
        return { ...payload, fetched: true };
      })
      .addCase(updatePartnersConfig.fulfilled, (state, { payload }) => {
        return { ...state, ...payload };
      })
      .addCase(revokePartnerToken.fulfilled, (state, { payload }) => {
        state[PartnersEnum[payload.partner]].api_key = payload.token;
      })
      .addCase(connectLever.fulfilled, state => {
        state.lever.enabled = true;
      })
      .addCase(disconnectLever.fulfilled, state => {
        state.lever.enabled = false;
      })
      .addCase(connectLivehire.fulfilled, (_, { payload }) => {
        return payload;
      })
      .addCase(disconnectLivehire.fulfilled, (_, { payload }) => {
        return payload;
      })
      .addCase(connectJobAdder.fulfilled, state => {
        state.job_adder.enabled = true;
      })
      .addCase(disconnectJobAdder.fulfilled, state => {
        state.job_adder.enabled = false;
      })
      .addCase(connectBullhorn.fulfilled, state => {
        state.bullhorn.enabled = true;
      })
      .addCase(disconnectBullhorn.fulfilled, state => {
        state.bullhorn.enabled = false;
      })
      .addCase(connectSmartRecruiters.fulfilled, state => {
        state.smartrecruiters.enabled = true;
      });
  },
});

export const selectPartnersConfig = state => state.partnersConfig;

export default parnerConfigSlice.reducer;
