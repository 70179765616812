import { IntegrationCard } from '..';

import { useState } from 'react';

import { MESSAGE_STATE_ERROR, MESSAGE_STATE_SUCCESS } from 'constants/message-app-state-contants';
import { useOAuth } from 'hooks/oauth';
import { useTranslation } from 'react-i18next';

import { Button, Input, InputGroup } from 'components/FormComponents';

export const LeverIntegration = ({
  integration,
  config,
  setLoading = () => {},
  popUpMessage = () => {},
  connect = () => {},
  disconnect = () => {},
  updatePartnersConfig = () => {},
}) => {
  const { t } = useTranslation();

  const [enabled, setEnabled] = useState(integration.enabled);

  const [stageId, setStageId] = useState(integration?.stage_id);
  const [signatureToken, setSignatureToken] = useState(integration?.signature_token);

  const onConnectSuccess = async code => {
    setLoading(true);
    const { payload } = await connect({ code });
    if (payload.error) {
      popUpMessage(t('integrations.lever.integration.failed'), MESSAGE_STATE_ERROR);
      setEnabled(false);
    } else {
      popUpMessage(t('integrations.lever.integration.success'), MESSAGE_STATE_SUCCESS);
      setEnabled(true);
    }
    setLoading(false);
  };

  const onConnectError = ({ error, errorDescription }) => {
    popUpMessage(
      t('integrations.lever.integration.error', { error, errorDescription }),
      MESSAGE_STATE_ERROR,
    );
  };

  const openAuth = useOAuth({
    authUri: `${process.env.REACT_APP_LEVER_AUTH}/authorize`,
    clientId: process.env.REACT_APP_LEVER_CLIENT_ID,
    responseType: 'code',
    redirectUri: `${process.env.REACT_APP_WEB_URL}/assets/lever_callback.html`,
    scope:
      'offline_access users:read:admin postings:read:admin applications:read:admin opportunities:write:admin tags:read:admin notes:write:admin stages:read:admin files:write:admin webhooks:write:admin',
    callbackAction: 'lever_callback',
    extraParams: {
      audience: process.env.REACT_APP_LEVER_API,
    },
    callback: onConnectSuccess,
    onError: onConnectError,
  });

  const toggleIntegration = async e => {
    const value = e.target.checked;
    if (integration.enabled === value) return;

    if (value) {
      openAuth();
    } else {
      setLoading(true);
      await disconnect();
      setLoading(false);
      setEnabled(false);
    }
  };

  const update = async () => {
    setLoading(true);
    await updatePartnersConfig({
      lever_stage_id: stageId,
      lever_signature_token: signatureToken,
    });
    popUpMessage(t('integrations.common.configuration_updated'), MESSAGE_STATE_SUCCESS);
    setLoading(false);
  };

  return (
    <IntegrationCard {...config} enabled={enabled} onToggle={toggleIntegration}>
      {enabled && (
        <>
          <InputGroup title={t('integrations.lever.inputs.stage_id')}>
            <Input
              placeholder="fff60592-31dd-4ebe-ba8e-e7a397c30f8e"
              inputProps={{
                value: stageId,
                onChange: e => setStageId(e.target.value),
              }}
            />
          </InputGroup>

          <InputGroup title={t('integrations.lever.inputs.lever_webhook_signature_token')}>
            <Input
              placeholder="b3d4b930ecf666ee64c7ccee8148202d9c7bc44c75cf4448"
              inputProps={{
                value: signatureToken,
                onChange: e => setSignatureToken(e.target.value),
              }}
            />
          </InputGroup>
          <Button onClick={update} type="secondary" className="u-width-100 u-margin-top--large">
            {t('common.save')}
          </Button>
        </>
      )}
    </IntegrationCard>
  );
};
