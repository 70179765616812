import { useState } from 'react';

import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { FormEdit } from 'components/Form/FormEdit/FormEdit';
import { List } from 'components/List/List';

import { ContactDetailFields, FormTypes } from './../../../../constants/forms';

export const CandidateReference = ({
  forms,
  teams,
  onSave = () => {},
  deleteForm = () => {},
  onArchive = () => {},
}) => {
  const { t } = useTranslation();

  const [form, setForm] = useState();

  const createNewForm = () => {
    setForm({
      name: 'New Form',
      team_id: null,
      type: FormTypes.CANDIDATE,
      fields: ContactDetailFields,
    });
  };

  const copyFromForm = copyFrom => {
    if (!copyFrom) return;

    let newForm = { ...copyFrom };
    delete newForm.id;

    newForm.name += ' (Copy)';

    newForm.fields.map(field => {
      let newField = { ...field };
      delete newField.id;
      return newField;
    });

    setForm(newForm);
  };

  const handleSave = async data => {
    const updatedForm = await onSave(data);
    if (updatedForm) setForm(updatedForm);
  };

  const archiveForm = () => {
    if (form?.id) {
      onArchive(form);
      setForm(null);
    }
  };

  return (
    <div className={cn('u-flex-box', 'u-flex-align-start')}>
      <List
        title={t('common.forms')}
        subtitle={t('forms.candidateReference.subtitle')}
        className="u-margin-right--large u-flex-align-self-normal"
        onNew={createNewForm}>
        <List.Category title={t('forms.candidateReference.title')}>
          {forms.map(form => (
            <List.Item key={form.id} title={form.name} value={form} onClick={setForm} />
          ))}
        </List.Category>
      </List>
      {form && (
        <FormEdit
          form={form}
          teams={teams}
          blockBefore={4}
          onCopy={copyFromForm}
          onSave={handleSave}
          onDelete={deleteForm}
          onArchive={archiveForm}
          onClose={() => setForm(null)}
        />
      )}
    </div>
  );
};
