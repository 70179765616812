import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import apiRequest from 'helpers/api';
import cookie from 'react-cookies';

const initialState = {
  company_id: null,
  user_id: null,
  checked: false,
  logged: false,
  admin: false,
  admin_role: null,
};

export const authenticate = createAsyncThunk('authenticate', async () => {
  const res = await apiRequest('authentication', {}, 'get', true);
  return res.result;
});

export const verify2fa = createAsyncThunk('verify2fa', async ({ user_id, code }) => {
  const res = await apiRequest('authentication/2fa', { user_id, code }, 'post', true);
  return res.result;
});

export const login = createAsyncThunk('login', async ({ email, password }) => {
  const res = await apiRequest('authentication', { email, password }, 'post', true);
  return res;
});

export const loginWithGoogle = createAsyncThunk('loginWithGoogle', async ({ email, token }) => {
  const res = await apiRequest('authentication', { email, token }, 'post', true);
  return res;
});

export const loginAs = createAsyncThunk('loginAs', async userId => {
  const res = await apiRequest(`authentication/login_as/${userId}`, {}, 'post');
  return res.result;
});

export const verifySSO = createAsyncThunk('verifySSO', async code => {
  const res = await apiRequest('authentication/sso', { code }, 'post', true);
  return res.result;
});

const authenticationSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    logout(state) {
      cookie.remove('token', { path: '/' });
      state.checked = true;
      state.logged = false;
      state.admin = false;
    },
    stopInterpretation(state) {
      cookie.save('token', cookie.load('admin-token'), { path: '/' });
      cookie.remove('admin-token', { path: '/' });
      state.checked = false;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(authenticate.fulfilled, (state, { payload }) => {
        state.company_id = payload.company_id;
        state.user_id = payload.user_id;
        state.admin = payload.admin;
        state.admin_role = payload.admin_role;
        state.checked = true;
        state.logged = true;
      })
      .addCase(authenticate.rejected, state => {
        state.checked = true;
      })
      .addCase(verify2fa.fulfilled, (state, { payload }) => {
        if (!payload?.verified) return;

        state.company_id = payload.company_id;
        state.user_id = payload.user_id;
        state.admin = payload.admin;
        state.admin_role = payload.admin_role;
        state.checked = true;
        state.logged = true;
      })
      .addCase(login.fulfilled, (state, { payload }) => {
        if (payload.error || payload.result?.require_2fa) return;

        state.company_id = payload.result.company_id;
        state.user_id = payload.result.user_id;
        state.admin = payload.result.admin;
        state.admin_role = payload.result.admin_role;
        state.checked = true;
        state.logged = true;
      })
      .addCase(loginAs.fulfilled, (state, { payload }) => {
        state.company_id = payload.company_id;
        state.user_id = payload.user_id;
        state.admin = payload.admin;
        state.admin_role = payload.admin_role;
        state.checked = true;
        state.logged = true;
      })
      .addCase(loginWithGoogle.fulfilled, (state, { payload }) => {
        if (payload.error) return;

        state.company_id = payload.result.company_id;
        state.user_id = payload.result.user_id;
        state.admin = payload.result.admin;
        state.admin_role = payload.result.admin_role;
        state.checked = true;
        state.logged = true;
      })
      .addCase(verifySSO.fulfilled, (state, { payload }) => {
        state.company_id = payload.company_id;
        state.user_id = payload.user_id;
        state.admin = payload.admin;
        state.admin_role = payload.admin_role;
        state.checked = true;
        state.logged = true;
      });
  },
});

export const { logout, stopInterpretation } = authenticationSlice.actions;

export const selectAuthentication = state => state.authentication;

export default authenticationSlice.reducer;
