import React, { Suspense, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { IconButton } from 'components/IconButton/IconButton';

import styles from './FilePreview.module.scss';

const PDFPreview = React.lazy(() => import('./PDFPreview'));
const HEICPreview = React.lazy(() => import('./HEICPreview'));

export const FilePreview = ({ file, checked, rejected, className }) => {
  const { t } = useTranslation();
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [rotateAngle, setRotateAngle] = useState(null);

  const open = e => {
    e.preventDefault();
    e.stopPropagation();
    window.open(file.url, '_blank');
  };

  const onLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setIsLoading(false);
  };

  const nextPage = () => {
    setIsLoading(true);
    setPageNumber(page => Math.min(page + 1, numPages));
  };

  const prevPage = () => {
    setIsLoading(true);
    setPageNumber(page => Math.max(page - 1, 1));
  };

  const renderPreview = file => {
    if (!file) return null;

    const PreviewWrapper = ({ children }) => (
      <div className={styles.filePreviewContainer}>
        <div
          className={cn(styles.filePreviewContent, { [styles.loading]: isLoading }, 'flex-grow-1')}>
          {children}
        </div>
        <div className={cn(styles.filePreviewFooter, 'd-flex justify-content-between')}>
          <span className={styles.fileType}>{file?.file_type}</span>
          {file?.file_type?.includes('pdf') && (
            <div>
              <IconButton
                className={cn(styles.toggleButton, 'u-margin-right--small')}
                icon="arrow-left"
                onClick={e => {
                  e.stopPropagation();
                  prevPage();
                }}
                disabled={pageNumber <= 1}
              />
              <IconButton
                className={styles.toggleButton}
                icon="arrow-right"
                onClick={e => {
                  e.stopPropagation();
                  nextPage();
                }}
                disabled={pageNumber >= numPages}
              />
            </div>
          )}
        </div>
        {file?.file_type?.includes('image') && file?.file_type !== 'image/tiff' && (
          <IconButton
            className={cn(styles.toggleButton, 'u-absolute u-top-10 u-right-10 u-z-index-1')}
            icon="rotate-cw"
            type="white"
            onClick={() => setRotateAngle(((rotateAngle || 0) + 90) % 360)}
          />
        )}
      </div>
    );

    switch (file.file_type) {
      case 'application/pdf':
      case 'image/pdf':
        return (
          <Suspense
            fallback={
              <PreviewWrapper>
                <div>Loading PDF viewer...</div>
              </PreviewWrapper>
            }>
            <PreviewWrapper>
              <PDFPreview
                file={file}
                onLoadSuccess={onLoadSuccess}
                pageNumber={pageNumber}
                setIsLoading={setIsLoading}
                onClick={open}
              />
            </PreviewWrapper>
          </Suspense>
        );
      case 'image/heic':
        return (
          <Suspense
            fallback={
              <PreviewWrapper>
                <p>{t('common.convertingHeic')}</p>
              </PreviewWrapper>
            }>
            <PreviewWrapper>
              <div onClick={open} className={styles.imageWrapper}>
                <HEICPreview file={file} style={{ transform: `rotate(${rotateAngle || 0}deg)` }} />
              </div>
            </PreviewWrapper>
          </Suspense>
        );
      case 'image/tiff':
        return (
          <PreviewWrapper>
            <FontAwesomeIcon icon="file-image" className={styles.pdfIcon} onClick={open} />
          </PreviewWrapper>
        );
      case 'image/jpeg':
      case 'image/png':
      case 'image/gif':
      case 'image/webp':
      case 'image':
        return (
          <PreviewWrapper>
            <div className={styles.imageWrapper}>
              <img
                src={file.url}
                className={styles.image}
                onClick={open}
                style={{ transform: `rotate(${rotateAngle || 0}deg)` }}
              />
            </div>
          </PreviewWrapper>
        );
      default:
        return (
          <PreviewWrapper>
            <FontAwesomeIcon icon="file" className={styles.pdfIcon} onClick={open} />
          </PreviewWrapper>
        );
    }
  };

  return (
    <div className={cn(styles.root, { [className]: className, [styles.rootRejected]: rejected })}>
      <div className={styles.imageCard}>
        <div className={styles.fileInfo}>
          {checked && <FontAwesomeIcon icon="check-square" className={styles.checkedIcon} />}
        </div>
        {renderPreview(file)}
      </div>
    </div>
  );
};
