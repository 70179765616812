import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';

import cn from 'classnames';
import { countries } from 'constants/countries';
import { FieldTypes } from 'constants/field-types-enum';
import { getIgnoredFields } from 'constants/logic';
import { textWithReferences } from 'helpers/form';
import { Controller, useForm } from 'react-hook-form';
import { CustomButton } from 'views/CandidateApp/components/CustomButton/CustomButton';

import {
  DatePicker,
  FilePicker,
  FormMessage,
  MessageType,
  MultipleChoice,
  OpinionScale,
  PhoneNumberPicker,
  Select,
  Signature,
  StarRating,
  Textarea,
} from 'components/FormComponents';

import styles from './Form.module.scss';

export const Form = forwardRef(
  (
    {
      candidateId,
      country = 'NZ',
      form,
      brand,
      buttonTitle = 'Save',
      textParams = {},
      onSubmit = () => {},
      getCandidateUpload = () => {},
      deleteCandidateUpload = () => {},
      signCandidateUpload = () => {},
      createCandidateUpload = () => {},
    },
    ref,
  ) => {
    const errorRef = useRef(null);

    const [showError, setShowError] = useState(false);
    const [logicIds, setLogicIds] = useState([]);
    const [ignoredFields, setIgnoredFields] = useState([]);

    const {
      register,
      handleSubmit,
      watch,
      reset,
      formState: { errors },
      control,
    } = useForm({
      shouldFocusError: false,
      shouldUnregister: true,
      resetOptions: {
        keepErrors: true,
        keepValues: false,
        keepDirtyValues: false,
      },
    });

    // Expose functions
    useImperativeHandle(ref, () => ({
      reset,
    }));

    // Set logic IDs to watch
    useEffect(() => {
      if (!form) return;

      const logicOrders = form.logic_jumps.map(logic => logic.from);
      const fieldsWithLogic = form.fields.filter(field => logicOrders.includes(field.order));
      setLogicIds(fieldsWithLogic.map(fields => `${fields.id}`));
    }, [form]);

    // Hide fields by logic
    useEffect(() => {
      let ignoredFields = [];
      form?.logic_jumps.forEach(logic => {
        const field = form.fields.find(field => field.order === logic.field);
        const value = watch(`${field.id}`);
        const logicIgnoredFields = getIgnoredFields(logic, value);
        ignoredFields = [...ignoredFields, ...logicIgnoredFields];
      });
      setIgnoredFields(ignoredFields);
      for (const ignoreField of ignoredFields) {
        const field = form.fields.find(field => field.order === ignoreField);
        control.unregister(`${field.id}`);
      }
    }, [JSON.stringify(watch(logicIds)), form]);

    const onError = () => {
      errorRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      setShowError(true);
    };

    const renderField = field => {
      switch (field.type) {
        case FieldTypes.FreeText:
          return (
            <Textarea
              register={register}
              name={`${field.id}`}
              validators={{ required: field.required }}
              noErrorMessage
              className={styles.textInput}
              placeholder="Enter your answer"
              dynamicHeight={true}
            />
          );
        case FieldTypes.MultipleChoice:
          return (
            <Controller
              control={control}
              name={`${field.id}`}
              rules={{ required: field.required }}
              render={({ field: { onChange, value } }) => (
                <MultipleChoice
                  value={value}
                  onChange={onChange}
                  options={{
                    multipleSelection: field.options?.multiple_selection,
                    otherOption: field.options?.other_option,
                    options: field.options?.options,
                  }}
                />
              )}
            />
          );
        case FieldTypes.OpinionScale:
          return (
            <Controller
              control={control}
              name={`${field.id}`}
              render={({ field: { onChange, value } }) => (
                <OpinionScale options={field.options} value={value} onChange={onChange} />
              )}
            />
          );
        case FieldTypes.StarRating:
          return (
            <Controller
              control={control}
              name={`${field.id}`}
              render={({ field: { onChange, value } }) => (
                <StarRating options={field.options} value={value} onChange={onChange} />
              )}
            />
          );
        case FieldTypes.YesNo:
          return (
            <Controller
              control={control}
              name={`${field.id}`}
              rules={{ required: field.required }}
              render={({ field: { onChange, value } }) => (
                <MultipleChoice
                  value={value}
                  onChange={onChange}
                  options={{
                    multipleSelection: false,
                    otherOption: false,
                    options: [{ value: 'Yes' }, { value: 'No' }],
                  }}
                />
              )}
            />
          );
        case FieldTypes.Date:
          return (
            <Controller
              control={control}
              name={`${field.id}`}
              rules={{ required: field.required }}
              render={({ field: { onChange, value } }) => (
                <DatePicker
                  outputFormat="YYYY-MM-DD"
                  value={value}
                  onChange={onChange}
                  useDefaultToday={!!field.options?.useDefault}
                />
              )}
            />
          );
        case FieldTypes.Country:
          return (
            <Select
              name={`${field.id}`}
              register={register}
              value={watch(`${field.id}`)}
              useDefault>
              {countries.map(country => (
                <Select.Item key={country.value} value={country.value}>
                  {country.name}
                </Select.Item>
              ))}
            </Select>
          );
        case FieldTypes.PhoneNumber:
          return (
            <Controller
              key={field.id}
              control={control}
              name={`${field.id}`}
              rules={{ required: field.required }}
              render={({ field: { onChange, value, ref } }) => (
                <PhoneNumberPicker
                  value={value}
                  defaultCountry={country}
                  className={styles.phone}
                  onChange={onChange}
                />
              )}
            />
          );
        case FieldTypes.Upload:
          return (
            <Controller
              key={field.id}
              control={control}
              name={`${field.id}`}
              rules={{ required: field.required }}
              render={({ field: { onChange, value } }) => (
                <FilePicker
                  className={styles.filePicker}
                  onChange={onChange}
                  accept={{
                    'application/pdf': ['.pdf'],
                    'application/msword': ['.doc', '.docx'],
                    'image/*': [],
                  }}
                  value={value}
                  modelParams={{ form_field_id: field.id, candidate_id: candidateId }}
                  getUpload={getCandidateUpload}
                  signUpload={signCandidateUpload}
                  createUpload={createCandidateUpload}
                  onDeleteUpload={deleteCandidateUpload}
                />
              )}
            />
          );
        case FieldTypes.Signature:
          return (
            <Controller
              key={field.id}
              control={control}
              name={`${field.id}`}
              rules={{ required: field.required }}
              render={({ field: { onChange, value } }) => (
                <Signature
                  className={styles.signature}
                  modelParams={{ form_field_id: field.id, candidate_id: candidateId }}
                  signatureId={value}
                  onChange={onChange}
                  getUpload={getCandidateUpload}
                  signUpload={signCandidateUpload}
                  createUpload={createCandidateUpload}
                  onDeleteUpload={deleteCandidateUpload}
                />
              )}
            />
          );
      }
    };

    return (
      <form className="u-width-100">
        <div ref={errorRef} />
        {showError && (
          <FormMessage
            type={MessageType.Error}
            message="There are some invalid values. Please check the fields marked in red and re-submit."
          />
        )}
        {form?.fields
          .filter(field => !ignoredFields.includes(field.order))
          .map(field => (
            <div key={field.id} className={styles.container}>
              <p
                className={cn(styles.question, {
                  [styles.questionWithError]: !!errors?.[`${field.id}`]?.type,
                })}
                dangerouslySetInnerHTML={{
                  __html: textWithReferences(field.text, textParams) + (field.required ? '*' : ''),
                }}
              />
              {field.use_description && (
                <p
                  className={styles.description}
                  dangerouslySetInnerHTML={{
                    __html: textWithReferences(field.description, textParams),
                  }}
                />
              )}
              {renderField(field)}
            </div>
          ))}

        <CustomButton
          className="u-margin-top--large"
          small
          brand={brand}
          onClick={handleSubmit(onSubmit, onError)}>
          {buttonTitle}
        </CustomButton>
      </form>
    );
  },
);
