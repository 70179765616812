import { useEffect, useRef, useState } from 'react';

import cn from 'classnames';
import FeatherIcon from 'feather-icons-react';
import { useClickOutside } from 'hooks/click-outside';
import { useCombinedRefs } from 'hooks/combined-refs';
import { useTimer } from 'hooks/timer';
import { ChangeEvent } from 'utils/form-utils';

import DisplayBox from 'components/DisplayBox/DisplayBox';

import styles from './AsyncSearch.module.scss';

export const AsyncSearch = ({ placeholder, className, asyncSearch, name, register }) => {
  const { ref, ...registerProps } = register(name);
  const inputRef = useRef(null);
  const combinedRef = useCombinedRefs(ref, inputRef);

  const [inputValue, setInputValue] = useState('');
  const [showMenu, setShowMenu] = useState(false);
  const [options, setOptions] = useState([]);

  const [value, setValue] = useState();

  const wrapperRef = useRef(null);

  const resetTimer = useTimer(1, () => handleSearch(inputValue));

  useClickOutside(wrapperRef, () => setShowMenu(false));

  const handleChange = e => {
    setInputValue(e.target.value);
    resetTimer();
  };

  useEffect(() => {
    handleSearch();
  }, []);

  const handleSearch = async () => {
    const options = await asyncSearch(inputValue);

    setOptions(options);
    setShowMenu(true);
  };

  const handleSelected = option => {
    registerProps.onChange(ChangeEvent(name, option.value));
    setShowMenu(false);
    setValue(option);
  };

  const removeSelection = () => {
    registerProps.onChange(ChangeEvent(name, null));
    setValue(null);
  };

  return (
    <div className={cn(styles.root, { [className]: className })}>
      <div className="position-relative" ref={wrapperRef}>
        <FeatherIcon className={styles.icon} icon="search" />

        <input hidden {...registerProps} name={name} ref={combinedRef} />

        <input
          type="text"
          onChange={handleChange}
          className={styles.input}
          placeholder={placeholder}
          onClick={() => setShowMenu(!showMenu)}
        />

        <div className={cn(styles.menu, { [styles.menuVisible]: showMenu })}>
          <ul>
            {options.map(option => (
              <li key={option.value} className={styles.item} onClick={() => handleSelected(option)}>
                {option.name}
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="u-margin-top">
        <DisplayBox show={!!value} cancelable onCancel={removeSelection}>
          {value?.name}
        </DisplayBox>
      </div>
    </div>
  );
};
