// checkHistoryActions.js
import React from 'react';

import cn from 'classnames';

import { OverlayCard } from 'components/Cards/OverlayCards';
import { UserInfo } from 'components/Data/UserInfo';

import styles from './../components/HistoryRow/HistoryRow.module.scss';
import { CheckHistoryTypes } from './check-history-types';

/**
 * Each action is defined as a function that takes a data object.
 * This data object contains all the values required to generate the icon and description.
 *
 */
const icons = {
  customNote: 'edit',
  customInfo: 'info',
  customSuccess: 'check-circle',
  customWarning: 'alert-triangle',
  customError: 'alert-circle',
};

const createCustomAction =
  type =>
  ({ userName, entry, companyUsers }) => ({
    icon: icons[type],
    description: (
      <p>
        {userName}:{' '}
        <span className={cn(styles.wrap, 'd-flex flex-wrap')}>
          {entry?.message?.split(/(@\[.*?\]\(\d+\))/).map((part, index, array) => {
            const match = part.match(/@\[(.*?)\]\((\d+)\)/);
            if (match) {
              const [_, name, id] = match;
              const userInfo = companyUsers?.find(user => user.id === Number(id));
              return userInfo ? (
                <OverlayCard name={name} key={`${id}-${index}`} className={styles.userLink}>
                  <UserInfo
                    display={`${userInfo.first_name} ${userInfo.last_name}`}
                    email={userInfo.email}
                    firstName={userInfo.first_name}
                    lastName={userInfo.last_name}
                  />
                </OverlayCard>
              ) : (
                <span key={`${id}-${index}`}>{name}</span>
              );
            }
            return part;
          })}
        </span>
      </p>
    ),
  });

export const checkHistoryActions = {
  [CheckHistoryTypes.CompletedCheck]: ({ refCandidate, referenceLink }) => ({
    icon: 'check-circle',
    description: (
      <p>
        Your check is completed for {refCandidate}. {referenceLink}
      </p>
    ),
  }),
  [CheckHistoryTypes.CompletedReferee]: ({ refReferee, country, referenceLink }) => ({
    icon: 'check-circle',
    description: (
      <p>
        Reference check completed by {refReferee} {country}. {referenceLink}
      </p>
    ),
  }),
  [CheckHistoryTypes.CompletedRefereeIpError]: ({
    refReferee,
    country,
    refCandidate,
    referenceLink,
  }) => ({
    icon: 'alert-triangle',
    description: (
      <p>
        Reference check completed by {refReferee} {country} using the same IP address as the
        candidate {refCandidate}. {referenceLink}
      </p>
    ),
  }),
  [CheckHistoryTypes.CompletedCandidate]: ({
    refCandidate,
    candidateRefereeLinks,
    country,
    referees,
  }) =>
    referees && referees.length > 0
      ? {
          icon: 'check-circle',
          description: (
            <p>
              Candidate {refCandidate} successfully provided referee details for{' '}
              <span>{candidateRefereeLinks}</span> {country}
            </p>
          ),
        }
      : {
          icon: 'check-circle',
          description: (
            <p>
              Candidate {refCandidate} successfully provided referee details. {country}
            </p>
          ),
        },
  [CheckHistoryTypes.UpdatedEmail]: ({ userName, refCandidate, refReferee, referee }) => ({
    icon: 'mail',
    description: (
      <p>
        {userName} updated {referee ? refReferee : refCandidate}'s email.
      </p>
    ),
  }),
  [CheckHistoryTypes.UpdatedContactNumber]: ({ userName, refCandidate, refReferee, referee }) => ({
    icon: 'phone',
    description: (
      <p>
        {userName} updated {referee ? refReferee : refCandidate}'s phone number.
      </p>
    ),
  }),
  [CheckHistoryTypes.UpdatedTeamName]: ({ userName, refCandidate }) => ({
    icon: 'edit',
    description: (
      <p>
        {userName} updated {refCandidate}'s team information.
      </p>
    ),
  }),
  [CheckHistoryTypes.UpdatedJobRole]: ({ userName, refCandidate }) => ({
    icon: 'edit',
    description: (
      <p>
        {userName} updated {refCandidate}'s job title.
      </p>
    ),
  }),
  [CheckHistoryTypes.RequestSentCandidate]: ({ userName, refCandidate }) => ({
    icon: 'send',
    description: (
      <p>
        {userName} sent candidate {refCandidate} a new request.
      </p>
    ),
  }),
  [CheckHistoryTypes.RequestOverdueCandidate]: ({
    refCandidate,
    candidate,
    onCandidateRequest,
  }) => ({
    icon: 'alert-circle',
    description: (
      <p>
        Check for candidate {refCandidate} overdue.{' '}
        {candidate.status === 'Candidate' && (
          <a onClick={() => onCandidateRequest()}>Resend request</a>
        )}
      </p>
    ),
  }),
  [CheckHistoryTypes.RequestSentReferee]: ({ userName, refReferee }) => ({
    icon: 'send',
    description: (
      <p>
        {userName} sent referee {refReferee} a new request.
      </p>
    ),
  }),
  [CheckHistoryTypes.RequestOverdueReferee]: ({ refReferee, referee, onRefereeRequest }) => ({
    icon: 'alert-circle',
    description: (
      <p>
        Reference request for referee {refReferee} overdue.{' '}
        {referee && !referee.completed && (
          <a onClick={() => onRefereeRequest(referee.id)}>Resend request</a>
        )}
      </p>
    ),
  }),
  [CheckHistoryTypes.CandidateEmailFailed]: ({ refCandidate }) => ({
    icon: 'alert-circle',
    description: (
      <p>
        Email couldn't be sent to {refCandidate}. You tried to send to a recipient that has been
        marked as inactive.
      </p>
    ),
  }),
  [CheckHistoryTypes.RefereeEmailFailed]: ({ refReferee }) => ({
    icon: 'alert-circle',
    description: (
      <p>
        Email couldn't be sent to referee {refReferee}. You tried to send to a recipient that has
        been marked as inactive.
      </p>
    ),
  }),
  [CheckHistoryTypes.NewReferee]: ({ userName, refReferee }) => ({
    icon: 'user-plus',
    description: (
      <p>
        {userName} added new referee {refReferee}
      </p>
    ),
  }),
  [CheckHistoryTypes.NewCheck]: ({
    userName,
    checkHuman,
    refCandidate,
    backgroundChecks,
    entry,
  }) => ({
    icon: 'check-circle',
    description: (
      <p>
        {userName} created a new {checkHuman} for candidate.{' '}
        {entry.check_type === CheckHistoryTypes.CheckReference ? refCandidate : backgroundChecks}
      </p>
    ),
  }),
  [CheckHistoryTypes.BackgroundCheckSubmitted]: ({ refCandidate }) => ({
    icon: 'check-circle',
    description: <p>{refCandidate} has completed the background application.</p>,
  }),
  [CheckHistoryTypes.BackgroundCheckProcessing]: ({
    refCandidate,
    checkHuman,
    backgroundChecks,
  }) => ({
    icon: 'loader',
    description: (
      <p>
        {refCandidate}'s {checkHuman} is being processed. {backgroundChecks}
      </p>
    ),
  }),
  [CheckHistoryTypes.BackgroundCheckCompleted]: ({
    refCandidate,
    checkHuman,
    backgroundChecks,
  }) => ({
    icon: 'check-circle',
    description: (
      <p>
        {refCandidate}'s {checkHuman} has been completed. {backgroundChecks}
      </p>
    ),
  }),
  [CheckHistoryTypes.BackgroundCheckRejected]: ({ checkHuman, refCandidate }) => ({
    icon: 'alert-circle',
    description: (
      <p>
        {checkHuman} has been declined. New request sent to {refCandidate}.
      </p>
    ),
  }),
  [CheckHistoryTypes.BackgroundCheckUpdated]: ({ refCandidate, checkHuman, backgroundChecks }) => ({
    icon: 'refresh-ccw',
    description: (
      <p>
        {refCandidate} updated {checkHuman} declined fields. {backgroundChecks}
      </p>
    ),
  }),
  [CheckHistoryTypes.CheckCanceled]: ({ userName, refCandidate, checkHuman }) => ({
    icon: 'alert-circle',
    description: (
      <p>
        {userName} canceled {refCandidate}'s {checkHuman}.
      </p>
    ),
  }),
  [CheckHistoryTypes.CandidateEmailOpened]: ({ refCandidate }) => ({
    icon: 'info',
    description: <p>{refCandidate} has opened the email.</p>,
  }),
  [CheckHistoryTypes.CandidateSMSOpened]: ({ refCandidate }) => ({
    icon: 'info',
    description: <p>{refCandidate} has opened the SMS.</p>,
  }),
  [CheckHistoryTypes.RefereeSMSOpened]: ({ refReferee }) => ({
    icon: 'info',
    description: <p>{refReferee} has opened the SMS.</p>,
  }),
  [CheckHistoryTypes.RefereeEmailOpened]: ({ refReferee }) => ({
    icon: 'info',
    description: <p>{refReferee} has opened the email.</p>,
  }),
  [CheckHistoryTypes.CandidateEmailClicked]: ({ refCandidate }) => ({
    icon: 'info',
    description: <p>{refCandidate} has clicked a link in the email.</p>,
  }),
  [CheckHistoryTypes.RefereeEmailClicked]: ({ refReferee }) => ({
    icon: 'info',
    description: <p>{refReferee} has clicked a link in the email.</p>,
  }),
  [CheckHistoryTypes.CustomNote]: createCustomAction('customNote'),
  [CheckHistoryTypes.CustomInfo]: createCustomAction('customInfo'),
  [CheckHistoryTypes.CustomSuccess]: createCustomAction('customSuccess'),
  [CheckHistoryTypes.CustomWarning]: createCustomAction('customWarning'),
  [CheckHistoryTypes.CustomError]: createCustomAction('customError'),

  [CheckHistoryTypes.CheckDeclined]: ({ entry }) => ({
    icon: 'alert-circle',
    description: (
      <p>
        Check declined with reason: "<span className={styles.wrap}>{entry.message}</span>"
      </p>
    ),
  }),
  [CheckHistoryTypes.CheckAddNotes]: ({ refCandidate, entry }) => ({
    icon: 'info',
    description: (
      <p>
        Checkmate notes for {refCandidate}: "<span className={styles.wrap}>{entry.message}</span>"
      </p>
    ),
  }),
  [CheckHistoryTypes.SubCheckCompleted]: ({ refCandidate, checkHuman, backgroundChecks }) => ({
    icon: 'check-circle',
    description: (
      <p>
        {refCandidate}'s {checkHuman} has a new partial result. {backgroundChecks}
      </p>
    ),
  }),
  [CheckHistoryTypes.CheckUpdateStatus]: ({ userName, checkHuman, entry }) => ({
    icon: 'info',
    description: (
      <p>
        {userName}{' '}
        <span className={styles.wrap}>
          marked the approval status of {checkHuman} as{' '}
          <span className="u-capitalize">"{entry.message?.replace('_', ' ')}"</span>
        </span>
      </p>
    ),
  }),
  [CheckHistoryTypes.RefereeDeleted]: ({ userName, entry }) => ({
    icon: 'alert-circle',
    description: (
      <p>
        {userName} deleted referee{' '}
        <b className={cn(styles.userRef, 'u-padding-left--small')}>
          <span className={styles.wrap}>{entry.message}</span>
        </b>
        .
      </p>
    ),
  }),
  [CheckHistoryTypes.RefereeReplacement]: ({ userName, refCandidate, refReferee }) => ({
    icon: 'alert-circle',
    description: (
      <p>
        {userName} asked {refCandidate} to replace {refReferee}.
      </p>
    ),
  }),
  [CheckHistoryTypes.ReminderCandidate]: ({ refCandidate }) => ({
    icon: 'info',
    description: <p>Reminder sent to @{refCandidate}.</p>,
  }),
  [CheckHistoryTypes.ReminderReferee]: ({ refReferee }) => ({
    icon: 'info',
    description: <p>Reminder sent to @{refReferee}.</p>,
  }),
  [CheckHistoryTypes.UpdatedCandidateTeam]: ({ userName, refCandidate }) => ({
    icon: 'edit',
    description: (
      <p>
        {userName} updated {refCandidate}'s team.
      </p>
    ),
  }),
  [CheckHistoryTypes.CheckResult]: ({ checkHuman, userName }) => ({
    icon: 'info',
    description: (
      <p>
        {checkHuman} report downloaded by <span style={{ paddingLeft: '3px' }}> {userName}</span>
      </p>
    ),
  }),
  [CheckHistoryTypes.TransferCandidateAssignment]: ({ userName, entry }) => ({
    icon: 'info',
    description: (
      <p>
        {userName} set {entry.message} as the assigned candidate owner.
      </p>
    ),
  }),
  [CheckHistoryTypes.NewCandidateAssignment]: ({ userName, entry }) => ({
    icon: 'info',
    description: (
      <p>
        {userName} assigned candidate to {entry.message}.
      </p>
    ),
  }),
  [CheckHistoryTypes.CheckUndoCompletion]: ({ userName, refCandidate, entry }) => ({
    icon: 'alert-circle',
    description: (
      <p>
        {userName} undid the completion of {refCandidate}'s {entry.check_type} check.
      </p>
    ),
  }),
};
