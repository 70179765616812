import { useEffect } from 'react';

import { useDocumentTitle } from 'hooks/document-title';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import { Loading } from 'components/Loading/Loading';

import { authenticate, selectAuthentication } from 'api/authentication-slice';
import { useCandidateCheckLogViewResultMutation } from 'api/candidate-checks-api';
import { useSubCheckLogViewResultMutation } from 'api/sub-checks-api';
import { useGetUploadQuery } from 'api/upload-api';

export const Document = () => {
  useDocumentTitle('Document');

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const authentication = useSelector(selectAuthentication);

  const { id, model, model_id } = useParams();

  const [candidateCheckLogViewResult] = useCandidateCheckLogViewResultMutation();
  const [subCheckLogViewResult] = useSubCheckLogViewResultMutation();

  const {
    data: upload,
    isError,
    isSuccess,
  } = useGetUploadQuery(id, {
    skip: !authentication.checked,
  });

  useEffect(() => {
    dispatch(authenticate());
  }, []);

  useEffect(() => {
    if (!authentication.checked) return;

    if (!authentication.logged) {
      navigate(`/sign_in?redirect_uri=${window.location.pathname}`);
      return;
    }
  }, [authentication.checked]);

  useEffect(() => {
    if (!isSuccess) return;

    download();
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      window.location.replace('/unauthorized');
    }
  }, [isError]);

  const download = async () => {
    switch (model) {
      case 'candidate_check':
        await candidateCheckLogViewResult(model_id);
        break;
      case 'sub_check':
        await subCheckLogViewResult(model_id);
        break;
    }

    window.location.replace(upload.url, '_blank');
  };

  return <Loading active={true} />;
};
