import { useState } from 'react';
import { useEffect } from 'react';

import { List } from 'components/List/List';

import { AdminCompanyCheck } from '../../components/AdminCompanyCheck/AdminCompanyCheck';
import styles from './ChecksTab.module.scss';

export const ChecksTab = ({
  company,
  users,
  checkTypes,
  billingInfo,
  companyChecks,
  setLoading = () => {},
  onToggle = () => {},
  onSave = () => {},
  onAddCredits = () => {},
}) => {
  const [checkType, setCheckType] = useState();
  const [companyCheck, setCompanyCheck] = useState();

  const selectCheck = (companyCheck, checkType) => {
    setCheckType(checkType);
    setCompanyCheck(companyCheck);
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    if (checkType) {
      // Find the matching company check for the currently selected check type
      const updatedCompanyCheck = companyChecks?.find(cc => cc.type === checkType.slug);
      setCompanyCheck(updatedCompanyCheck);
    }
  }, [companyChecks, checkTypes, checkType]);

  return (
    <div className="d-flex align-items-start">
      <List
        title="Checks"
        subtitle="Configure the company checks below"
        className="u-margin-right--large u-flex-align-self-normal">
        <List.Category>
          {checkTypes?.map(checkType => {
            let companyCheck = companyChecks?.find(cc => cc.type === checkType.slug);

            return (
              <List.Item
                key={checkType.id}
                title={checkType.title}
                value={checkType.id}
                icon={companyCheck ? 'check' : null}
                iconClass={styles.enabledIcon}
                onClick={() => selectCheck(companyCheck, checkType)}
              />
            );
          })}
        </List.Category>
      </List>
      <div className="u-margin-left--large u-width-50">
        {checkType && (
          <AdminCompanyCheck
            key={checkType.slug}
            company={company}
            users={users}
            checkType={checkType}
            billingInfo={billingInfo}
            companyCheck={companyCheck}
            setLoading={setLoading}
            onToggle={onToggle}
            onSave={onSave}
            onAddCredits={onAddCredits}
          />
        )}
      </div>
    </div>
  );
};
