import { useEffect, useState } from 'react';

import cn from 'classnames';
import { MESSAGE_STATE_ERROR, MESSAGE_STATE_SUCCESS } from 'constants/message-app-state-contants';
import { Permissions } from 'constants/permissions';
import FeatherIcon from 'feather-icons-react';
import { timeDifference } from 'helpers/date';
import { errorRouter } from 'helpers/error-router';
import { useAccessControl } from 'hooks/access-control';
import { useDocumentTitle } from 'hooks/document-title';
import { useLocale } from 'hooks/locale';
import AppPage from 'layouts/AppPage/AppPage';
import { Badge } from 'react-bootstrap';
import ReactGA from 'react-ga';
import { useTranslation } from 'react-i18next';
import Moment from 'react-moment';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { BrandSelectionModal } from './components/BrandSelectionModal/BrandSelectionModal';
import { ActionsMenu } from 'components/ActionsMenu/ActionsMenu';
import Alert from 'components/Alert/Alert';
import CopyEmail from 'components/CopyEmail/CopyEmail';
import { IconButton } from 'components/IconButton/IconButton';
import { IconStatusButton } from 'components/IconStatusButton/IconStatusButton';
import { TabSelect } from 'components/Tabs/Tabs';
import { TagsMenu } from 'components/TagsMenu/TagsMenu';

import { messagePopUp } from 'api/app-slice';
import { useLazyGetBrandQuery } from 'api/brands-api';
import { getBrands, selectBrands } from 'api/brands-slice';
import {
  useCancelCandidateCheckMutation,
  useCreateCandidateCheckMutation,
  useLazyGetCandidateCheckResultQuery,
  useUpdateCandidateCheckMutation,
} from 'api/candidate-checks-api';
import { getCustomFields, selectCustomFields } from 'api/candidate-custom-fields-slice';
import {
  useAddCandidateHistoryMutation,
  useAddTagToCandidateMutation,
  useCancelCandidateChecksMutation,
  useGetCandidateChecksQuery,
  useGetCandidateRefereesQuery,
  useLazyGetCandidateReportQuery,
  useLazyGetCombinedPdfReportQuery,
  useRemoveTagFromCandidateMutation,
  useSendCandidateRequestEmailMutation,
} from 'api/candidates-api';
import { deleteCandidate, getCandidate, updateCandidate } from 'api/candidates-slice';
import { selectCandidates } from 'api/candidates-slice';
import { selectCompanyChecks } from 'api/company-checks-slice';
import { selectCompany } from 'api/company-slice';
import { getForms } from 'api/forms-slice';
import { selectForms } from 'api/forms-slice';
import { useGetIdVerificationQuery } from 'api/id-verifications-api';
import {
  useCreateRefereeMutation,
  useDeleteRefereeMutation,
  useLazyGetPdfReportQuery,
  useSendRefereeRequestEmailMutation,
  useSendReplacementRequestEmailMutation,
  useUpdateRefereeMutation,
} from 'api/referees-api';
import { getTags } from 'api/tags-slice';
import { selectTags } from 'api/tags-slice';
import { useGetUserTeamsQuery } from 'api/user-api';
import { selectPermissions } from 'api/user-permissions-slice';
import { selectUser } from 'api/user-slice';

import { AddCheckModal } from '../../components/AddCheckModal/AddCheckModal';
import styles from './Check.module.scss';
import { Candidate } from './tabs/Candidate/Candidate';
import { Checks } from './tabs/Checks/Checks';
import { History } from './tabs/History/History';
import { Questionnaire } from './tabs/Questionnaire/Questionnaire';
import { Referees } from './tabs/Referees/Referees';
import { Reference } from './tabs/Reference/Reference';
import { Reports } from './tabs/Reports/Reports';

const Check = () => {
  const { id, tab, target } = useParams();

  const user = useSelector(selectUser);
  const company = useSelector(selectCompany);
  const companyChecks = useSelector(selectCompanyChecks);
  const forms = useSelector(selectForms);
  const candidates = useSelector(selectCandidates);
  const tags = useSelector(selectTags);
  const permissions = useSelector(selectPermissions);
  const customFields = useSelector(selectCustomFields);
  const brands = useSelector(selectBrands);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const hasAccess = useAccessControl(permissions);

  const { data: checksData } = useGetCandidateChecksQuery(id, {
    refetchOnMountOrArgChange: true,
  });
  const { data: refereesData } = useGetCandidateRefereesQuery(id, {
    refetchOnMountOrArgChange: true,
  });
  const { dateSlashFormat } = useLocale(company?.locale);
  const { data: teams } = useGetUserTeamsQuery(user.id);
  const { data: idVerification } = useGetIdVerificationQuery(id);

  const [getCandidateCheckResult] = useLazyGetCandidateCheckResultQuery();
  const [getBrand] = useLazyGetBrandQuery();

  const [addTag] = useAddTagToCandidateMutation();
  const [removeTag] = useRemoveTagFromCandidateMutation();
  const [cancelCandidateChecks] = useCancelCandidateChecksMutation();
  const [sendCandidateRequestEmail] = useSendCandidateRequestEmailMutation();
  const [sendRefereeRequestEmail] = useSendRefereeRequestEmailMutation();
  const [sendReplacementRequestEmail] = useSendReplacementRequestEmailMutation();
  const [addCandidateHistory] = useAddCandidateHistoryMutation();
  const [updateReferee] = useUpdateRefereeMutation();
  const [createReferee] = useCreateRefereeMutation();
  const [createCandidateCheck] = useCreateCandidateCheckMutation();
  const [cancelCandidateCheck] = useCancelCandidateCheckMutation();
  const [updateCandidateCheck] = useUpdateCandidateCheckMutation();
  const [deleteReferee] = useDeleteRefereeMutation();

  const [getCombinedPdfReport] = useLazyGetCombinedPdfReportQuery();
  const [getPdfReport] = useLazyGetPdfReportQuery();
  const [getCandidateReport] = useLazyGetCandidateReportQuery();

  const onMessagePopUp = (text, state, hide = true) =>
    dispatch(messagePopUp({ text, state, hide }));

  const [loading, setLoading] = useState(false);
  const [candidate, setCandidate] = useState();
  const [checks, setChecks] = useState([]);
  const [navigation, setNavigation] = useState({ previous: null, next: null });
  const [showTagsMenu, setShowTagsMenu] = useState(false);
  const [showNewChecksModal, setShowNewChecksModal] = useState(false);
  const [tabKey, setTabKey] = useState('candidate');

  const [referenceCheck, setReferenceCheck] = useState(null);
  const [customReferenceCheck, setCustomReferenceCheck] = useState(null);
  const [questionnaireCheck, setQuestionnaireCheck] = useState(null);
  const [referees, setReferees] = useState([]);
  const [refereesCompleted, setRefereesCompleted] = useState(0);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [emailLinkedOpened, setEmailLinkOpened] = useState(false);
  const [brand, setBrand] = useState();
  const [alertDelete, setAlertDelete] = useState(false);
  const [showBrandModal, setShowBrandModal] = useState(false);

  const { t } = useTranslation();

  useDocumentTitle(candidate ? candidate.name : 'Loading...');

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  useEffect(() => {
    if (checksData) setChecks(checksData);
  }, [checksData]);
  useEffect(() => {
    if (refereesData) setReferees(refereesData);
  }, [refereesData]);

  // Load candidate
  useEffect(() => {
    const loadData = async () => {
      try {
        setLoading(true);
        const [, , brandsResult] = await Promise.all([
          dispatch(getTags()),
          dispatch(getCustomFields()),
          dispatch(getBrands()),
        ]);

        const { payload: candidate } = await dispatch(getCandidate(id));
        setCandidate(candidate);

        try {
          const brand = await getBrand(candidate.brand_id).unwrap();
          setBrand(brand);
        } catch (error) {
          console.error('Failed to get brand:', error);
          const loadedBrands = brandsResult?.payload || [];
          if (loadedBrands.length > 1) {
            setShowBrandModal(true);
          } else if (loadedBrands.length === 1) {
            await handleBrandSelection(loadedBrands[0], false);
          }
        }
      } catch (error) {
        navigate(errorRouter(error));
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, [id]);

  // Set navigation
  useEffect(() => {
    if (candidates.length < 1) return;

    let ids = candidates.map(candidate => candidate.id);
    let index = ids.findIndex(i => i == id);
    if (index < 0) return;

    let previous = index === 0 ? null : ids[index - 1];
    let next = index === ids.length - 1 ? null : ids[index + 1];

    setNavigation({ previous, next });
  }, [id, candidates.length]);

  // Set referees completed
  useEffect(() => {
    setRefereesCompleted(referees.filter(referee => referee.completed).length);
  }, [referees.length]);

  // Set special checks
  useEffect(() => {
    if (checks?.length < 1) return;

    setReferenceCheck(checks.find(check => check.type === 'reference' && check.canceled == false));
    setQuestionnaireCheck(
      checks.find(check => check.type === 'candidate_questionnaire' && check.canceled == false),
    );
    setCustomReferenceCheck(
      checks.find(check => check.type === 'custom_reference' && check.canceled == false),
    );
  }, [checks.length]);

  const setTabPath = key => {
    setTabKey(key);
    navigate(`/check/${id}/${key}`);
  };

  // TEMPLATE

  const handleAddTag = async tagId => {
    setLoading(true);
    setShowTagsMenu(false);
    try {
      const { data: tag } = await addTag({ id, tagId });
      setCandidate({ ...candidate, tags: [...candidate.tags, tag] });
    } catch (e) {}
    setLoading(false);
  };

  const hanleCandidateCancelation = async () => {
    setShowCancelModal(prevState => !prevState);
    setLoading(true);
    const { data: result } = await cancelCandidateChecks(id);
    setCandidate(result);
    setLoading(false);
  };

  const handleArchiveToggle = async () => {
    setLoading(true);
    const { payload } = await dispatch(
      updateCandidate({ id, params: { archived: !candidate.archived } }),
    );
    setCandidate(payload);
    setLoading(false);
  };

  const handleCheckDeletion = async () => {
    setLoading(true);
    await dispatch(deleteCandidate(id));
    setLoading(false);
    navigate('/dashboard');
  };

  const handleCandidateDeletion = () => {
    setAlertDelete(true);
  };

  const onCheckDelete = async () => {
    setAlertDelete(false);
    setLoading(true);
    await dispatch(deleteCandidate(id));
    dispatch(messagePopUp({ text: 'Candidate deleted', state: MESSAGE_STATE_SUCCESS, hide: true }));
    setLoading(false);
    navigate('/dashboard');
  };

  const handleDownloadAll = async () => {
    setLoading(true);
    await getCandidateReport(candidate).unwrap();
    setLoading(false);
  };

  const handleTagDeletion = async tagId => {
    setLoading(true);
    await removeTag({ id, tagId });
    setCandidate({ ...candidate, tags: candidate.tags.filter(tag => tag.id !== tagId) });
    setLoading(false);
  };

  const hanldeSendCandidateRequestEmail = async () => {
    setLoading(true);
    await sendCandidateRequestEmail(id);
    onMessagePopUp('New candidate request Sent', MESSAGE_STATE_SUCCESS);
    setLoading(false);
  };

  const hanldeSendRefereeRequestEmail = async refereeId => {
    setLoading(true);
    const { data: result } = await sendRefereeRequestEmail(refereeId);
    if (result) onMessagePopUp('New referee request Sent', MESSAGE_STATE_SUCCESS);
    else onMessagePopUp('Request not sent', MESSAGE_STATE_ERROR);
    setLoading(false);
  };

  const handleUpdateCandidate = async params => {
    setLoading(true);
    const { payload: candidate } = await dispatch(updateCandidate({ id, params }));
    setCandidate(candidate);
    setLoading(false);
  };

  const handleGetPdfReport = async (id, filename, combined = false) => {
    setLoading(true);
    if (combined) await getCombinedPdfReport({ id, filename });
    else await getPdfReport({ id, filename });
    setLoading(false);
  };

  const getCombinedReport = async check => {
    setLoading(true);
    await getCandidateCheckResult({ check, candidate });
    setLoading(false);
  };

  const handleNewChecks = async checksData => {
    setShowNewChecksModal(false);
    setLoading(true);
    const { data: candidateChecks } = await createCandidateCheck({
      candidateId: candidate.id,
      data: checksData.checks,
    });
    setChecks(candidateChecks);
    setCandidate({ ...candidate, submitted_at: null, completed_at: null, status: 'candidate' });
    setLoading(false);
  };

  const handleCancelCheck = async candidateCheckId => {
    setLoading(true);
    const { data: updatedCheck } = await cancelCandidateCheck(candidateCheckId);
    setChecks(checks.map(check => (check.id === updatedCheck.id ? updatedCheck : check)));
    setLoading(false);
  };

  const handleCheckUpdate = async (candidateCheckId, data) => {
    setLoading(true);
    const { data: updatedCheck } = await updateCandidateCheck({ id: candidateCheckId, data });
    setChecks(checks.map(check => (check.id === updatedCheck.id ? updatedCheck : check)));
    setLoading(false);
  };

  const handleShowNewChecksModal = async () => {
    setLoading(true);
    await dispatch(getForms());
    setShowNewChecksModal(true);
    setLoading(false);
  };

  const handleCreateReferee = async data => {
    setLoading(true);
    const { data: referee } = await createReferee(data);
    setReferees([...referees, referee]);
    setLoading(false);
    return referee;
  };

  const handleRefereeUpdate = async (id, data) => {
    setLoading(true);
    const { data: updatedReferee } = await updateReferee({ id, data });
    setReferees(
      referees.map(referee => (referee.id === updatedReferee.id ? updatedReferee : referee)),
    );
    setLoading(false);
  };

  const handleRefereeDeleted = async id => {
    setLoading(true);
    await deleteReferee(id);
    setReferees(referees.filter(referee => referee.id !== id));
    setLoading(false);
  };

  const handleRefereeCancelation = async id => {
    setLoading(true);
    await updateReferee({ id, data: { canceled: true } });
    setReferees(referees.filter(referee => referee.id !== id));
    setLoading(false);
  };

  const handleBrandSelection = async (selectedBrand, displayMessage = true) => {
    setLoading(true);
    try {
      const { payload: updatedCandidate } = await dispatch(
        updateCandidate({
          id,
          params: { brand_id: selectedBrand.id },
        }),
      );
      setCandidate(updatedCandidate);
      setBrand(selectedBrand);
      setShowBrandModal(false);
      if (displayMessage) {
        onMessagePopUp('Brand updated successfully', MESSAGE_STATE_SUCCESS);
      }
    } catch (error) {
      onMessagePopUp('Failed to update brand', MESSAGE_STATE_ERROR);
    }
    setLoading(false);
  };

  return (
    <AppPage loading={loading}>
      <BrandSelectionModal
        visible={showBrandModal}
        onClose={() => setShowBrandModal(false)}
        brands={brands}
        onUpdate={handleBrandSelection}
        loading={loading}
      />
      <AddCheckModal
        companyChecks={companyChecks}
        forms={forms}
        visible={showNewChecksModal}
        onSubmit={handleNewChecks}
        onClose={() => setShowNewChecksModal(false)}
        className={styles.addModalCheck}
      />
      <Alert
        show={alertDelete}
        title={t('check.deleteCandidateTitle')}
        message={t('check.deleteCandidateMessage')}
        ok="Yes"
        onOk={onCheckDelete}
        cancel="Cancel"
        onCancel={() => setAlertDelete(false)}
      />
      {candidate && (
        <div className={styles.root}>
          {!candidate.completed_at && hasAccess(Permissions.CandidateWrite) && (
            <Alert
              show={showCancelModal}
              title={t('check.cancelCandidateTitle')}
              ok={t('check.cancelCandidateOk')}
              cancel={t('check.cancelCandidateCancel')}
              onOk={hanleCandidateCancelation}
              onCancel={() => setShowCancelModal(false)}
            />
          )}
          <div className={styles.header}>
            <div className="d-flex justify-content-between align-items-start">
              <div>
                <p className={'u-padding-right--small u-margin-bottom--small t-small'}>
                  <Link className="u-link dark" to="/dashboard">
                    DASHBOARD
                  </Link>{' '}
                  / CHECK FOR
                </p>
                <div className="d-flex justify-content-start align-items-start">
                  <h1 className="title-2">{candidate.name}</h1>
                  <ActionsMenu className={styles.showMedium} small>
                    {hasAccess(Permissions.CandidateWrite) && (
                      <ActionsMenu.Item
                        icon="x"
                        title={t('check.actions.cancelCandidateButton')}
                        onClick={() => setShowCancelModal(prevState => !prevState)}
                        disabled={candidate.completed_at}
                      />
                    )}
                    {hasAccess(Permissions.CandidateWrite) && (
                      <ActionsMenu.Item
                        icon="archive"
                        title={
                          candidate.archived
                            ? t('check.actions.restore')
                            : t('check.actions.archive')
                        }
                        onClick={handleArchiveToggle}
                      />
                    )}
                    {hasAccess(Permissions.CandidateWrite) && (
                      <ActionsMenu.Item
                        icon="edit"
                        title={t('check.actions.addNote')}
                        onClick={() => {
                          setTabKey('history');
                          navigate(`/check/${id}/history/new`);
                        }}
                      />
                    )}
                    {hasAccess(Permissions.CandidateWrite) && (
                      <ActionsMenu.Item
                        icon="trash-2"
                        title={t('check.actions.delete')}
                        onClick={handleCheckDeletion}
                      />
                    )}
                    {hasAccess(Permissions.CandidateChecksResults) &&
                      candidate.completed_at &&
                      !candidate.canceled && (
                        <ActionsMenu.Item
                          icon="download"
                          title={t('check.actions.downloadAll')}
                          onClick={handleDownloadAll}
                        />
                      )}
                  </ActionsMenu>
                  <div className={cn(styles.hideMedium, 'd-flex')}>
                    {hasAccess(Permissions.CandidateWrite) && (
                      <IconButton
                        icon="x"
                        small
                        className={cn(styles.icon, 'u-margin-left')}
                        tip={t('check.actions.cancelCandidateButton')}
                        onClick={() => setShowCancelModal(prevState => !prevState)}
                        disabled={candidate.completed_at}
                      />
                    )}
                    {hasAccess(Permissions.CandidateWrite) && (
                      <IconButton
                        icon="archive"
                        small
                        className={styles.icon}
                        tip={
                          candidate.archived
                            ? t('check.actions.restore')
                            : t('check.actions.archive')
                        }
                        active={candidate.archived}
                        onClick={handleArchiveToggle}
                      />
                    )}
                    {hasAccess(Permissions.CandidateWrite) && (
                      <div style={{ position: 'relative' }}>
                        <IconButton
                          icon="tag"
                          small
                          className={styles.icon}
                          tip={t('check.actions.tag')}
                          onClick={() => setShowTagsMenu(!showTagsMenu)}
                          disabled={tags.length < 1}
                        />
                        <TagsMenu
                          visible={showTagsMenu}
                          onClose={() => setShowTagsMenu(false)}
                          tags={tags}
                          onSelect={handleAddTag}
                          hideDeletion={true}
                          position="bottom"
                        />
                      </div>
                    )}
                    {hasAccess(Permissions.CandidateWrite) && (
                      <IconButton
                        icon="edit"
                        small
                        className={styles.icon}
                        tip={t('check.actions.addNote')}
                        onClick={() => {
                          setTabKey('history');
                          navigate(`/check/${id}/history/new`);
                        }}
                      />
                    )}
                    <div className={styles.emailLinkContainer}>
                      <IconButton
                        className={styles.icon}
                        icon="link"
                        small
                        tip={t('check.actions.candidateLink')}
                        onClick={() => setEmailLinkOpened(prevState => !prevState)}
                      />
                      <CopyEmail
                        open={emailLinkedOpened}
                        emailLink={`${process.env.REACT_APP_WEB_URL}/form_submission/candidate/${candidate.token}`}
                        title={t('check.copyCandidateLink')}
                        linkType="link"
                        setEmailLinkOpened={setEmailLinkOpened}
                      />
                    </div>
                    {hasAccess(Permissions.CandidateWrite) && (
                      <IconButton
                        icon="trash-2"
                        small
                        className={styles.icon}
                        tip={t('check.actions.delete')}
                        onClick={handleCandidateDeletion}
                      />
                    )}
                    {hasAccess(Permissions.CandidateChecksResults) &&
                      candidate.completed_at &&
                      !candidate.canceled && (
                        <IconButton
                          className={styles.icon}
                          icon="download"
                          small
                          tip={t('check.actions.downloadAll')}
                          onClick={handleDownloadAll}
                        />
                      )}
                  </div>
                </div>
              </div>
              <div
                className={cn(
                  styles.hideMobile,
                  'd-flex',
                  'align-items-start',
                  'justify-content-end',
                )}>
                <IconButton
                  icon="arrow-left"
                  className={cn(styles.arrowIcon, {
                    [styles['--disabled']]: !navigation.previous,
                  })}
                  tip={t('check.actions.previousCheck')}
                  onClick={() => navigate(`/check/${navigation.previous}/candidate`)}
                  disabled={!navigation.previous}
                />
                <IconButton
                  icon="arrow-right"
                  className={cn(styles.arrowIcon, {
                    [styles['--disabled']]: !navigation.next,
                  })}
                  tip={t('check.actions.nextCheck')}
                  onClick={() => navigate(`/check/${navigation.next}/candidate`)}
                  disabled={!navigation.next}
                />
              </div>
            </div>
            <div
              className={cn(
                styles.candidateDetails,
                'd-flex',
                'justify-content-start',
                'align-items-start',
                'u-padding-top',
                'u-width-100',
              )}>
              <div className="d-flex justify-content-start align-items-center">
                <Badge className={cn(styles.badge, `u-status-${candidate.status}`)}>
                  {candidate.status}
                </Badge>
                {checks.map(check => {
                  const checkType = companyChecks.find(
                    companyCheck => companyCheck.type === check.type,
                  )?.check_type;
                  if (!checkType) return;

                  return (
                    <IconStatusButton
                      key={check.id}
                      className={styles.icon}
                      icon={checkType.icon}
                      prefix="fa"
                      tooltip={`${checkType.title} ${check.status}`}
                      status={check.status}
                      onClick={() => navigate(`/check/${id}/background_checks`)}
                    />
                  );
                })}
              </div>
              <div className={cn(styles.infoFieldRow)}>
                {candidate.reference_code && (
                  <div className={styles.infoField}>
                    <p className={styles.title}>{t('check.infoFields.referenceCode')}</p>
                    <p className={styles.value}>{candidate.reference_code}</p>
                  </div>
                )}
                <div className={styles.infoField}>
                  <p className={styles.title}>{t('check.infoFields.role')}</p>
                  <p className={styles.value}>{candidate.job_role}</p>
                </div>
                <div className={styles.infoField}>
                  <p className={styles.title}>{t('check.infoFields.department')}</p>
                  <p className={styles.value}>{candidate.team_name}</p>
                </div>
                {referenceCheck && (
                  <div className={styles.infoField}>
                    <p className={styles.title}>{t('check.infoFields.completed')}</p>
                    <p className={styles.value}>
                      {refereesCompleted} of {referenceCheck.details.referees} Referees
                    </p>
                  </div>
                )}
                <div className={styles.infoField}>
                  <p className={styles.title}>{t('check.infoFields.createdOn')}</p>
                  <p className={styles.value}>
                    {candidate.created_at ? (
                      <Moment format={`${dateSlashFormat} @hh:mma`}>{candidate.created_at}</Moment>
                    ) : (
                      '-'
                    )}
                  </p>
                </div>
                <div className={styles.infoField}>
                  <p className={styles.title}>{t('check.infoFields.completedOn')}</p>
                  <p className={styles.value}>
                    {candidate.completed_at ? (
                      <Moment format={`${dateSlashFormat} @hh:mma`}>
                        {candidate.completed_at}
                      </Moment>
                    ) : (
                      '-'
                    )}
                  </p>
                </div>
                <div className={styles.infoField}>
                  <p className={styles.title}>{t('check.infoFields.timeToComplete')}</p>
                  <p className={styles.value}>
                    {candidate.completed_at
                      ? timeDifference(candidate.created_at, candidate.completed_at)
                      : '-'}
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.tags}>
              {candidate.tags?.map(tag => (
                <Badge className={styles.tag} key={tag.id}>
                  {tag.name}
                  <FeatherIcon
                    icon="x"
                    className={styles.tagDeleteBtn}
                    onClick={() => handleTagDeletion(tag.id)}
                  />
                </Badge>
              ))}
            </div>
          </div>

          <TabSelect
            id="accountTabs"
            onSelect={setTabPath}
            activeKey={tab}
            defaultActiveKey="candidate"
            newTabKey={tabKey}
            className="tabs"
            mountOnEnter={true}
            unmountOnExit={true}>
            <TabSelect.Tab eventKey="history" title={t('check.tabs.history')}>
              <History
                company={company}
                candidate={candidate}
                referees={referees}
                user={user}
                companyChecks={companyChecks}
                setLoading={setLoading}
                targetNewRecord={target === 'new'}
                onCandidateRequest={hanldeSendCandidateRequestEmail}
                onRefereeRequest={hanldeSendRefereeRequestEmail}
                createRecord={addCandidateHistory}
              />
            </TabSelect.Tab>
            <TabSelect.Tab eventKey="candidate" title={t('check.tabs.candidate')}>
              <Candidate
                company={company}
                candidate={candidate}
                idVerification={idVerification}
                teams={teams}
                customFields={customFields}
                handleCandidateUpdate={handleUpdateCandidate}
                sendRequestEmail={hanldeSendCandidateRequestEmail}
                brand={brand}
                user={user}
              />
            </TabSelect.Tab>
            {referenceCheck && (
              <TabSelect.Tab
                eventKey="referees"
                title={t('check.tabs.referees')}
                disabled={candidate.canceled}>
                <Referees
                  check={referenceCheck}
                  candidate={candidate}
                  company={company}
                  referees={referees}
                  setLoading={setLoading}
                  updateReferee={handleRefereeUpdate}
                  sendRequestEmail={hanldeSendRefereeRequestEmail}
                  targetId={target}
                  addReferee={handleCreateReferee}
                  deleteReferee={handleRefereeDeleted}
                  onMessagePopUp={onMessagePopUp}
                />
              </TabSelect.Tab>
            )}
            {referenceCheck && (
              <TabSelect.Tab
                eventKey="reports"
                title={t('check.tabs.refereeReport')}
                disabled={!referenceCheck || !refereesCompleted > 0}>
                <Reports
                  check={referenceCheck}
                  referees={referees}
                  candidate={candidate}
                  setLoading={setLoading}
                  getPdfReport={handleGetPdfReport}
                  targetId={target}
                  company={company}
                />
              </TabSelect.Tab>
            )}
            {customReferenceCheck && (
              <TabSelect.Tab eventKey="reference" title={t('check.tabs.referenceCheck')}>
                <Reference
                  company={company}
                  candidate={candidate}
                  check={customReferenceCheck}
                  referees={referees}
                  brand={brand}
                  messagePopUp={onMessagePopUp}
                  addReferee={handleCreateReferee}
                  setLoading={setLoading}
                  getPdfReport={handleGetPdfReport}
                  getCombinedReport={getCombinedReport}
                  updateReferee={handleRefereeUpdate}
                  deleteReferee={handleRefereeDeleted}
                  cancelReferee={handleRefereeCancelation}
                  sendRequestEmail={hanldeSendRefereeRequestEmail}
                  sendReplacementRequestEmail={sendReplacementRequestEmail}
                />
              </TabSelect.Tab>
            )}
            {questionnaireCheck && (
              <TabSelect.Tab eventKey="questionnaire" title={t('check.tabs.questionnaire')}>
                <Questionnaire
                  check={questionnaireCheck}
                  candidate={candidate}
                  setLoading={setLoading}
                />
              </TabSelect.Tab>
            )}
            <TabSelect.Tab eventKey="background_checks" title={t('check.tabs.backgroundChecks')}>
              <Checks
                company={company}
                candidate={candidate}
                checks={checks}
                companyChecks={companyChecks}
                setLoading={setLoading}
                showNewChecksModal={handleShowNewChecksModal}
                handleCancelCheck={handleCancelCheck}
                handleCheckUpdate={handleCheckUpdate}
              />
            </TabSelect.Tab>
          </TabSelect>
        </div>
      )}
    </AppPage>
  );
};

export default Check;
