import { useState } from 'react';

import { ID_TYPES } from 'helpers/checks';
import { useApiUploadActions } from 'hooks/upload-actions';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { AnimatedModal } from 'components/AnimatedModal/AnimatedModal';
import { Button, FilePicker, InputGroup, Select } from 'components/FormComponents';

export const CandidateDocumentUploadModal = ({
  candidate,
  company,
  visible,
  onUpdate = () => {},
  onClose = () => {},
}) => {
  const { t } = useTranslation();

  const { handleSubmit, control, reset, watch } = useForm({
    defaultValues: {
      id_type: 'other',
    },
  });

  const { signUpload, createUpload, deleteUpload } = useApiUploadActions();

  const [uploadData, setUploadData] = useState(null);

  const selectedIdType = watch('id_type');

  const setupUploadData = data => {
    setUploadData(data);
  };

  const onSubmit = async () => {
    try {
      await createUpload(uploadData);
      onUpdate();
      reset();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <AnimatedModal
      visible={visible}
      onClose={onClose}
      title="Upload document"
      description="Use this option to manually upload a candidate's identification document. This allows the candidate to submit missing or additional documentation via email for further review and processing."
      small
      icon="upload">
      {visible && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <InputGroup title="Document Type" className="u-margin-bottom">
            <Controller
              control={control}
              name="id_type"
              rules={{ required: true }}
              render={({ field, fieldState: { error } }) => (
                <Select
                  value={field.value}
                  inputProps={{
                    onChange: e => field.onChange(e.target.value),
                  }}
                  error={error ? t('common.validation.required') : null}>
                  {Object.entries(ID_TYPES).map(([key, type]) => (
                    <Select.Item key={key} value={type.code}>
                      {t(`candidate.identity.idTypes.${type.code}`)}
                    </Select.Item>
                  ))}
                </Select>
              )}
            />
          </InputGroup>
          <InputGroup title="Document" className="u-margin-top--large">
            <Controller
              control={control}
              name="upload_id"
              render={({ field: { value } }) => (
                <FilePicker
                  onChange={setupUploadData}
                  value={value}
                  metadata={{
                    result: selectedIdType,
                  }}
                  modelParams={{
                    candidate_id: candidate.id,
                    company_id: company.id,
                  }}
                  bucketFolder="background_applications"
                  signUpload={signUpload}
                  deleteUpload={deleteUpload}
                  createUploadAction={false}
                />
              )}
            />
          </InputGroup>

          <Button submit type="primary" large className="u-width-100 u-margin-top--large">
            Upload
          </Button>
        </form>
      )}
    </AnimatedModal>
  );
};
