import React, { useEffect, useRef, useState } from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';

import styles from './OverlayCards.module.scss';

export const OverlayCard = ({ name, children, className }) => {
  const [isHovered, setIsHovered] = useState(false);
  const overlayCardRef = useRef(null);
  const overlayLinkRef = useRef(null);
  const [position, setPosition] = useState('bottom');

  const checkPosition = () => {
    if (!overlayCardRef.current || !overlayLinkRef.current) return;

    const linkRect = overlayLinkRef.current.getBoundingClientRect();
    const cardHeight = overlayCardRef.current.offsetHeight;
    const viewportHeight = window.innerHeight;

    // Check if the card will overflow the viewport
    if (linkRect.bottom + cardHeight > viewportHeight) {
      setPosition('top');
    } else {
      setPosition('bottom');
    }
  };

  useEffect(() => {
    checkPosition();
  }, [isHovered]);

  return (
    <div
      ref={overlayLinkRef}
      className={cn(styles.overlayCardTrigger, 'd-flex')}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}>
      <span className={className}>&nbsp;{name}&nbsp;</span>
      {isHovered && (
        <div
          ref={overlayCardRef}
          className={cn(
            styles.overlayCardSection,
            'u-border overlayCard u-padding--small',
            position,
          )}>
          {children}
        </div>
      )}
    </div>
  );
};

OverlayCard.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};
