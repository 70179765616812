import { useEffect, useState } from 'react';

import cn from 'classnames';
import { ValidationFields } from 'constants/forms';
import { FormTypes } from 'constants/forms';
import { useTranslation } from 'react-i18next';

import Alert from 'components/Alert/Alert';
import { List } from 'components/List/List';
import { SectionedFormEdit } from 'components/SectionedForm/SectionedFormEdit/SectionedFormEdit';

export const CustomForms = ({
  copyFrom,
  forms = [],
  onSave = () => {},
  onDelete = () => {},
  onArchive = () => {},
}) => {
  const { t } = useTranslation();

  const [deleteFormConfirmation, setDeleteFormConfirmation] = useState(false);
  const [deleteFormParams, setDeleteFormParams] = useState({});

  const [form, setForm] = useState();

  useEffect(() => {
    if (copyFrom) copyFromForm(copyFrom);
  }, [copyFrom]);

  const handleSave = async data => {
    const updatedForm = await onSave(data);
    if (updatedForm) setForm(updatedForm);
  };

  const handleFormDelete = params => {
    setDeleteFormParams(false);
    setDeleteFormConfirmation(false);
    onDelete(params.form);
  };

  const createNewForm = () => {
    const form = {
      name: 'New Form',
      team_id: null,
      type: FormTypes.CUSTOM,
      fields: ValidationFields,
    };

    setForm(form);
  };

  useEffect(() => {
    if (form && form.id && !forms.some(f => f.id === form.id)) {
      setForm(null);
    }
  }, [forms, form]);

  const copyFromForm = form => {
    let newForm = { ...form };
    delete newForm.id;
    newForm.public = false;
    newForm.type = FormTypes.CUSTOM;
    newForm.name += ' (Copy)';
    newForm.fields = newForm?.fields.map(field => {
      let newField = { ...field };
      delete newField.id;
      if (newField.check_part !== 'VALIDATION') newField.check_part = 'CUSTOM';
      return newField;
    });

    setForm(newForm);
  };

  const handleFormRemove = () => {
    if (form?.id) {
      setDeleteFormConfirmation(true);
      setDeleteFormParams({ form });
    } else {
      setForm();
    }
  };

  const handleFormArchive = () => {
    if (form?.id) {
      onArchive(form);
      setForm(null);
    }
  };

  return (
    <div className={cn('u-flex-box', 'u-flex-align-start')}>
      <Alert
        show={deleteFormConfirmation}
        title="Are you sure you want to delete this form?"
        message="If you chose to proceed. No data will be lost."
        ok="Delete"
        onOk={handleFormDelete}
        params={deleteFormParams}
        cancel="Cancel"
        onCancel={() => setDeleteFormConfirmation(false)}
      />
      <List
        title={t('forms.custom.title')}
        subtitle={t('forms.custom.subtitle')}
        className="u-margin-right--large u-flex-align-self-normal"
        onNew={createNewForm}>
        <List.Category title={t('forms.custom.referenceForms')}>
          {forms.map(form => (
            <List.Item key={form.id} title={form.name} value={form} onClick={setForm} />
          ))}
        </List.Category>
      </List>
      {form && (
        <SectionedFormEdit
          form={form}
          onCopy={() => copyFromForm(form)}
          onSave={handleSave}
          onDelete={handleFormRemove}
          onArchive={handleFormArchive}
          onClose={() => setForm(null)}
        />
      )}
    </div>
  );
};
