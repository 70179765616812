export const Permissions = {
  CompanyWrite: 'company:write',
  CandidateRead: 'candidates:read',
  CandidateWrite: 'candidates:write',
  TeamsRead: 'teams:read',
  CandidateChecksResults: 'candidate_checks:results',
  BrandWrite: 'brand:write',
  FormsWrite: 'forms:write',
  CheckBundlesWrite: 'check_bundles:write',
  BillingWrite: 'billing:write',
  PartnersWrite: 'partners:write',
};

export const PermissionsHuman = {
  'company:write': 'Manage company details',
  'candidates:read': 'View candidates',
  'candidates:write': 'Create candidates',
  'teams:read': 'Access teams',
  'candidate_checks:results': 'View checks results',
  'brand:write': 'Manage brands',
  'forms:write': 'Manage questionnaires',
  'check_bundles:write': 'Manage check bundles',
  'billing:write': 'Manage billing details',
  'partners:write': 'Manage integrations',
};

export const AdminRoles = {
  Support: 'support',
  Sales: 'sales',
  SuperAdmin: 'su',
};

export const AdminRolesHuman = {
  support: 'Support',
  sales: 'Sales',
  su: 'Super Admin',
};
