import { useState } from 'react';

import cn from 'classnames';
import { Permissions } from 'constants/permissions';
import { RESULT_OPTIONS } from 'helpers/checks';
import { useAccessControl } from 'hooks/access-control';
import { useLocale } from 'hooks/locale';
import { Badge } from 'react-bootstrap';
import { Collapse } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Moment from 'react-moment';

import { Select } from 'components/FormComponents';
import { IconButton } from 'components/IconButton/IconButton';

import Alert from '../Alert/Alert';
import { KeyValueModal } from '../KeyValueModal/KeyValueModal';
import { MetadataDetailsModal } from '../MetadataDetailsModal/MetadataDetailsModal';
import styles from './CheckStatusRow.module.scss';

export const CheckStatusRow = ({
  candidate,
  checkType,
  company,
  check,
  permissions,
  useViewResult = true,
  useDownloadResult = true,
  setLoading = () => {},
  getCandidateCheckResult = () => {},
  handleCancelCheck = () => {},
  getSubCheckResult = () => {},
  handleCheckUpdate = () => {},
}) => {
  const [showMetadata, setShowMetadata] = useState(false);
  const [showExtraDetails, setShowExtraDetails] = useState(false);
  const [showSubExtraDetails, setSubShowExtraDetails] = useState(false);

  const [showCancelModal, setShowCancelModal] = useState(false);
  const [subChecksOpened, setSubChecksOpened] = useState(false);
  const { dateSlashFormat } = useLocale(company?.locale);

  const hasAccess = useAccessControl(permissions, { notPermissionPolicy: 'allowed' });

  const { t } = useTranslation();

  const onCancelCheck = checkId => {
    setShowCancelModal(false);
    handleCancelCheck(checkId);
  };

  const onChangeStatus = (checkId, status_value) => {
    if (check.user_review_status === status_value) return;

    handleCheckUpdate(checkId, { user_review_status: status_value });
  };

  const checkViewResult = (model, model_type) => {
    if (!model?.upload_id) return;

    const uri = `${process.env.REACT_APP_WEB_URL}/documents/${model.upload_id}/${model_type}/${model.id}`;
    window.open(uri, '_blank').focus();
  };

  const downloadResult = async () => {
    setLoading(true);
    await getCandidateCheckResult({
      check,
      candidate,
      isFile: checkType.result_type === RESULT_OPTIONS.File,
    }).unwrap();
    setLoading(false);
  };

  const downloadSubCheckResult = async subCheck => {
    setLoading(true);
    await getSubCheckResult({
      subCheck,
      check,
      candidate,
      isFile: checkType.result_type === RESULT_OPTIONS.File,
    }).unwrap();
    setLoading(false);
  };

  return (
    <div className={cn(styles.root, { [styles.rootDummy]: check.dummy })}>
      <Alert
        show={showCancelModal}
        title={t('check.backgroundChecks.cancelCheckTitle', { checkType: checkType.title })}
        ok={t('check.backgroundChecks.cancelCheckOk')}
        cancel={t('check.backgroundChecks.cancelCheckCancel')}
        onOk={() => {
          onCancelCheck(check.id);
        }}
        onCancel={() => {
          setShowCancelModal(false);
        }}
      />

      <div className={styles.checkRow}>
        <MetadataDetailsModal
          visible={showMetadata}
          onClose={() => setShowMetadata(false)}
          userAgent={check.user_agent}
          country={check.country}
        />
        {check.extra_data && (
          <KeyValueModal
            visible={showExtraDetails}
            onClose={() => setShowExtraDetails(false)}
            title="More Details"
            data={check.extra_data}
          />
        )}

        <div className={cn(styles.divider, 'u-divider')} />

        <div className={styles.logoColumn}>
          <img src={checkType.logo} className={styles.logo} />
        </div>

        <div className={cn(styles.titleColumn)}>
          <div className="title-4 u-text-ellipsis">{checkType.title}</div>
          <div className={`${styles.titleStatus} u-txt-status-${check.status}`}>
            {check.status}
            {(check.status == 'submitted' || check.status == 'in-progress') &&
              ` - ${check.in_progress_sub_status.replaceAll('_', ' ').capitalize()}`}
          </div>
        </div>

        <div className={cn(styles.createdOnColumn)}>
          <div className={styles.dateTitle}>{t('check.backgroundChecks.columns.createdOn')}</div>
          <div className={styles.dateValue}>
            <Moment format={dateSlashFormat}>{check.created_at}</Moment>
          </div>
        </div>

        <div className={cn(styles.completedOnColumn)}>
          <div className={styles.dateTitle}>{t('check.backgroundChecks.columns.completedOn')}</div>
          <div className={styles.dateValue}>
            {check.completed_at ? (
              <Moment format={dateSlashFormat}>{check.completed_at}</Moment>
            ) : (
              '-'
            )}
          </div>
        </div>

        <div className={cn(styles.expiredOnColumn)}>
          {check.expiration_date && (
            <>
              <div className={styles.dateTitle}>{t('check.backgroundChecks.columns.expireOn')}</div>
              <div className={styles.dateValue}>
                <Moment format={dateSlashFormat} parse="YYYY-MM-DD">
                  {check.expiration_date}
                </Moment>
              </div>
            </>
          )}
        </div>

        <div className={cn(styles.divider2, 'u-divider')} />

        <div className={cn(styles.statusColumn)}>
          {check.completed_at &&
            !check.canceled &&
            checkType.has_result_flag &&
            check.result_flag && (
              <Badge className={`u-status-completed-with-result`}>
                {checkType.true_flag_label}
              </Badge>
            )}
          {check.completed_at &&
            !check.canceled &&
            checkType.has_result_flag &&
            !check.result_flag && (
              <Badge className={`u-status-completed`}>{checkType.false_flag_label}</Badge>
            )}
        </div>

        <div className={cn(styles.reviewColumn)}>
          {hasAccess(Permissions.CandidateWrite) && (
            <Select
              className="u-margin-right"
              inputProps={{ onChange: e => onChangeStatus(check.id, e.target.value) }}
              value={check.user_review_status}
              placeholder="Add Review">
              <Select.Item value="approved">Approved</Select.Item>
              <Select.Item value="not_approved">Not Approved</Select.Item>
              <Select.Item value="needs_review">Needs Review</Select.Item>
            </Select>
          )}
        </div>

        <div
          className={cn(
            styles.actionsColumn,
            'd-flex',
            'justify-content-end',
            'align-items-center',
          )}>
          {check.extra_data && !check.dummy && (
            <IconButton
              className="u-margin-right--small"
              icon="file-text"
              tip="More details"
              onClick={() => setShowExtraDetails(true)}
            />
          )}
          {check.completed_at && !check.canceled && check.user_agent && !check.dummy && (
            <IconButton
              className="u-margin-right--small"
              icon="monitor"
              tip="Metadata details"
              onClick={() => setShowMetadata(true)}
            />
          )}
          {check.completed_at &&
            !check.canceled &&
            hasAccess(Permissions.CandidateChecksResults) &&
            !check.dummy &&
            useViewResult && (
              <IconButton
                className="u-margin-right--small"
                icon="eye"
                tip="View result"
                onClick={() => {
                  checkViewResult(check, 'candidate_check');
                }}
              />
            )}
          {check.completed_at &&
            !check.canceled &&
            hasAccess(Permissions.CandidateChecksResults) &&
            useDownloadResult &&
            !check.dummy && (
              <IconButton
                className="u-margin-right--small"
                icon="download"
                tip="Download result"
                onClick={downloadResult}
              />
            )}
          {!candidate.completed_at &&
            !check.completed_at &&
            !check.canceled &&
            hasAccess(Permissions.CandidateWrite) &&
            !check.dummy && (
              <IconButton
                className="u-margin-right--small"
                icon="x"
                tip="Cancel Ongoing Check"
                onClick={() => setShowCancelModal(prevState => !prevState)}
              />
            )}
          {check.sub_checks?.length > 0 && !check.dummy && (
            <div>
              <IconButton
                className={cn({ [styles.iconButtonRotated]: subChecksOpened })}
                icon="chevron-down"
                onClick={() => setSubChecksOpened(curr => !curr)}
              />
              <span className={styles.subCheckExpandIcon}>{check.sub_checks.length}</span>
            </div>
          )}
        </div>
      </div>

      {check.sub_checks?.length > 0 && (
        <Collapse in={subChecksOpened}>
          <div>
            {check.sub_checks.map(subCheck => (
              <div className={cn(styles.subCheckRow, 'u-divider-top-border', 'u-width-100')}>
                {subCheck.extra_details && (
                  <KeyValueModal
                    visible={showSubExtraDetails}
                    onClose={() => setSubShowExtraDetails(false)}
                    title="More Details"
                    data={subCheck.extra_details}
                  />
                )}

                <div className={cn(styles.titleColumn, 'd-flex', 'align-items-center')}>
                  <div>
                    <div className={styles.dateTitle}>title</div>
                    <div className="title-5">{subCheck.title}</div>
                    <div>
                      <span className={cn(styles.titleStatus, `u-txt-status-${subCheck.status}`)}>
                        {subCheck.status}
                      </span>
                      {subCheck.completed_at && !subCheck.canceled && checkType.has_result_flag && (
                        <>
                          <span> • </span>
                          <span
                            className={cn(styles.titleStatus, {
                              ['u-txt-completed-with-result']: subCheck.result_flag,
                              ['u-txt-status-completed']: !subCheck.result_flag,
                            })}>
                            {subCheck.result_flag
                              ? checkType.true_flag_label
                              : checkType.false_flag_label}
                          </span>
                        </>
                      )}
                    </div>
                  </div>
                </div>

                <div className={cn(styles.divider, 'u-divider')} />

                <div className={cn(styles.createdOnColumn)}>
                  <div className={styles.dateTitle}>
                    {t('check.backgroundChecks.subChecks.columns.createdOn')}
                  </div>
                  <div className={styles.dateValue}>
                    <Moment format={dateSlashFormat}>{subCheck.created_at}</Moment>
                  </div>
                </div>

                <div className={cn(styles.completedOnColumn)}>
                  <div className={styles.dateTitle}>
                    {t('check.backgroundChecks.subChecks.columns.completedOn')}
                  </div>
                  <div className={styles.dateValue}>
                    {subCheck.completed_at ? (
                      <Moment format={dateSlashFormat}>{subCheck.completed_at}</Moment>
                    ) : (
                      '-'
                    )}
                  </div>
                </div>

                <div className={cn(styles.expiredOnColumn)}>
                  {subCheck.expiration_date && (
                    <>
                      <div className={styles.dateTitle}>
                        {t('check.backgroundChecks.subChecks.columns.expireOn')}
                      </div>
                      <div className={styles.dateValue}>
                        <Moment format={dateSlashFormat} parse="YYYY-MM-DD">
                          {subCheck.expiration_date}
                        </Moment>
                      </div>
                    </>
                  )}
                </div>

                <div className={cn(styles.actionsColumn, 'd-flex', 'justify-content-end')}>
                  {subCheck.extra_details && (
                    <IconButton
                      className="u-margin-right--small"
                      icon="file-text"
                      tip={t('check.backgroundChecks.subChecks.actions.moreDetails')}
                      onClick={() => setSubShowExtraDetails(true)}
                    />
                  )}
                  {subCheck.completed_at &&
                    !subCheck.canceled &&
                    hasAccess(Permissions.CandidateChecksResults) &&
                    useViewResult && (
                      <IconButton
                        className="u-margin-right--small"
                        icon="eye"
                        tip={t('check.backgroundChecks.subChecks.actions.viewResult')}
                        onClick={() => {
                          checkViewResult(subCheck, 'sub_check');
                        }}
                      />
                    )}
                  {!subCheck.canceled &&
                    hasAccess(Permissions.CandidateChecksResults) &&
                    useDownloadResult && (
                      <IconButton
                        className={styles.iconButton}
                        icon="download"
                        tip={t('check.backgroundChecks.subChecks.actions.downloadResult')}
                        disabled={!subCheck.completed_at}
                        onClick={() => downloadSubCheckResult(subCheck)}
                      />
                    )}
                </div>
              </div>
            ))}
          </div>
        </Collapse>
      )}
    </div>
  );
};
