import { useEffect } from 'react';

import { useDocumentTitle } from 'hooks/document-title';
import ReactGA from 'react-ga';

import { Error } from './Error';

const Error401 = () => {
  useDocumentTitle('Unauthorized | Checkmate');

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  return <Error message="Unauthorized Access">401</Error>;
};

export default Error401;
