import React, { useEffect, useMemo, useState } from 'react';

import cn from 'classnames';
import { useNavigate } from 'react-router-dom';

import { AddHistoryModal } from './components/AddHistoryModal/AddHistoryModal';
import { Button } from 'components/FormComponents';
import { HistoryRow } from 'components/HistoryRow/HistoryRow';

import { useGetCandidateHistoryQuery } from 'api/candidates-api';
import { useGetCompanyUsersQuery } from 'api/user-api';

import styles from './History.module.scss';

export const History = ({
  company,
  candidate,
  referees,
  user,
  companyChecks,
  targetNewRecord,
  createRecord = () => {},
  onCandidateRequest = () => {},
  onRefereeRequest = () => {},
  setLoading = () => {},
}) => {
  const [entries, setEntries] = useState([]);
  const [addNoteModal, setAddNoteModal] = useState(false);

  const { data: companyUsers } = useGetCompanyUsersQuery(company.id);

  // Filter out the current user from the company users so they can't tag themselves in history record
  const filteredCompanyUsers = useMemo(
    () => companyUsers?.filter(companyUser => companyUser.id !== user.id),
    [companyUsers, user.id],
  );

  const { data: entriesData, isLoading: isLoadingRecords } = useGetCandidateHistoryQuery(
    candidate.id,
  );

  const navigate = useNavigate();

  useEffect(() => {
    if (entriesData) setEntries(entriesData);
  }, [entriesData]);
  useEffect(() => {
    setLoading(isLoadingRecords);
  }, [isLoadingRecords]);

  useEffect(() => {
    if (targetNewRecord) {
      setAddNoteModal(true);
    }
  }, [targetNewRecord]);

  const handleAddRecord = async data => {
    setLoading(true);
    const { data: result } = await createRecord({ id: candidate.id, data });
    setEntries([result, ...entries]);
    setLoading(false);
  };

  const handleCloseModal = () => {
    setAddNoteModal(false);
    // Navigate to the base history path without the 'new' target
    navigate(`/check/${candidate.id}/history`);
  };

  return (
    <div>
      <AddHistoryModal
        visible={addNoteModal}
        onClose={handleCloseModal}
        company={company}
        candidate={candidate}
        companyChecks={companyChecks}
        user={user}
        companyUsers={filteredCompanyUsers}
        onSave={handleAddRecord}
      />
      <div className={cn('d-flex', 'u-width-100', 'justify-content-end', 'u-margin-bottom--large')}>
        <Button onClick={() => setAddNoteModal(true)}>Add History Record</Button>
      </div>
      {entries.map(entry => {
        return (
          <HistoryRow
            key={entry?.id}
            entry={entry}
            company={company}
            companyChecks={companyChecks}
            className={styles.row}
            candidate={candidate}
            referees={referees}
            companyUsers={companyUsers}
            onCandidateRequest={onCandidateRequest}
            onRefereeRequest={onRefereeRequest}
          />
        );
      })}
    </div>
  );
};
