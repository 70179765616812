export const CheckHistoryTypes = {
  CompletedCheck: 1,
  CompletedReferee: 2,
  CompletedRefereeIpError: 3,
  CompletedCandidate: 4,
  UpdatedEmail: 5,
  UpdatedContactNumber: 6,
  UpdatedTeamName: 7,
  UpdatedJobRole: 8,
  RequestSentCandidate: 9,
  RequestOverdueCandidate: 10,
  RequestSentReferee: 11,
  RequestOverdueReferee: 12,
  NewReferee: 13,
  NewCheck: 14,
  BackgroundCheckSubmitted: 15,
  BackgroundCheckProcessing: 16,
  BackgroundCheckCompleted: 17,
  BackgroundCheckRejected: 18,
  BackgroundCheckUpdated: 19,
  CandidateEmailFailed: 20,
  RefereeEmailFailed: 21,
  CheckCanceled: 22,
  CandidateEmailOpened: 23,
  RefereeEmailOpened: 24,
  CandidateEmailClicked: 25,
  RefereeEmailClicked: 26,
  CustomNote: 27,
  CustomInfo: 28,
  CustomSuccess: 29,
  CustomWarning: 30,
  CustomError: 31,
  CheckDeclined: 32,
  CheckAddNotes: 33,
  CheckUpdateStatus: 34,
  RefereeDeleted: 35,
  SubCheckCompleted: 36,
  CandidateSMSOpened: 37,
  RefereeSMSOpened: 38,
  RefereeReplacement: 39,
  ReminderCandidate: 40,
  ReminderReferee: 41,
  UpdatedCandidateTeam: 42,
  CheckResult: 43,
  TransferCandidateAssignment: 44,
  NewCandidateAssignment: 45,
  CheckUndoCompletion: 46,
};
