Object.assign(String.prototype, {
  // Format text. See https://checkmate-technology.atlassian.net/wiki/spaces/PROD/pages/30670851/Form+builder#Format
  format(format) {
    let formattedValue = this;

    for (let i = 0; i < this.length; i++) {
      const char = formattedValue[i];
      const formatChar = format[i];
      switch (formatChar) {
        case 'd':
          if (isNaN(char)) {
            formattedValue = formattedValue.substring(0, i) + formattedValue.substring(i + 1);
          }
          break;
        case 'x':
          break;
        default:
          if (char !== formatChar)
            formattedValue =
              formattedValue.substring(0, i) + formatChar + formattedValue.substring(i);
          break;
      }
    }

    if (formattedValue.length > format.length) return formattedValue.substring(0, format.length);
    else return formattedValue;
  },

  capitalize() {
    if (!this || this.length === 0) return '';
    return this.charAt(0).toUpperCase() + this.slice(1).toLowerCase();
  },
});
