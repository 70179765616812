import { useEffect, useState } from 'react';

import cn from 'classnames';
import { Partners } from 'constants/partners';
import { useQueryParams } from 'hooks/query-params';
import { Tab, Tabs } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import { HistoryRow } from 'components/HistoryRow/HistoryRow';
import { Loading } from 'components/Loading/Loading';

import {
  useAddCandidateChecksMutation,
  useCancelCandidateCheckMutation,
  useGetCandidateHistoryQuery,
  useGetCandidateQuery,
  useGetCompanyChecksQuery,
  useGetCompanyQuery,
  useGetFormsQuery,
  useGetPartnersConfigQuery,
  useLazyGetCandidateCheckResultQuery,
  useLazyGetSubCheckResultQuery,
  useUpdateCandidateCheckMutation,
} from 'api/partner-v1-api';

import { AssessmentChecksDetails } from '../component/AssessmentChecksDetails/AssessmentChecksDetails';
import styles from './AssessmentProgress.module.scss';

export const AssessmentProgress = () => {
  const { partner, token } = useParams();

  const [loading, setLoading] = useState(false);
  const [applicationId, setApplicationId] = useState();
  const [candidate, setCandidate] = useState({});

  const [bullhornCandidateId, partnerApplicationId, jobAdderApplicationId] = useQueryParams([
    'EntityID',
    'partner_application_id',
    'applicationId',
  ]);

  const {
    data: candidateData,
    isFetching: loadingCandidate,
    refetch,
  } = useGetCandidateQuery(
    { token, applicationId },
    { skip: !applicationId, refetchOnMountOrArgChange: true },
  );
  const { data: entries, isLoading: loadingHistory } = useGetCandidateHistoryQuery(
    { token, applicationId },
    { skip: !applicationId },
  );
  const { data: forms, isLoading: loadingForms } = useGetFormsQuery(token, {
    skip: !applicationId,
  });
  const { data: companyChecks, isLoading: loadingCompanyChecks } = useGetCompanyChecksQuery(token, {
    skip: !applicationId,
  });
  const { data: company, isLoading: loadingCopmpany } = useGetCompanyQuery(token, {
    skip: !applicationId,
  });

  const { data: partnersConfig, isLoading: loadingPartnersConfig } = useGetPartnersConfigQuery(
    token,
    {
      skip: !applicationId,
    },
  );

  const [getCandidateCheckResult] = useLazyGetCandidateCheckResultQuery();
  const [addCandidateChecks] = useAddCandidateChecksMutation();
  const [updateCandidateCheck] = useUpdateCandidateCheckMutation();
  const [cancelCandidateCheck] = useCancelCandidateCheckMutation();
  const [getSubCheckResult] = useLazyGetSubCheckResultQuery();

  // Get applicationId based on partner
  useEffect(() => {
    switch (parseInt(partner)) {
      case Partners.Bullhorn:
        setApplicationId(bullhornCandidateId);
        break;
      case Partners.JobAdder:
        setApplicationId(jobAdderApplicationId);
        break;
      default:
        setApplicationId(partnerApplicationId);
    }
  }, [partner, bullhornCandidateId, partnerApplicationId, jobAdderApplicationId]);

  useEffect(() => {
    setCandidate(candidateData);
  }, [candidateData]);

  if (
    !candidate ||
    loading ||
    loadingCopmpany ||
    loadingForms ||
    loadingCompanyChecks ||
    loadingCandidate ||
    loadingHistory ||
    loadingPartnersConfig
  )
    return <Loading active={true} />;

  return (
    <div className={styles.root}>
      <Tabs id="accountTabs" defaultActiveKey="checks" className="tabs" mountOnEnter={true}>
        <Tab eventKey="history" title="History">
          {!candidate?.completed_at && <div className={cn(styles.row, styles.rowPending)}></div>}
          {entries?.map(entry => (
            <HistoryRow
              key={entry.id}
              entry={entry}
              company={company}
              companyChecks={companyChecks}
              className={styles.row}
              candidate={candidate}
              disableLinks
              referees={entry.referee ? [entry.referee] : []}
            />
          ))}
        </Tab>
        <Tab eventKey="checks" title="Background Checks" className={styles.results}>
          <AssessmentChecksDetails
            candidate={candidate}
            company={company}
            partnersConfig={partnersConfig}
            token={token}
            companyChecks={companyChecks}
            forms={forms}
            reload={refetch}
            setLoading={setLoading}
            setCandidate={setCandidate}
            updateCandidateCheck={updateCandidateCheck}
            getSubCheckResult={getSubCheckResult}
            getCandidateCheckResult={getCandidateCheckResult}
            addCandidateChecks={addCandidateChecks}
            cancelCandidateCheck={cancelCandidateCheck}
          />
        </Tab>
      </Tabs>
    </div>
  );
};
