import { useEffect, useMemo, useRef } from 'react';

import autosize from 'autosize';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { Mention, MentionsInput } from 'react-mentions';

import { UserInfo } from 'components/Data/UserInfo';

import styles from './MentionInput.module.scss';

export const MentionInput = ({
  name,
  validators = {},
  placeholder,
  dynamicHeight,
  companyUsers,
  className,
  control,
  onSelect = () => {},
}) => {
  const mentionsInputRef = useRef(null);
  const containerRef = useRef(null);

  const formattedUsers = useMemo(
    () =>
      companyUsers.map(user => ({
        id: user.id,
        display: `${user.first_name} ${user.last_name}`,
        email: user.email,
        firstName: user.first_name,
        lastName: user.last_name,
      })),
    [companyUsers],
  );

  useEffect(() => {
    if (dynamicHeight && mentionsInputRef.current) {
      autosize(mentionsInputRef.current);
    }
  }, [dynamicHeight]);

  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      rules={validators}
      id="suggestionPortal"
      ref={containerRef}
      render={({ field }) => (
        <MentionsInput
          {...field}
          placeholder={placeholder}
          ref={mentionsInputRef}
          className={cn(styles.root, { [className]: className }, 'mention-input')}
          suggestionsPortalHost={containerRef.current}
          allowSuggestionsAboveCursor={true}
          customSuggestionsContainer={children => (
            <div className="u-border overlayCard u-padding--small">{children}</div>
          )}>
          <Mention
            trigger="@"
            data={formattedUsers}
            appendSpaceOnAdd={true}
            onAdd={id => {
              const selectedUser = formattedUsers.find(user => user.id === id);
              onSelect?.(selectedUser);
            }}
            renderSuggestion={suggestion => (
              <UserInfo
                key={suggestion.id}
                display={suggestion.display}
                email={suggestion.email}
                firstName={suggestion.firstName}
                lastName={suggestion.lastName}
              />
            )}
          />
        </MentionsInput>
      )}
    />
  );
};

MentionInput.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  validators: PropTypes.object,
  inputProps: PropTypes.object,
  dynamicHeight: PropTypes.bool,
  className: PropTypes.string,
  control: PropTypes.object.isRequired,
  onSelect: PropTypes.func,
};
