import { useEffect, useRef, useState } from 'react';

import cn from 'classnames';
import { MESSAGE_STATE_ERROR, MESSAGE_STATE_SUCCESS } from 'constants/message-app-state-contants';
import { Permissions } from 'constants/permissions';
import { useAccessControl } from 'hooks/access-control';
import { useDocumentTitle } from 'hooks/document-title';
import AppPage from 'layouts/AppPage/AppPage';
import ReactGA from 'react-ga';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { BulkActionsBar } from './components/BulkActionsBar/BulkActionsBar';
import CheckTableHeader from './components/CheckTableHeader/CheckTableHeader';
import { CheckTableRow } from './components/CheckTableRow/CheckTableRow';
import { DashboardFilters } from './components/DashboardFilters/DashboardFilters';
import { Button, Checkbox } from 'components/FormComponents';

import { messagePopUp } from 'api/app-slice';
import {
  useBulkArchiveMutation,
  useBulkAssignCandidateMutation,
  useBulkDeletionMutation,
  useBulkExportMutation,
  useBulkRemindersMutation,
  useBulkTagDeletionMutation,
  useBulkTagMutation,
} from 'api/candidates-api';
import { deleteCandidate, getCandidates, updateCandidate } from 'api/candidates-slice';
import { selectCompanyChecks } from 'api/company-checks-slice';
import { selectCompany } from 'api/company-slice';
import { getTags, selectTags } from 'api/tags-slice';
import { getTeams, selectTeams } from 'api/teams-slice';
import { useGetCompanyUsersQuery } from 'api/user-api';
import { selectPermissions } from 'api/user-permissions-slice';
import { selectUser } from 'api/user-slice';

import Alert from '../../components/Alert/Alert';
import { Loader } from '../../components/Loader/Loader';
import styles from './Dashboard.module.scss';

const Dashboard = () => {
  useDocumentTitle('Dashboard');

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const tags = useSelector(selectTags);
  const user = useSelector(selectUser);
  const permissions = useSelector(selectPermissions);
  const companyChecks = useSelector(selectCompanyChecks);
  const company = useSelector(selectCompany);
  const teams = useSelector(selectTeams);

  const { data: companyUsers } = useGetCompanyUsersQuery(user?.id, {
    refetchOnMountOrArgChange: true,
  });

  const [bulkReminders] = useBulkRemindersMutation();
  const [bulkTagDeletion] = useBulkTagDeletionMutation();
  const [bulkTag] = useBulkTagMutation();
  const [bulkArchive] = useBulkArchiveMutation();
  const [bulkdeletion] = useBulkDeletionMutation();
  const [bulkExport] = useBulkExportMutation();
  const [bulkAssignCandidate] = useBulkAssignCandidateMutation();

  const [loading, setLoading] = useState(true);

  const [candidates, setCandidates] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    records_per_page: 15,
    total_pages: 1,
    total_records: 0,
  });
  const [sorting, setSorting] = useState({
    status: 1,
    name: 1,
    job_role: 1,
    team_name: -1,
    created_at: 1,
    user_name: 1,
  });

  const [bulkSelect, setBulkSelect] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [useAllForBulk, setUseAllForBulk] = useState(false);

  const [alertDelete, setAlertDelete] = useState(false);
  const [alertParams, setAlertParams] = useState({});

  const filtersRef = useRef(null);

  const hasAccess = useAccessControl(permissions);

  // Initialization
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
    dispatch(getTags());
    dispatch(getTeams());
  }, []);

  const handleBulkReminders = async () => {
    try {
      setLoading(true);
      await bulkReminders({ ids: selectedIds, useAll: useAllForBulk });
      dispatch(messagePopUp({ text: 'Reminders sent.', state: MESSAGE_STATE_SUCCESS, hide: true }));
    } catch (error) {
      dispatch(
        messagePopUp({ text: 'Something went wrong', state: MESSAGE_STATE_ERROR, hide: true }),
      );
    } finally {
      setLoading(false);
    }
  };

  const handleDownloadCSV = async () => {
    setLoading(true);
    try {
      await bulkExport({ ids: selectedIds, useAll: useAllForBulk });
    } catch (error) {
      dispatch(
        messagePopUp({ text: 'Something went wrong', state: MESSAGE_STATE_ERROR, hide: true }),
      );
    } finally {
      setLoading(false);
    }
  };

  const handleBulkDeletion = async () => {
    setLoading(true);
    try {
      const { data: response } = await bulkdeletion({ ids: selectedIds, useAll: useAllForBulk });
      dispatch(
        messagePopUp({
          text: `${response.result} candidates removed`,
          state: MESSAGE_STATE_SUCCESS,
          hide: true,
        }),
      );
      filtersRef.current.refresh();
    } catch (error) {
      dispatch(
        messagePopUp({ text: 'Something went wrong', state: MESSAGE_STATE_ERROR, hide: true }),
      );
      setLoading(false);
    }
  };

  const handleBulkArchive = async () => {
    setLoading(true);
    try {
      const { data: response } = await bulkArchive({ ids: selectedIds, useAll: useAllForBulk });
      dispatch(
        messagePopUp({
          text: `${response.result} candidates archived`,
          state: MESSAGE_STATE_SUCCESS,
          hide: true,
        }),
      );
      filtersRef.current.refresh();
    } catch (error) {
      dispatch(
        messagePopUp({ text: 'Something went wrong', state: MESSAGE_STATE_ERROR, hide: true }),
      );
      setLoading(false);
    }
  };

  const handleBulkTag = async tagId => {
    setLoading(true);
    try {
      const { data: response } = await bulkTag({
        ids: selectedIds,
        tag_id: tagId,
        use_all: useAllForBulk,
      });
      dispatch(
        messagePopUp({
          text: `${response.result} candidates tagged`,
          state: MESSAGE_STATE_SUCCESS,
          hide: true,
        }),
      );
    } catch (error) {
      dispatch(
        messagePopUp({ text: 'Something went wrong', state: MESSAGE_STATE_ERROR, hide: true }),
      );
    } finally {
      setLoading(false);
    }
  };

  const handleBulkAssignCandidate = async userId => {
    setLoading(true);
    try {
      const { data: response } = await bulkAssignCandidate({
        ids: selectedIds,
        user_id: userId.user_id,
        use_all: useAllForBulk,
      });
      dispatch(
        messagePopUp({
          text: `Candidates re-assigned successfully`,
          state: MESSAGE_STATE_SUCCESS,
          hide: true,
        }),
      );
      filtersRef.current.refresh();
    } catch (error) {
      dispatch(
        messagePopUp({ text: 'Something went wrong', state: MESSAGE_STATE_ERROR, hide: true }),
      );
    } finally {
      setLoading(false);
    }
  };

  const handleBulkTagDeletion = async () => {
    setLoading(true);
    try {
      const { data: response } = await bulkTagDeletion({ ids: selectedIds, useAll: useAllForBulk });
      dispatch(
        messagePopUp({
          text: `${response.result} tags removed`,
          state: MESSAGE_STATE_SUCCESS,
          hide: true,
        }),
      );
    } catch (error) {
      dispatch(
        messagePopUp({ text: 'Something went wrong', state: MESSAGE_STATE_ERROR, hide: true }),
      );
    } finally {
      setLoading(false);
    }
  };

  const onCheckDelete = async params => {
    setAlertDelete(false);
    setLoading(true);
    await dispatch(deleteCandidate(params.candidate_id));
    filtersRef.current.refresh();
    dispatch(messagePopUp({ text: 'Candidate deleted', state: MESSAGE_STATE_SUCCESS, hide: true }));
    setLoading(false);
  };

  const handleBulkToggle = selected => {
    if (!selected) setSelectedIds([]);
    setBulkSelect(selected);
  };

  const sortCandidatesTable = column => {
    const newSortValue = sorting[column] == 0 ? 1 : sorting[column] * -1;
    const newSort = Object.keys(sorting).reduce((res, col) => {
      res[col] = col == column ? newSortValue : 0;
      return res;
    }, {});
    setSorting(newSort);
    setCandidates(candidates => [...candidates].sort(candidateSort(column, newSortValue)));
  };

  const candidateSort = (column, asc) => {
    if (column === 'created_at') {
      return (a, b) => {
        let a_split = a.created_at.split('/');
        let a_value = a_split[2] + a_split[1] + a_split[0];
        let b_split = b.created_at.split('/');
        let b_value = b_split[2] + b_split[1] + b_split[0];
        if (a_value < b_value) return -1 * asc;
        else if (a_value > b_value) return 1 * asc;
        else return 0;
      };
    }

    return (a, b) => {
      if (a[column] < b[column]) return -1 * asc;
      else if (a[column] > b[column]) return 1 * asc;
      else return 0;
    };
  };

  const fetchCandidates = async (params = {}, replace = true) => {
    params = { ...params, ...filtersRef.current.getFiltersParams() };
    const { payload } = await dispatch(getCandidates({ params }));
    setPagination(payload.result.pagination);
    if (replace) setCandidates(payload.result.candidates);
    else setCandidates(curr => [...curr, ...payload.result.candidates]);
  };

  const onCheckSelectionToggle = (id, selected) => {
    setSelectedIds(curr => (selected ? [...curr, id] : curr.filter(value => value !== id)));
  };

  const onCheckDeleteConfirmation = candidate_id => {
    setAlertDelete(true);
    setAlertParams({ candidate_id });
  };

  const onArchivedStatusChange = async candidate => {
    setLoading(true);
    await dispatch(
      updateCandidate({ id: candidate.id, params: { archived: !candidate.archived } }),
    );
    filtersRef.current.refresh();
    setLoading(false);
  };

  return (
    <AppPage loading={loading}>
      <BulkActionsBar
        active={selectedIds.length > 0}
        selectedIds={selectedIds}
        onUseAllChange={setUseAllForBulk}
        tags={tags}
        users={companyUsers}
        reminderAction={handleBulkReminders}
        downloadCSV={handleDownloadCSV}
        bulkDeletion={handleBulkDeletion}
        bulkArchive={handleBulkArchive}
        bulkTag={handleBulkTag}
        bulkTagDeletion={handleBulkTagDeletion}
        bulkAssignCandidate={handleBulkAssignCandidate}
      />

      <Alert
        show={alertDelete}
        title="You're about to delete this Check permanently"
        message="Are you sure you want to continue?"
        ok="Yes"
        onOk={onCheckDelete}
        cancel="Cancel"
        onCancel={() => setAlertDelete(false)}
        params={alertParams}
      />
      <div className={styles.root}>
        <div>
          <AppPage.Header
            title="Dashboard"
            subtitle="Manage your candidates and pre-employment screening checks."
          />
          <div className={styles.filters}>
            <DashboardFilters
              ref={filtersRef}
              userId={user.id}
              setLoading={setLoading}
              getCandidates={params => dispatch(getCandidates({ params, refresh: true }))}
              tags={tags}
              teams={teams}
              onCandidatesChange={setCandidates}
              onPaginationChange={setPagination}
            />
            {hasAccess(Permissions.CandidateWrite) && (
              <Button icon="user-plus" onClick={() => navigate('/new_check')}>
                New Check
              </Button>
            )}
          </div>
        </div>
        <div className={styles.results}>
          <div className={styles.columnHeader}>
            <div className={styles.columnTitles}>
              <Checkbox
                styles={{ marginBottom: '10px', padding: '0px 0px 10px 0px' }}
                onClick={handleBulkToggle}
              />
              <CheckTableHeader
                title="Status"
                name="status"
                className={styles.column}
                order={sorting.status}
                sortFunction={sortCandidatesTable}
              />
              <CheckTableHeader
                title="Candidate Name"
                name="name"
                className={styles.column}
                order={sorting.name}
                sortFunction={sortCandidatesTable}
              />
              <CheckTableHeader
                title="Department"
                name="team_name"
                className={cn(styles.column, styles.smHide)}
                order={sorting.team_name}
                sortFunction={sortCandidatesTable}
              />
              <CheckTableHeader
                title="Role"
                name="job_role"
                className={cn(styles.column, styles.smHide)}
                order={sorting.job_role}
                sortFunction={sortCandidatesTable}
              />
              <CheckTableHeader
                title="Assigned To"
                name="user_name"
                className={cn(styles.column, styles.smHide)}
                order={sorting.user_name}
                sortFunction={sortCandidatesTable}
              />
              <CheckTableHeader
                title="Created on"
                name="created_at"
                order={sorting.created_at}
                sortFunction={sortCandidatesTable}
              />
            </div>
          </div>
          <div className={styles.checksTable}>
            <InfiniteScroll
              dataLength={candidates.length}
              next={() => fetchCandidates({ page: pagination.page + 1 }, false)}
              hasMore={pagination.page !== pagination.total_pages}
              loader={<Loader />}>
              {candidates.map((candidate, index) => (
                <CheckTableRow
                  key={candidate.id}
                  company={company}
                  companyChecks={companyChecks}
                  bulkSelect={bulkSelect}
                  onToggledSelection={onCheckSelectionToggle}
                  candidate={candidate}
                  onDelete={onCheckDeleteConfirmation}
                  onArchiveChange={onArchivedStatusChange}
                />
              ))}
            </InfiniteScroll>
          </div>
          {candidates.length === 0 && (
            <div className={styles.emptyResults}>
              <p>👋 Hi, get started by adding a</p>
              <Button icon="user-plus" onClick={() => navigate('/new_check')}>
                New Check
              </Button>
            </div>
          )}
        </div>
      </div>
    </AppPage>
  );
};

export default Dashboard;
