import { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Button } from 'components/FormComponents';

import { messagePopOut, selectApp } from 'api/app-slice';
import { selectAuthentication } from 'api/authentication-slice';

import { Loading } from '../../components/Loading/Loading';
import AppMessage from '../AppPage/AppMessage/AppMessage';
import { AdminNavbar } from './AdminNavbar/AdminNavbar';
import styles from './AdminPage.module.scss';

const AdminPage = ({
  title,
  loading,
  button,
  buttonText = 'Save',
  children,
  onClick = () => {},
  allowedRoles = [],
}) => {
  const dispatch = useDispatch();

  const authentication = useSelector(selectAuthentication);
  const app = useSelector(selectApp);

  const [authenticating, setAuthenticating] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(messagePopOut());
  }, []);

  useEffect(() => {
    if (!authentication.checked) return;
    else if (!authentication.logged) navigate(`/sign_in?redirect_uri=${window.location.pathname}`);
    else if (!authentication.admin) navigate('/dashboard');
    else if (!allowedRoles.includes(authentication.admin_role)) navigate('/admin/companies');

    setAuthenticating(false);
  }, [authentication.checked]);

  return (
    <div className={styles.root}>
      <Loading active={loading || authenticating}></Loading>
      <AdminNavbar />
      <AppMessage {...app.message} />
      <div>
        <div className={styles.header}>
          <h1 className={styles.title}>{title}</h1>
          {button && <Button onClick={onClick}>{buttonText}</Button>}
        </div>
        {children}
      </div>
    </div>
  );
};

export default AdminPage;
