import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import FeatherIcon from 'feather-icons-react';
import PropTypes from 'prop-types';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import styles from './IconComponent.module.scss';

export const IconComponent = ({
  icon,
  className,
  tip,
  placement = 'top',
  prefix = 'fi',
  small = false,
  // Available types:
  // - 'default': Standard checkmate Gradient style
  // - 'neutral-light': Light grey color
  // - 'neutral-dark': Dark grey color
  // - 'success': Green success indicator
  // - 'error': Red error/danger indicator
  // - 'warning': Yellow warning indicator
  // - 'info': Blue information indicator
  type = 'default',
  rounded = true,
  border = true,
  noBackground = false,
}) => (
  <OverlayTrigger
    trigger={tip === undefined ? 'none' : ['hover', 'focus']}
    placement={placement}
    overlay={<Tooltip>{tip}</Tooltip>}>
    <div
      className={cn(styles.root, styles[type], {
        [className]: className,
        [styles.small]: small,
        [styles['no-rounded']]: !rounded,
        [styles['with-border']]: border,
        [styles['no-background']]: noBackground,
      })}>
      {prefix === 'fa' ? (
        <FontAwesomeIcon icon={icon} />
      ) : (
        <FeatherIcon size={17} icon={icon} className={styles.icon} />
      )}
    </div>
  </OverlayTrigger>
);

IconComponent.propTypes = {
  icon: PropTypes.string,
  className: PropTypes.string,
};
