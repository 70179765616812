import { useState } from 'react';

import cn from 'classnames';
import { useApiUploadActions } from 'hooks/upload-actions';

import { AddRefereeCard } from './components/AddRefereeCard/AddRefereeCard';
import { CombinedReferees } from './components/CombinedReferees/CombinedReferees';
import { NewRefereeModal } from './components/NewRefereeModal/NewRefereeModal';
import { Referee } from './components/Referee/Referee';
import { RefereeCard } from './components/RefereeCard/RefereeCard';
import Alert from 'components/Alert/Alert';
import { Form } from 'components/Form/Form/Form';

import { useLazyGetFormsByIdQuery } from 'api/forms-api';

import styles from './Reference.module.scss';

const COMBINED_ID = 0;

export const Reference = ({
  candidate,
  check,
  referees = [],
  company,
  brand,
  messagePopUp = () => {},
  addReferee = () => {},
  setLoading = () => {},
  updateReferee = () => {},
  deleteReferee = () => {},
  cancelReferee = () => {},
  getPdfReport = () => {},
  getCombinedReport = () => {},
  sendRequestEmail = () => {},
  sendReplacementRequestEmail = () => {},
}) => {
  const { getUpload, signUpload, createUpload, deleteUpload } = useApiUploadActions();

  const [getFormsById] = useLazyGetFormsByIdQuery();

  const [state, setState] = useState({
    selectedReferee: null,
    showRequestAlert: false,
    showRequestConfirmationAlert: false,
    showNewRefereeModal: false,
    referenceForms: [],
    candidateForm: null,
    refereeFormId: null,
  });

  const refereeNameMap = Object.fromEntries(referees.map(referee => [referee.id, referee.name]));

  const createCard = referee => (
    <RefereeCard
      key={referee.id}
      name={referee.name}
      referee={referee}
      selected={state.selectedReferee?.id === referee.id}
      disabled={referee.replaced_by}
      onClick={referee => setState(prev => ({ ...prev, selectedReferee: referee }))}
      tag={referee.replaced_by ? `Replaced by ${refereeNameMap[referee.replaced_by]}` : false}
    />
  );

  const primaryCards = referees.filter(r => !r.backup).map(createCard);
  const backupCards = referees.filter(r => r.backup).map(createCard);

  const renderRefereeCards = (referees, allSameForm) => {
    return (
      <div className={styles.cards}>
        {allSameForm && referees.length > 1 && (
          <CombinedRefereeCard
            referees={referees}
            check={check}
            selectedReferee={state.selectedReferee}
            onSelect={referee => setState(prev => ({ ...prev, selectedReferee: referee }))}
          />
        )}

        <p>Primary Referees</p>
        {primaryCards}

        {referees.length < (check?.details?.referees || 0) && (
          <AddRefereeCard onClick={handleNewReferee} />
        )}

        {backupCards.length > 0 && <BackupRefereesSection backupCards={backupCards} />}
      </div>
    );
  };

  const handleNewReferee = async () => {
    if (state.referenceForms.length < 1) {
      setLoading(true);

      const formIds = [];
      check.details.form_details.forEach(formDetails => {
        formIds.push(formDetails.candidate);
        formIds.push(formDetails.referee);
      });

      const forms = await getFormsById(formIds).unwrap();
      setState(prev => ({ ...prev, referenceForms: forms }));
      setLoading(false);
    }
    setState(prev => ({ ...prev, showNewRefereeModal: true }));
  };

  const handleNewRefereeFormSelection = index => {
    const referenceDetails = check.details.form_details[index];
    setState(prev => ({ ...prev, refereeFormId: referenceDetails.referee }));
    const candidateForm = state.referenceForms.find(form => form.id === referenceDetails.candidate);
    setState(prev => ({ ...prev, candidateForm: candidateForm }));
    setState(prev => ({ ...prev, showNewRefereeModal: false }));
  };

  const onNewReferee = async answers => {
    setLoading(true);
    const data = {
      candidate_id: candidate.id,
      candidate_check_id: check.id,
      candidate_form_id: state.candidateForm.id,
      form_id: state.refereeFormId,
      answers,
    };
    const referee = await addReferee(data);
    setState(prev => ({ ...prev, selectedReferee: referee }));
    setState(prev => ({ ...prev, candidateForm: null }));
    setState(prev => ({ ...prev, showRequestAlert: true }));
    setLoading(false);
  };

  const onNewRequest = () => {
    setState(prev => ({ ...prev, showRequestConfirmationAlert: false }));
    sendRequestEmail(state.selectedReferee.id);
  };

  return (
    <div className={styles.root}>
      <NewRefereeModal
        visible={state.showNewRefereeModal}
        forms={state.referenceForms}
        formDetails={check.details.form_details}
        onSelect={handleNewRefereeFormSelection}
        onClose={() => setState(prev => ({ ...prev, showNewRefereeModal: false }))}
      />

      <div className={styles.title}>Referees</div>
      <div className={styles.subtitle}>
        {referees?.length > 0
          ? 'View and update the list of referees provided by the candidate'
          : 'No referees have been provided'}
      </div>
      <div className={styles.content}>
        {renderRefereeCards(
          referees,
          referees.every(referee => referee['form_id'] === referees[0]['form_id']),
        )}
        {state.candidateForm && (
          <div className={cn('card', 'card-with-border', 'u-min-width-medium', 'u-padding')}>
            <h1 className="title-2 u-margin-bottom">{state.candidateForm.name}</h1>
            <Form
              candidateId={candidate.id}
              country={company.country}
              textParams={{ candidate: candidate.first_name, job: candidate.job_role }}
              form={state.candidateForm}
              brand={brand}
              buttonTitle="Create"
              onSubmit={onNewReferee}
              getCandidateUpload={getUpload}
              deleteCandidateUpload={deleteUpload}
              signCandidateUpload={signUpload}
              createCandidateUpload={createUpload}
            />
          </div>
        )}
        {state.selectedReferee?.id === COMBINED_ID && !state.candidateForm && (
          <CombinedReferees
            candidate={candidate}
            check={check}
            referees={referees.filter(referee => !referee.replaced_by && !referee.canceled)}
            setLoading={setLoading}
            messagePopUp={messagePopUp}
            getPdfReport={getCombinedReport}
            onClose={() => setState(prev => ({ ...prev, selectedReferee: null }))}
          />
        )}

        {state.selectedReferee &&
          state.selectedReferee.id !== COMBINED_ID &&
          !state.candidateForm && (
            <Referee
              candidate={candidate}
              company={company}
              check={check}
              referee={state.selectedReferee}
              setLoading={setLoading}
              messagePopUp={messagePopUp}
              updateReferee={updateReferee}
              deleteReferee={deleteReferee}
              cancelReferee={cancelReferee}
              getPdfReport={getPdfReport}
              setShowRequestAlert={state => setState(prev => ({ ...prev, showRequestAlert: true }))}
              onNewRequest={() =>
                setState(prev => ({ ...prev, showRequestConfirmationAlert: true }))
              }
              sendReplacementRequestEmail={sendReplacementRequestEmail}
              onClose={() => setState(prev => ({ ...prev, selectedReferee: null }))}
            />
          )}
      </div>
      <Alert
        show={state.showRequestAlert}
        title="Would you like to send a new request to the referee?"
        ok="Send New Request"
        cancel="No Thanks"
        params={state.showRequestAlert}
        onOk={() => {
          setState(prev => ({ ...prev, showRequestAlert: false }));
          sendRequestEmail(state.selectedReferee?.id);
        }}
        onCancel={() => {
          setState(prev => ({ ...prev, showRequestAlert: false }));
        }}
      />
      <Alert
        show={state.showRequestConfirmationAlert}
        title="Are you sure you want to send a new request?"
        ok="Send new request"
        cancel="Cancel"
        onOk={onNewRequest}
        onCancel={() => setState(prev => ({ ...prev, showRequestConfirmationAlert: false }))}
      />
    </div>
  );
};

const CombinedRefereeCard = ({ referees, check, selectedReferee, onSelect }) => (
  <div>
    <RefereeCard
      combined
      title="COMBINED REPORT"
      name={referees
        .filter(referee => !referee.replaced_by && !referee.canceled)
        .map(referee => referee.name)
        .join(' & ')}
      referee={{ id: COMBINED_ID }}
      disabled={!check.completed_at}
      onClick={onSelect}
      selected={selectedReferee?.id === COMBINED_ID}
    />
    <div className={styles.divider}></div>
  </div>
);

const BackupRefereesSection = ({ backupCards }) => (
  <>
    <div className={styles.divider} />
    <p>Backup Referees</p>
    {backupCards}
  </>
);
