import { useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { useLocale } from 'hooks/locale';
import { Badge } from 'react-bootstrap';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';

import { Checkbox } from 'components/FormComponents';
import { IconButton } from 'components/IconButton/IconButton';

import styles from './CheckTableRow.module.scss';

export const CheckTableRow = ({
  bulkSelect,
  company,
  candidate,
  companyChecks,
  onToggledSelection = () => {},
  onDelete = () => {},
  onArchiveChange = () => {},
}) => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState(false);
  const { dateSlashFormat } = useLocale(company?.locale);

  // Row selection
  useEffect(() => {
    setSelected(bulkSelect);
    onToggledSelection(candidate.id, bulkSelect);
    return () => {
      onToggledSelection(candidate.id, false);
    };
  }, [bulkSelect]);

  const handleDelete = e => {
    onDelete(candidate.id);
  };

  const handleArchiveChange = e => {
    onArchiveChange(candidate);
  };

  const subStatus = (check_status, referee_status = null) => {
    let status = `(${check_status.status.capitalize()}`;

    if (referee_status?.status === 'in-progress') {
      status += ` - ${check_status.in_progress_sub_status?.replaceAll('_', ' ').capitalize()}`;
    } else if (check_status.status === 'submitted' || check_status.status === 'in-progress') {
      status += ` - ${check_status.in_progress_sub_status?.replaceAll('_', ' ').capitalize()}`;
    } else if (
      check_status.status === 'completed' &&
      check_status.user_review_status !== 'add_review'
    ) {
      status += ` - ${check_status.user_review_status?.replaceAll('_', ' ').capitalize()}`;
    }

    status += ')';

    return status;
  };

  const userReviewIcon = (user_review_status, check_status) => {
    if (check_status !== 'completed') {
      return null;
    }

    switch (user_review_status) {
      case 'approved':
        return (
          <FontAwesomeIcon
            icon="check-circle"
            className={cn(styles.approvalIcon_Approved, styles.approvalIcon)}
          />
        );
      case 'not_approved':
        return (
          <FontAwesomeIcon
            icon="exclamation-triangle"
            className={cn(styles.approvalIcon_NotApproved, styles.approvalIcon)}
          />
        );
      case 'needs_review':
        return (
          <FontAwesomeIcon
            icon="exclamation-triangle"
            className={cn(styles.approvalIcon_NeedReview, styles.approvalIcon)}
          />
        );
      default:
        return null;
    }
  };

  const statusIcons = () => {
    let icons = [];

    candidate.checks_statuses?.forEach(check_status => {
      console.log('check_status', check_status);
      const checkType = companyChecks?.find(
        companyCheck => companyCheck.type === check_status.type,
      )?.check_type;
      if (!checkType) return;

      if (
        (check_status.type === 'reference' || check_status.type === 'custom_reference') &&
        candidate.referees_statuses
      ) {
        candidate.referees_statuses.forEach(referee => {
          icons.push(
            <OverlayTrigger
              key={referee.id}
              overlay={
                <Tooltip id={referee.id}>
                  {referee.name} Referee {subStatus(check_status, referee)}{' '}
                </Tooltip>
              }>
              <div className={cn(styles.userIcon, `u-txt-status-${referee.status}`)}>
                {referee.warning_flag === 1 && check_status.status === 'completed' ? (
                  <FontAwesomeIcon icon="exclamation-triangle" className={styles.resultIcon} />
                ) : (
                  userReviewIcon(check_status.user_review_status, check_status.status)
                )}
                <Link to={`/check/${candidate.id}/${checkType.candidate_tab}/${referee.id}`}>
                  <FontAwesomeIcon className={`u-txt-status-${referee.status}`} icon="user" />
                </Link>
              </div>
            </OverlayTrigger>,
          );
        });
      } else {
        icons.push(
          <OverlayTrigger
            key={check_status.id}
            overlay={
              <Tooltip id={check_status.id}>
                {checkType.title} {subStatus(check_status)}{' '}
              </Tooltip>
            }>
            <div className={cn(styles.userIcon, `u-txt-status-${check_status.status}`)}>
              {check_status.result_flag === 1 && check_status.status === 'completed' ? (
                <FontAwesomeIcon icon="exclamation-triangle" className={styles.resultIcon} />
              ) : (
                userReviewIcon(check_status.user_review_status, check_status.status)
              )}
              <Link to={`/check/${candidate.id}/${checkType.candidate_tab}`}>
                <FontAwesomeIcon
                  className={`u-txt-status-${check_status.status}`}
                  icon={checkType.icon}
                />
              </Link>
            </div>
          </OverlayTrigger>,
        );
      }
    });

    return icons;
  };

  return (
    <div className={styles.root}>
      <div className={cn(styles.checkRow)}>
        <Checkbox
          className={cn(styles.checkbox, 'u-margin-top--small')}
          checked={selected}
          onClick={selected => {
            setSelected(selected);
            onToggledSelection(candidate.id, selected);
          }}
        />
        <div className={styles.column}>
          <Badge className={`u-status-${candidate.status}`}>{candidate.status}</Badge>
        </div>
        <div className={styles.column}>
          <Link to={`/check/${candidate.id}`} className="u-link dark">
            {candidate.name}
          </Link>
        </div>
        <div className={cn(styles.column, 'sm-hide')}>
          <div className={cn(styles.gap, 'u-flex-box u-flex-align-center u-flex-wrap')}>
            <p className="u-text-ellipsis u-no-margin">{candidate.team_name}</p>
            <div className={cn(styles.gap, 'u-flex-box u-flex-align-center u-flex-wrap')}>
              {statusIcons()}
            </div>
          </div>
        </div>
        <div className={cn(styles.column, 'sm-hide')}>
          <p className="u-text-ellipsis u-no-margin">
            {candidate.job_role +
              (candidate.reference_code ? ` - ${candidate.reference_code}` : '')}
          </p>
        </div>
        <div className={cn(styles.column, 'sm-hide')}>
          <p className="u-text-ellipsis u-no-margin">{candidate.user_name}</p>
        </div>
        <div className={cn(styles.column, 'u-flex-box', 'u-flex-justify-between')}>
          <Moment format={dateSlashFormat}>{candidate.created_at}</Moment>
          <div className={cn(styles.iconsContainer, 'xs-hide')}>
            <Link to={`/check/${candidate.id}/background_checks`}>
              <IconButton className="u-margin-right--small" icon="check-square" tip="View report" />
            </Link>
            <IconButton
              className="u-margin-right--small"
              icon="archive"
              tip={candidate.archived ? 'Restore' : 'Archive'}
              active={candidate.archived}
              onClick={handleArchiveChange}
            />
            <IconButton icon="trash-2" tip="Delete" onClick={handleDelete} />
          </div>
        </div>
      </div>
    </div>
  );
};
