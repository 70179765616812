import { useState } from 'react';
import { useEffect } from 'react';

import { Currencies } from 'constants/currencies';
import { useForm } from 'react-hook-form';

import { AnimatedModal } from 'components/AnimatedModal/AnimatedModal';
import { EditableSettings } from 'components/EditableSettings/EditableSettings';
import {
  Button,
  Input,
  InputGroup,
  OptionsConfig,
  Select,
  ToggleSwitch,
} from 'components/FormComponents';
import { ToggleSwitchInput } from 'components/FormComponents/ToggleSwitchInput/ToggleSwitchInput';
import { IconButton } from 'components/IconButton/IconButton';

export const AdminCompanyCheck = ({
  company,
  checkType,
  companyCheck,
  users,
  billingInfo,
  setLoading = () => {},
  onToggle = () => {},
  onSave = () => {},
  onAddCredits = () => {},
}) => {
  const { control, register, watch, reset, handleSubmit } = useForm();
  const [enabled, setEnabled] = useState();

  const [showAddCreditsModal, setShowAddCreditsModal] = useState(false);

  const [credits, setCredits] = useState(companyCheck?.credits);

  useEffect(() => {
    if (enabled === undefined || (enabled && companyCheck) || (!enabled && !companyCheck)) return;

    onToggle(companyCheck?.id, checkType.slug, enabled);
  }, [enabled]);

  useEffect(() => {
    setEnabled(!!companyCheck);
    reset(companyCheck);
  }, [companyCheck]);

  const handleSave = async data => {
    await onSave(companyCheck.id, data);
  };

  const renderExtraConfigurationField = (name, type) => {
    const humanName = name.replaceAll('_', ' ');
    switch (type) {
      case 'options':
        return (
          <InputGroup title={<span className="u-capitalize">{humanName}</span>} key={name}>
            <OptionsConfig name={`configuration.${name}`} register={register} control={control} />
          </InputGroup>
        );
      case 'text':
        return (
          <InputGroup title={<span className="u-capitalize">{humanName}</span>} key={name}>
            <Input register={register} name={`configuration.${name}`} />
          </InputGroup>
        );
      case 'bool':
        return (
          <ToggleSwitch
            key={name}
            register={register}
            name={`configuration.${name}`}
            label={<span className="u-capitalize">{humanName}</span>}
          />
        );
      default:
        return null;
    }
  };

  const handleAddCredits = async data => {
    setShowAddCreditsModal(false);
    data['company_id'] = company.id;
    data['check_type'] = checkType.slug;
    setLoading(true);
    const { data: credits } = await onAddCredits(data);
    setCredits(credits);
    setLoading(false);
  };

  return (
    <ToggleSwitchInput
      name={checkType.slug}
      className="u-margin-right--large"
      text={`Activate ${checkType.title}`}
      subtext={`(${checkType.slug})`}
      logo={checkType.logo}
      value={enabled}
      onChange={e => setEnabled(e.target.checked)}>
      <AddCreditsModal
        visible={showAddCreditsModal}
        billingInfo={billingInfo}
        users={users}
        checkType={checkType}
        companyCheck={companyCheck}
        onAdd={handleAddCredits}
        onClose={() => setShowAddCreditsModal(false)}
      />

      <h3 className="t-tiny u-uppercase">credits</h3>
      <div className="d-flex justify-content-between">
        <h1 className="u-margin-top--0">{credits}</h1>
        <IconButton icon="plus" tip="Add credits" onClick={() => setShowAddCreditsModal(true)} />
      </div>

      <form onSubmit={handleSubmit(handleSave)}>
        <EditableSettings
          title="Custom pricing"
          open={watch('use_custom_pricing')}
          toggleable
          register={register}
          name="use_custom_pricing"
          className="u-margin-top--large">
          <InputGroup title="pricing">
            <Input register={register} name="pricing" type="number" inputProps={{ step: '0.01' }} />
          </InputGroup>
        </EditableSettings>

        {checkType.configuration?.map(config =>
          renderExtraConfigurationField(config.name, config.type),
        )}

        <Button submit className="u-width-100">
          Save
        </Button>
      </form>
    </ToggleSwitchInput>
  );
};

const AddCreditsModal = ({
  visible,
  users,
  checkType,
  billingInfo,
  companyCheck,
  onAdd = () => {},
  onClose = () => {},
}) => {
  const { register, watch, handleSubmit, reset } = useForm({
    defaultValues: {
      currency: billingInfo?.currency,
      unit_price: companyCheck?.use_custom_pricing
        ? companyCheck.pricing
        : checkType?.[`pricing_${billingInfo?.currency}`] || 0,
      units: 0,
    },
  });

  useEffect(() => {
    reset({
      currency: billingInfo?.currency,
      unit_price: companyCheck?.use_custom_pricing
        ? companyCheck.pricing
        : checkType?.[`pricing_${billingInfo?.currency}`] || 0,
      units: 0,
    });
  }, [companyCheck, billingInfo, checkType]);

  return (
    <AnimatedModal
      title={`Add ${checkType.title} credits`}
      visible={visible}
      onClose={onClose}
      small>
      <form className="u-margin-top" onSubmit={handleSubmit(onAdd)}>
        <InputGroup title="Required by">
          <Select name="user_name" register={register} useDefault value={watch('user_name')}>
            {users.map(user => (
              <Select.Item value={user.name}>
                {user.name} <span className="t-tiny"> ({user.email})</span>
              </Select.Item>
            ))}
          </Select>
        </InputGroup>
        <InputGroup title="Currency">
          <Select name="currency" register={register} value={watch('currency')} useDefault>
            {Object.keys(Currencies).map(currency => (
              <Select.Item value={currency}>{currency.toUpperCase()}</Select.Item>
            ))}
          </Select>
        </InputGroup>
        <InputGroup title="Unit price">
          <Input
            name="unit_price"
            type="number"
            register={register}
            inputProps={{ step: '0.01' }}
          />
        </InputGroup>
        <InputGroup title="Units" useDefault>
          <Input name="units" type="number" register={register} />
        </InputGroup>
        <Button submit className="u-width-100 u-margin-top--large">
          Add
        </Button>
      </form>
    </AnimatedModal>
  );
};
