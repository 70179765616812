import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { saveAs } from 'file-saver';
import { toQueryParams } from 'helpers/api';

export const partnerV1Api = createApi({
  reducerPath: 'partnerV1Api',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URl}/partners/v1`,
  }),
  endpoints: builder => ({
    getCandidate: builder.query({
      query: ({ token, applicationId }) => ({
        url: `/candidates/${applicationId}`,
        headers: { Authorization: token },
      }),
      transformResponse: ({ result }) => result,
    }),
    createCandidate: builder.mutation({
      query: ({ token, params }) => ({
        url: '/candidates',
        method: 'POST',
        body: params,
        headers: { Authorization: token },
      }),
      transformResponse: ({ result }) => result,
    }),
    updateCandidate: builder.mutation({
      query: ({ token, applicationId, candidateData }) => ({
        url: `/candidates/${applicationId}`,
        method: 'PUT',
        body: { candidate: candidateData },
        headers: { Authorization: token },
      }),
      transformResponse: ({ result }) => result,
    }),
    updateCandidateCheck: builder.mutation({
      query: ({ token, applicationId, checkId, data }) => ({
        url: `candidates/${applicationId}/checks/${checkId}`,
        method: 'PUT',
        body: { candidate_check: data },
        headers: { Authorization: token },
      }),
      transformResponse: ({ result }) => result,
    }),
    getBrands: builder.query({
      query: token => ({
        url: '/branding',
        headers: { Authorization: token },
      }),
      transformResponse: ({ result }) => result,
    }),
    getBrandUploads: builder.query({
      query: ({ token, brandId }) => ({
        url: `/branding/${brandId}/uploads`,
        headers: { Authorization: token },
      }),
      transformResponse: ({ result }) => result,
    }),
    getForms: builder.query({
      query: token => ({
        url: '/forms/types',
        headers: { Authorization: token },
      }),
      transformResponse: ({ result }) => result,
    }),
    getCompany: builder.query({
      query: token => ({
        url: '/company',
        headers: { Authorization: token },
      }),
      transformResponse: ({ result }) => result,
    }),
    getPartnersConfig: builder.query({
      query: token => ({
        url: '/partners_config',
        headers: { Authorization: token },
      }),
      transformResponse: ({ result }) => result,
    }),
    getUsers: builder.query({
      query: token => ({
        url: '/users',
        headers: { Authorization: token },
      }),
      transformResponse: ({ result }) => result?.users,
    }),
    getCompanyChecks: builder.query({
      query: token => ({
        url: '/company/checks',
        headers: { Authorization: token },
      }),
      transformResponse: ({ result }) => result,
    }),
    getBundles: builder.query({
      query: token => ({
        url: '/bundles',
        headers: { Authorization: token },
      }),
      transformResponse: ({ result }) => result,
    }),
    getCandidateHistory: builder.query({
      query: ({ token, applicationId }) => ({
        url: `/candidates/${applicationId}/history`,
        headers: { Authorization: token },
      }),
      transformResponse: ({ result }) => result,
    }),
    getUpload: builder.query({
      query: ({ token, id }) => ({
        url: `/uploads/${id}`,
        headers: { Authorization: token },
      }),
      transformResponse: ({ result }) => result,
    }),
    signUpload: builder.query({
      query: ({ token, params }) => ({
        url: `/uploads/sign?${toQueryParams(params)}`,
        headers: { Authorization: token },
      }),
      transformResponse: ({ result }) => result,
    }),
    createUpload: builder.mutation({
      query: ({ token, params }) => ({
        url: '/uploads',
        method: 'POST',
        body: params,
        headers: { Authorization: token },
      }),
      transformResponse: ({ result }) => result,
    }),
    deleteUpload: builder.mutation({
      query: ({ token, id }) => ({
        url: `/uploads/${id}`,
        method: 'DELETE',
        headers: { Authorization: token },
      }),
      transformResponse: ({ result }) => result,
    }),
    addCandidateChecks: builder.mutation({
      query: ({ token, applicationId, checks }) => ({
        url: `/candidates/${applicationId}/checks`,
        method: 'POST',
        body: { checks },
        headers: { Authorization: token },
      }),
      transformResponse: ({ result }) => result,
    }),
    cancelCandidateCheck: builder.mutation({
      query: ({ token, applicationId, checkId }) => ({
        url: `candidates/${applicationId}/checks/${checkId}/cancel`,
        method: 'PUT',
        headers: { Authorization: token },
      }),
      transformResponse: ({ result }) => result,
    }),
    startPartnerAssessment: builder.mutation({
      query: ({ token, applicationId }) => ({
        url: `/candidates/${applicationId}/start`,
        method: 'POST',
        headers: { Authorization: token },
      }),
      transformResponse: ({ result }) => result,
    }),
    getCandidateCheckResult: builder.query({
      queryFn: async ({ token, check, candidate, isFile = true }, api, extraOptions, baseQuery) => {
        const { data: result } = await baseQuery({
          url: `/candidates/${candidate.partner_application_id}/checks/${check.id}/result`,
          method: 'GET',
          responseHandler: async response => {
            if (isFile) {
              const blob = await response.blob();
              return blob;
            } else {
              const jsonResponse = await response.json();
              return jsonResponse.result;
            }
          },
          cache: 'no-cache',
          headers: { Authorization: token },
        });
        if (isFile) saveAs(result, `${candidate.name}_${check.type}`);
        else window.open(result, '_blank');

        return { data: null };
      },
    }),
    getSubCheckResult: builder.query({
      queryFn: async (
        { token, subCheck, check, candidate, isFile = true },
        api,
        extraOptions,
        baseQuery,
      ) => {
        const { data: result } = await baseQuery({
          url: `candidates/${candidate.partner_application_id}/sub_checks/${subCheck.id}/result`,
          method: 'GET',
          responseHandler: async response => {
            if (isFile) {
              const blob = await response.blob();
              return blob;
            } else {
              const jsonResponse = await response.json();
              return jsonResponse.result;
            }
          },
          cache: 'no-cache',
          headers: { Authorization: token },
        });
        if (isFile)
          saveAs(
            result,
            `${candidate.first_name}_${candidate.last_name}-${check.type}-${subCheck.title}`,
          );
        else window.open(result, '_blank');

        return { data: null };
      },
    }),
  }),
});

export const {
  useGetCandidateQuery,
  useUpdateCandidateCheckMutation,
  useGetBundlesQuery,
  useGetBrandsQuery,
  useLazyGetBrandUploadsQuery,
  useGetFormsQuery,
  useGetUsersQuery,
  useGetCompanyQuery,
  useGetPartnersConfigQuery,
  useGetCompanyChecksQuery,
  useCreateCandidateMutation,
  useLazyGetUploadQuery,
  useCreateUploadMutation,
  useLazySignUploadQuery,
  useDeleteUploadMutation,
  useUpdateCandidateMutation,
  useAddCandidateChecksMutation,
  useStartPartnerAssessmentMutation,
  useGetCandidateHistoryQuery,
  useLazyGetCandidateCheckResultQuery,
  useCancelCandidateCheckMutation,
  useLazyGetSubCheckResultQuery,
} = partnerV1Api;
