import { useEffect, useState } from 'react';

import cn from 'classnames';
import { FormTypes } from 'constants/forms';
import { useTranslation } from 'react-i18next';

import { FormEdit } from 'components/Form/FormEdit/FormEdit';
import { List } from 'components/List/List';
import { SectionedFormEdit } from 'components/SectionedForm/SectionedFormEdit/SectionedFormEdit';

export const ArchivedForms = ({
  forms = [],
  teams = [],
  onDelete = () => {},
  onRestore = () => {},
}) => {
  const { t } = useTranslation();

  const [form, setForm] = useState();

  const handleFormDelete = params => {
    onDelete(params);
  };

  useEffect(() => {
    if (form && form.id && !forms.some(f => f.id === form.id)) {
      setForm(null);
    }
  }, [forms, form]);

  const handleFormRestore = () => {
    if (form?.id) {
      onRestore(form);
      setForm(null);
    }
  };

  return (
    <div className={cn('u-flex-box', 'u-flex-align-start')}>
      <List
        title={`${t('forms.archived.title')} (${forms.length})`}
        subtitle={t('forms.archived.subtitle')}
        className="u-margin-right--large u-flex-align-self-normal">
        {Object.values(FormTypes).map(type => {
          const typeKey = type.replace(':', '');
          const typesForms = forms.filter(form => form.type === type);
          return typesForms.length > 0 ? (
            <List.Category key={type} title={t(`forms.${typeKey}`)}>
              {typesForms.map(form => (
                <List.Item key={form.id} title={form.name} value={form} onClick={setForm} />
              ))}
            </List.Category>
          ) : null;
        })}
      </List>
      {form && (
        <>
          {form.type === FormTypes.CUSTOM ? (
            <SectionedFormEdit
              form={form}
              teams={teams}
              onRestore={handleFormRestore}
              onClose={() => setForm(null)}
              onDelete={handleFormDelete}
              readOnly={true}
            />
          ) : (
            <FormEdit
              form={form}
              teams={teams}
              onRestore={handleFormRestore}
              onClose={() => setForm(null)}
              onDelete={handleFormDelete}
              readOnly={true}
            />
          )}
        </>
      )}
    </div>
  );
};
