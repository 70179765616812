import { configureStore } from '@reduxjs/toolkit';

import { adminLogsApi } from 'api/admin-logs-api';
import { adminRolesApi } from 'api/admin-roles-api';
import AppReducer from 'api/app-slice';
import { auditLogsApi } from 'api/audit-logs-api';
import { authenticationApi } from 'api/authentication-api';
import AuthenticationReducer from 'api/authentication-slice';
import { billingApi } from 'api/billing-api';
import { brandApi } from 'api/brands-api';
import BrandsReducer from 'api/brands-slice';
import BundlesReducer from 'api/bundles-slice';
import CandidateApplicationReducer from 'api/candidate-application-slice';
import { candidateChecksApi } from 'api/candidate-checks-api';
import { candidateChecksValuesApi } from 'api/candidate-checks-values-api';
import CustomFieldReducer from 'api/candidate-custom-fields-slice';
import { candidatesApi } from 'api/candidates-api';
import CandidateReducer from 'api/candidates-slice';
import { checkTypeApi } from 'api/check-types-api';
import { companyApi } from 'api/company-api';
import { companyChecksApi } from 'api/company-checks-api';
import CompanyChecksReducer from 'api/company-checks-slice';
import CompanyReducer from 'api/company-slice';
import { formsApi } from 'api/forms-api';
import FormsReducer from 'api/forms-slice';
import { idVerificationsApi } from 'api/id-verifications-api';
import { integrationApi } from 'api/integration-api';
import { linkedinApi } from 'api/linkedin-api';
import { notificationSettingsApi } from 'api/notification-settings-slice';
import { oauthClientApi } from 'api/oauth-client-api';
import { partnerApi } from 'api/partner-api';
import { partnerV1Api } from 'api/partner-v1-api';
import PartnerConfigReducer from 'api/partners-config-slice';
import { permissionsApi } from 'api/permissions-api';
import { purchasesApi } from 'api/purchases-api';
import { refereesApi } from 'api/referees-api';
import { reportingApi } from 'api/reporting-api';
import { reportsApi } from 'api/reports-api';
import RolesReducer from 'api/roles-slice';
import { subChecksApi } from 'api/sub-checks-api';
import TagReducer from 'api/tags-slice';
import TeamsReducer from 'api/teams-slice';
import { uploadApi } from 'api/upload-api';
import { userApi } from 'api/user-api';
import UserPermissionsReducer from 'api/user-permissions-slice';
import UserReducer from 'api/user-slice';

export const store = configureStore({
  reducer: {
    authentication: AuthenticationReducer,
    candidates: CandidateReducer,
    bundles: BundlesReducer,
    company: CompanyReducer,
    permissions: UserPermissionsReducer,
    user: UserReducer,
    app: AppReducer,
    forms: FormsReducer,
    companyChecks: CompanyChecksReducer,
    tags: TagReducer,
    candidateApplication: CandidateApplicationReducer,
    teams: TeamsReducer,
    roles: RolesReducer,
    brands: BrandsReducer,
    customFields: CustomFieldReducer,
    partnersConfig: PartnerConfigReducer,
    [idVerificationsApi.reducerPath]: idVerificationsApi.reducer,
    [candidatesApi.reducerPath]: candidatesApi.reducer,
    [candidateChecksApi.reducerPath]: candidateChecksApi.reducer,
    [companyApi.reducerPath]: companyApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [billingApi.reducerPath]: billingApi.reducer,
    [uploadApi.reducerPath]: uploadApi.reducer,
    [notificationSettingsApi.reducerPath]: notificationSettingsApi.reducer,
    [formsApi.reducerPath]: formsApi.reducer,
    [checkTypeApi.reducerPath]: checkTypeApi.reducer,
    [candidateChecksValuesApi.reducerPath]: candidateChecksValuesApi.reducer,
    [companyChecksApi.reducerPath]: companyChecksApi.reducer,
    [adminLogsApi.reducerPath]: adminLogsApi.reducer,
    [adminRolesApi.reducerPath]: adminRolesApi.reducer,
    [reportsApi.reducerPath]: reportsApi.reducer,
    [authenticationApi.reducerPath]: authenticationApi.reducer,
    [oauthClientApi.reducerPath]: oauthClientApi.reducer,
    [brandApi.reducerPath]: brandApi.reducer,
    [refereesApi.reducerPath]: refereesApi.reducer,
    [subChecksApi.reducerPath]: subChecksApi.reducer,
    [partnerApi.reducerPath]: partnerApi.reducer,
    [linkedinApi.reducerPath]: linkedinApi.reducer,
    [auditLogsApi.reducerPath]: auditLogsApi.reducer,
    [integrationApi.reducerPath]: integrationApi.reducer,
    [partnerV1Api.reducerPath]: partnerV1Api.reducer,
    [permissionsApi.reducerPath]: permissionsApi.reducer,
    [purchasesApi.reducerPath]: purchasesApi.reducer,
    [reportingApi.reducerPath]: reportingApi.reducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat([
      idVerificationsApi.middleware,
      candidatesApi.middleware,
      candidateChecksApi.middleware,
      companyApi.middleware,
      userApi.middleware,
      billingApi.middleware,
      uploadApi.middleware,
      notificationSettingsApi.middleware,
      formsApi.middleware,
      checkTypeApi.middleware,
      candidateChecksValuesApi.middleware,
      companyChecksApi.middleware,
      adminLogsApi.middleware,
      adminRolesApi.middleware,
      reportsApi.middleware,
      authenticationApi.middleware,
      oauthClientApi.middleware,
      brandApi.middleware,
      refereesApi.middleware,
      subChecksApi.middleware,
      partnerApi.middleware,
      linkedinApi.middleware,
      auditLogsApi.middleware,
      integrationApi.middleware,
      partnerV1Api.middleware,
      permissionsApi.middleware,
      purchasesApi.middleware,
      reportingApi.middleware,
    ]),
  devTools: process.env.REACT_APP_DEBUG,
});
