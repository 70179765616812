import cn from 'classnames';

import { IconButton } from '../IconButton/IconButton';
import styles from './DisplayBox.module.scss';

const DisplayBox = ({
  show = false,
  cancelable = false,
  onCancel = () => {},
  className,
  children,
}) => {
  return (
    show && (
      <div className={cn(styles.root, 'u-border-radius--small u-border', className)}>
        <div
          className={cn(styles.header, 'u-flex-box align-items-center', {
            'u-flex-justify-between': cancelable,
            'u-flex-justify-center': !cancelable,
          })}>
          {children}
          {cancelable && <IconButton icon="x" onClick={onCancel} type="white" />}
        </div>
      </div>
    )
  );
};

export default DisplayBox;
