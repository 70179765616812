import { useEffect, useState } from 'react';

import cn from 'classnames';
import FeatherIcon from 'feather-icons-react';
import { uploadSigned } from 'helpers/aws-s3';
import { useBrandingStyleVariables } from 'hooks/branding-style-variables';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import * as mime from 'react-native-mime-types';

import { Loader } from 'components/Loader/Loader';

import styles from './FilePicker.module.scss';

export const FilePicker = ({
  value,
  modelParams = {},
  metadata = {},
  className,
  bucketFolder = 'answers',
  maxSize = 20971520,
  accept,
  brand,
  useModel = false,
  multiple = false,
  createUploadAction = true,
  onChange = () => {},
  getUpload = () => {},
  signUpload = () => {},
  createUpload = () => {},
  deleteUpload = () => {},
}) => {
  const [fileName, setFileName] = useState();
  const [loading, setLoading] = useState(false);

  // For multiple
  const [uploads, setUploads] = useState([]);

  const bradingStyleVariables = useBrandingStyleVariables(brand);

  // Initial state
  useEffect(() => {
    // For multiple
    if (multiple) {
      setFileName(null);
      setUploads(value || []);
      return;
    }

    if (!value) {
      setFileName(null);
      return;
    }

    if (useModel) {
      setFileName(value.file_name);
    } else {
      getUpload(value).then(upload => setFileName(upload.file_name));
    }
  }, [value?.length, multiple]);

  /**
   * --- On upload ---
   * 1) Get file meta
   * 2) Sign upload
   * 3) Upload to s3
   * 4) Create upload
   * 5) Set value
   */
  const onDrop = async files => {
    if (files.length < 1) return;

    setLoading(true);
    const file = files[0];
    if (!file) return;

    // Sign upload
    const params = {
      file_name: file.name,
      file_type: file.type,
      file_ext: mime.extension(file.type),
      bucket_folder: bucketFolder,
    };

    const signedFile = await signUpload(params);

    // Upload to S3
    await uploadSigned(signedFile.url, file, file.type);

    // Save model
    const uploadData = {
      ...modelParams,
      metadata,
      file_name: signedFile.file_name,
      file_type: signedFile.file_type,
      hash_key: signedFile.key,
    };

    if (createUploadAction) {
      const upload = await createUpload(uploadData);
      if (!multiple) {
        setFileName(upload.file_name);
      } else {
        setUploads(curr => [...curr, upload]);
      }
      onChange(useModel ? upload : upload.id);
    } else {
      if (!multiple) {
        setFileName(uploadData.file_name);
      } else {
        setUploads(curr => [...curr, uploadData]);
      }
      onChange(uploadData);
    }
    setLoading(false);
  };

  const onRemove = async (id = null) => {
    if (multiple) {
      setUploads(curr => curr.filter(u => u.id !== id));
      await deleteUpload(id);
    } else {
      if (value) {
        setLoading(true);
        const id = useModel ? value.id : value;
        await deleteUpload(id);
        setLoading(false);
      }
      setFileName(null);
      onChange(null);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop, accept, maxSize });

  return (
    <>
      <div className={cn(styles.root, { [className]: className })} style={bradingStyleVariables}>
        {loading && <Loader brand={brand} />}

        {fileName && !loading && (
          <div className={styles.fileWrapper}>
            <FeatherIcon icon="file" size={40} />
            <p>{fileName}</p>
            <div className={styles.removeFileIconWrapper} onClick={onRemove}>
              <FeatherIcon className={styles.removeFileIcon} icon="trash-2" size={50} />
            </div>
          </div>
        )}

        {!fileName && !loading && (
          <div {...getRootProps({ className: styles.dropzone })}>
            <input {...getInputProps()} />
            <img src="assets/images/icons/file-upload.svg" alt="upload" />
            <p className={styles.placeholder}>
              Drop your documents here, or <span className={styles.browseButton}>browse</span>
            </p>
          </div>
        )}
      </div>
      {multiple &&
        (uploads || []).map(upload => (
          <div key={upload.id} className={styles.uploadLine}>
            <div className={styles.uploadName}>
              <FeatherIcon icon="file" size={21} />
              <span>{upload.file_name}</span>
            </div>
            <div className={styles.uploadDeleteIcon} onClick={() => onRemove(upload.id)}>
              <FeatherIcon icon="trash-2" size={13} />
            </div>
          </div>
        ))}
    </>
  );
};

FilePicker.propTypes = {
  value: PropTypes.any,
  modelParams: PropTypes.object,
  metadata: PropTypes.object,
  className: PropTypes.string,
  bucketFolder: PropTypes.string,
  onChange: PropTypes.func,
  getUpload: PropTypes.func,
  signUpload: PropTypes.func,
  createUpload: PropTypes.func,
  deleteUpload: PropTypes.func,
  accept: PropTypes.object,
};
