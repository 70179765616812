import JSONInput from 'react-json-editor-ajrm';

export const JsonInput = ({ value, onChange = () => {} }) => {
  const hanldeChange = data => {
    if (value === data.jsObject) return;

    onChange(data.jsObject);
  };

  return (
    <JSONInput
      placeholder={value}
      theme="light_mitsuketa_tribute"
      height="200px"
      onChange={hanldeChange}
      colors={{ background: '$surface-secondary' }}
      reset={true}
      style={{
        outerBox: { width: '100%' },
        container: { width: '100%', border: '1px solid #e5e5e5', borderRadius: '6px' },
      }}
    />
  );
};
