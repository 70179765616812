import cn from 'classnames';
import PropTypes from 'prop-types';

import { UserAvatar } from 'components/UserAvatar/UserAvatar';

export const UserInfo = ({
  display,
  email,
  firstName,
  lastName,
  className,
  displayAvatar = true,
}) => (
  <div
    className={cn(
      className,
      'd-flex u-padding--small user-info-container u-border-radius--xs-small',
    )}>
    {displayAvatar && (
      <UserAvatar className="d-flex align-items-center justify-content-center u-margin-right--small u-no-border surface-light-white">
        <span className="avatar-initials">
          {firstName?.charAt(0).toUpperCase() || 'N'}
          {lastName?.charAt(0).toUpperCase() || '/A'}
        </span>
      </UserAvatar>
    )}
    <div className="d-flex flex-column">
      <span className="font-semibold t-body primary-text">{display}</span>
      {email && <span className="t-small secondary-text">{email}</span>}
    </div>
  </div>
);

UserInfo.propTypes = {
  display: PropTypes.string.isRequired,
  email: PropTypes.string,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  className: PropTypes.string,
};
