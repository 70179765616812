import { useEffect, useMemo, useRef, useState } from 'react';

import cn from 'classnames';
import { MESSAGE_STATE_ERROR, MESSAGE_STATE_SUCCESS } from 'constants/message-app-state-contants';
import { Permissions } from 'constants/permissions';
import { errorRouter } from 'helpers/error-router';
import { useDocumentTitle } from 'hooks/document-title';
import { useApiUploadActions } from 'hooks/upload-actions';
import AppPage from 'layouts/AppPage/AppPage';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import useLocalStorage from 'react-use-localstorage';

import { Step1Form } from './components/Step1Form/Step1Form';
import { Step2Form } from './components/Step2Form/Step2Form';
import { StepSelector } from './components/StepSelector/StepSelector';
import { AnimatedModal } from 'components/AnimatedModal/AnimatedModal';
import { Button } from 'components/FormComponents';

import { messagePopUp } from 'api/app-slice';
import { getBrandUploads, getBrands, selectBrands } from 'api/brands-slice';
import { getBundles, selectBundles } from 'api/bundles-slice';
import { useCreateCandidateCheckMutation } from 'api/candidate-checks-api';
import { getCustomFields, selectCustomFields } from 'api/candidate-custom-fields-slice';
import { createCandidate } from 'api/candidates-slice';
import { selectCompanyChecks } from 'api/company-checks-slice';
import { selectCompany } from 'api/company-slice';
import { getForms } from 'api/forms-slice';
import { selectForms } from 'api/forms-slice';
import { useGetCompanyUsersQuery, useGetUserTeamsQuery } from 'api/user-api';
import { selectUser } from 'api/user-slice';

import styles from './NewCheck.module.scss';

const NewCheck = () => {
  useDocumentTitle('New Check');
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { target } = useParams();

  const forms = useSelector(selectForms);
  const user = useSelector(selectUser);
  const company = useSelector(selectCompany);
  const companyChecks = useSelector(selectCompanyChecks);
  const brands = useSelector(selectBrands);
  const bundles = useSelector(selectBundles);
  const customFields = useSelector(selectCustomFields);

  const [createCandidateCheck] = useCreateCandidateCheckMutation();

  const { data: teams } = useGetUserTeamsQuery(user.id);
  const { data: companyUsers } = useGetCompanyUsersQuery(user?.id, {
    refetchOnMountOrArgChange: true,
  });

  const { getUpload, signUpload, createUpload, deleteUpload } = useApiUploadActions();

  const [targetForm, setTargetForm] = useLocalStorage('newCandidateForm');
  const [loading, setLoading] = useState(true);
  const [step, setStep] = useState(1);
  const [invalidStep, setInvalidStep] = useState(false);

  // Data
  const [candidateData, setCandidateData] = useState({});
  const [showExistingCandidateConfirmation, setShowExistingCandidateConfirmation] = useState(false);
  const [existingCandidateError, setExistingCandidateError] = useState(null);
  const [candidateChecksData, setCandidateChecksData] = useState(null);

  // Ref
  const topRef = useRef(null);
  const step1FormRef = useRef(null);

  const onGetBrandUploads = brandId => dispatch(getBrandUploads(brandId));

  // Initialize
  useEffect(() => {
    if (!user.id) return;

    if (target) setTargetForm(target);

    Promise.all([
      dispatch(getBrands()),
      dispatch(getForms()),
      dispatch(getCustomFields()),
      dispatch(getBundles()),
    ]).then(
      _ => setLoading(false),
      error => navigate(errorRouter(error)),
    );
  }, [user, company]);

  // Scroll
  const scrollToTop = () => {
    topRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  };

  // Next Step
  const nextStep = () => {
    scrollToTop();
    step1FormRef.current.submit();
  };

  const submitCandidateData = data => {
    setCandidateData(data);
    setStep(2);
  };

  // Submit
  const submit = async (checksData, forceBypass = false) => {
    const data = {
      candidate_data: {
        ...candidateData,
        ...checksData.candidate,
        bypass_existing_candidate: forceBypass,
        custom_fields: candidateData.custom_fields?.reduce((prev, value, index) => {
          return value ? { ...prev, [index]: value } : prev;
        }, {}),
      },
      checks: checksData.checks,
    };
    setLoading(true);

    try {
      const { payload: response } = await dispatch(createCandidate(data));

      if (response.error) {
        if (response.status === 422) {
          setCandidateChecksData(checksData);
          setExistingCandidateError(response.error);
          setShowExistingCandidateConfirmation(true);
          return;
        }
        throw response.error.log;
      }

      dispatch(
        messagePopUp({ text: t('newCheck.Added'), state: MESSAGE_STATE_SUCCESS, hide: true }),
      );

      const referenceCheck = data.checks.find(check => check?.type === 'reference');
      const customReferenceCheck = data.checks.find(check => check?.type === 'custom_reference');

      if (referenceCheck?.details.bypassed) {
        navigate(`/check/${response.result.id}/referees`);
      } else if (customReferenceCheck?.details.bypassed) {
        navigate(`/check/${response.result.id}/reference`);
      } else {
        navigate('/dashboard');
      }
    } catch (error) {
      dispatch(messagePopUp({ text: t('newCheck.Error'), state: MESSAGE_STATE_ERROR, hide: true }));
    } finally {
      setLoading(false);
    }
  };

  const defaultValues = {
    user_id: user.id,
  };

  // Render
  return (
    <AppPage loading={loading} requiredPermissions={[Permissions.CandidateWrite]}>
      <div className={styles.root}>
        <div ref={topRef} />
        <AppPage.Header
          title={t('newCheck.Title')}
          subtitle={t('newCheck.Subtitle')}
          ctaText={t('newCheck.BulkImport')}
          ctaClick={() => navigate('/bulk_import/candidates')}
          className="u-divider-bottom-border"
        />
        <div className={styles.body}>
          {user.id && (
            <div className={cn(styles.content, 'u-margin-y--large')}>
              <StepSelector
                className="u-margin-bottom--large"
                activeStep={step}
                invalid={invalidStep}
                onStep1Selection={() => setStep(1)}
                onStep2Selection={nextStep}
              />
              <div className={cn(styles.forms, { [styles['forms--step2']]: step === 2 })}>
                <Step1Form
                  ref={step1FormRef}
                  company={company}
                  brands={brands}
                  teams={teams}
                  customFields={customFields}
                  onSubmit={submitCandidateData}
                  onClick={nextStep}
                  setInvalid={setInvalidStep}
                  users={companyUsers}
                  defaultValues={defaultValues}
                />
                <Step2Form
                  brands={brands}
                  companyChecks={companyChecks}
                  forms={forms}
                  targetForm={targetForm}
                  bundles={bundles}
                  useDocuments
                  allowUploads
                  getBrandUploads={onGetBrandUploads}
                  onSubmit={submit}
                  stepBack={() => setStep(1)}
                  getUpload={getUpload}
                  createUpload={createUpload}
                  deleteUpload={deleteUpload}
                  signUpload={signUpload}
                />
              </div>
            </div>
          )}
        </div>
        <ExistingCandidateConfirmation
          showExistingCandidateConfirmation={showExistingCandidateConfirmation}
          setShowExistingCandidateConfirmation={setShowExistingCandidateConfirmation}
          error={existingCandidateError}
          onSubmit={submit}
          candidateChecksData={candidateChecksData}
          createCandidateCheck={createCandidateCheck}
          onSuccess={() => navigate('/dashboard')}
        />
      </div>
    </AppPage>
  );
};

export default NewCheck;

const ExistingCandidateConfirmation = ({
  showExistingCandidateConfirmation,
  setShowExistingCandidateConfirmation,
  error,
  candidateChecksData,
  createCandidateCheck,
  onSuccess,
  onSubmit,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const candidate = useMemo(() => {
    try {
      return JSON.parse(error?.log) || null;
    } catch {
      return null;
    }
  }, [error]);

  const actions = useMemo(
    () => [
      {
        label: t('newCheck.AddCheckToExistingCandidate'),
        type: 'primary',
        handler: async () => {
          await createCandidateCheck({
            candidateId: candidate.id,
            data: candidateChecksData.checks,
          });
          dispatch(
            messagePopUp({
              text: t('newCheck.CheckAddedToExistingCandidate'),
              state: MESSAGE_STATE_SUCCESS,
              hide: true,
            }),
          );
          navigate(`/check/${candidate.id}/background_checks`);
        },
      },
      {
        label: t('newCheck.SaveAsNewCandidate'),
        type: 'secondary',
        handler: () => {
          onSubmit(candidateChecksData, true);
        },
      },
      {
        label: t('newCheck.GoToExistingCandidate'),
        type: 'secondary',
        handler: () => navigate(`/check/${candidate.id}`),
      },
      {
        label: t('newCheck.CancelCheck'),
        type: 'secondary',
        handler: () => onSuccess(),
      },
    ],
    [candidate, candidateChecksData, createCandidateCheck, onSuccess, onSubmit],
  );

  const handleAction = async handler => {
    setLoading(true);
    try {
      await handler();
    } catch (err) {
      dispatch(
        messagePopUp({
          text: t('newCheck.FailedToProcessRequest'),
          state: MESSAGE_STATE_ERROR,
          hide: true,
        }),
      );
      console.error('Action failed:', err);
    } finally {
      setLoading(false);
      setShowExistingCandidateConfirmation(false);
    }
  };

  return (
    <AnimatedModal
      visible={showExistingCandidateConfirmation}
      title={t('newCheck.CandidateAlreadyExists')}
      small
      noClose
      description={
        candidate?.email
          ? t('newCheck.CandidateAlreadyExistsDescription', { email: candidate.email })
          : t('newCheck.CandidateAlreadyExistsDescription')
      }>
      <div className="d-flex flex-column">
        {actions.map(({ label, type, handler }) => (
          <Button
            key={label}
            type={type}
            onClick={() => handleAction(handler)}
            disabled={loading}
            className="u-margin-bottom">
            {label}
          </Button>
        ))}
      </div>
    </AnimatedModal>
  );
};
