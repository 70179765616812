import { useState } from 'react';

import { useTranslation } from 'react-i18next';

import { AnimatedModal } from 'components/AnimatedModal/AnimatedModal';
import { Button, InputGroup, Select } from 'components/FormComponents';

export const BrandSelectionModal = ({ visible, brands, onUpdate }) => {
  const { t } = useTranslation();
  const [selectedBrand, setSelectedBrand] = useState(null);

  const handleBrandChange = e => {
    const brand = (brands || [])?.find(b => b.id === e.target.value);
    setSelectedBrand(brand);
  };

  const handleSubmit = e => {
    e.preventDefault();
    onUpdate(selectedBrand);
  };

  return (
    <AnimatedModal
      visible={visible}
      title={t('candidate.check.selectBrandTitle')}
      small
      icon="droplet"
      noClose
      description={t('candidate.check.noBrandFoundDescription')}>
      <form onSubmit={handleSubmit} className="d-flex flex-column">
        <InputGroup title="Brands" className="u-margin-bottom--large">
          <Select
            name="brand_id"
            placeholder="Brand"
            value={selectedBrand?.id || ''}
            inputProps={{
              onChange: handleBrandChange,
            }}
            useDefault>
            {brands.map(brand => (
              <Select.Item key={brand.id} value={brand.id}>
                {brand.name}
              </Select.Item>
            ))}
          </Select>
        </InputGroup>
        <Button type="primary" submit disabled={!selectedBrand} className="u-margin-bottom">
          {t('candidate.check.updateBrand')}
        </Button>
      </form>
    </AnimatedModal>
  );
};
