import React, { useRef, useState } from 'react';

import cn from 'classnames';

import { useClickOutside } from '../../hooks/click-outside';
import styles from './CopyEmail.module.scss';

const CopyEmail = ({ open, title, emailLink, setEmailLinkOpened, linkType = 'email' }) => {
  const handleLinkCopy = () => {
    linkInput.current.select();
    document.execCommand('copy');
    setCopied(true);
  };

  const [copied, setCopied] = useState(false);
  const linkInput = useRef();
  const emailPopupRef = useRef();

  const button_text = copied ? `${linkType} copied` : `copy ${linkType}`;

  useClickOutside(emailPopupRef, () => setEmailLinkOpened(false));

  return (
    <div ref={emailPopupRef} className={cn(styles.copyEmail, { [styles.disabled]: !open })}>
      <div className={styles.header}>
        <div className={styles.title}>{title}</div>
        <a className={styles.copy} onClick={handleLinkCopy}>
          {button_text}
        </a>
      </div>
      <input ref={linkInput} value={emailLink} readOnly />
    </div>
  );
};

export default CopyEmail;
