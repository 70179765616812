import { useEffect } from 'react';

import cn from 'classnames';
import { Badge } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';

import { Button, Input, InputGroup, PhoneNumberPicker } from 'components/FormComponents';

import styles from './Profile.module.scss';

export const Profile = ({ user, company, updateUser = () => {}, setLoading = () => {} }) => {
  const {
    register,
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    reset({
      first_name: user.first_name,
      last_name: user.last_name,
      contact_number: user.contact_number,
      email: user.email,
    });
  }, [user]);

  const onSubmit = async data => {
    setLoading(true);
    await updateUser(data);
    setLoading(false);
  };

  return (
    <form className={cn(styles.root)} onSubmit={handleSubmit(onSubmit)}>
      <div className={cn('card--small', 'card', 'card-with-border')}>
        <div className="u-margin--large">
          <div className={cn('u-flex-box', 'u-flex-justify-between', 'u-flex-align-center')}>
            <h1 className="title-2">Profile</h1>
          </div>
          <p className="t-small">Edit the details of your profile below.</p>
        </div>
        <div className={cn(styles.content, 'background-secondary', 'u-padding')}>
          <div className={cn('card', 'card-with-border', 'u-padding')}>
            <div className={styles.inlineInputs}>
              <InputGroup title="Email">
                <Input
                  name="email"
                  placeholder="kohn.doe@checkmate.tech"
                  register={register}
                  disabled={true}
                  icon="lock"
                />
              </InputGroup>
              <InputGroup title="First Name">
                <Input
                  name="first_name"
                  placeholder="John"
                  register={register}
                  validators={{ required: true }}
                  error={errors?.first_name?.type}
                  inputProps={{ tabIndex: 1 }}
                />
              </InputGroup>
              <InputGroup title="Last Name">
                <Input
                  name="last_name"
                  placeholder="Doe"
                  register={register}
                  validators={{ required: true }}
                  error={errors?.last_name?.type}
                  inputProps={{ tabIndex: 2 }}
                />
              </InputGroup>
            </div>
            <InputGroup title="Contact Number">
              <Controller
                control={control}
                name="contact_number"
                render={({ field: { onChange, value } }) => (
                  <PhoneNumberPicker
                    value={value}
                    defaultCountry={company?.country}
                    onChange={onChange}
                    tabIndex={3}
                  />
                )}
              />
            </InputGroup>
            <InputGroup title="Teams">
              {user.teams.map(team => (
                <Badge className={cn('u-margin-right', styles.badge)}>{team}</Badge>
              ))}
            </InputGroup>
            <InputGroup title="Roles">
              {user.roles.map(role => (
                <Badge className={cn('u-margin-right', styles.badge)}>{role}</Badge>
              ))}
            </InputGroup>
          </div>
        </div>
        <div className={cn('card_footer', 'u-flex-box', 'u-flex-justify-end')}>
          <Button submit>Save</Button>
        </div>
      </div>
    </form>
  );
};
