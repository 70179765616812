import cn from 'classnames';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { EditableSettings } from 'components/EditableSettings/EditableSettings';
import { Button, Input, InputGroup, Textarea, ToggleSwitch } from 'components/FormComponents';

export const Emails = ({
  register,
  control,
  sendCandidateEmailPreview = () => {},
  sendRefereeEmailPreview = () => {},
}) => {
  const { t } = useTranslation();

  const emailActiveValue = useWatch({ control, name: 'email_active' });
  const emailCandidateActiveValue = useWatch({ control, name: 'email_candidate_active' });
  const emailRefereeActiveValue = useWatch({ control, name: 'email_referee_active' });
  const smsActiveValue = useWatch({ control, name: 'sms_active' });
  const smsCandidateActiveValue = useWatch({ control, name: 'sms_candidate_active' });
  const smsRefereeActiveValue = useWatch({ control, name: 'sms_referee_active' });

  return (
    <div className="background-secondary u-padding">
      <div className={cn('card', 'card-with-border', 'u-padding')}>
        <div className="u-margin-bottom">
          <InputGroup>
            <ToggleSwitch
              register={register}
              name="use_email_communication"
              label={t('branding.communication.useEmailCommunication')}
            />
          </InputGroup>

          <InputGroup>
            <ToggleSwitch
              register={register}
              name="use_sms_communication"
              label={t('branding.communication.useSmsCommunication')}
            />
          </InputGroup>
        </div>

        <EditableSettings
          title={t('branding.communication.useCustomizedEmails')}
          open={emailActiveValue}
          toggleable
          register={register}
          name="email_active"
          className="u-margin-bottom">
          <EditableSettings
            title={t('branding.communication.useCustomizedEmailsForCandidate')}
            open={emailCandidateActiveValue}
            toggleable
            register={register}
            name="email_candidate_active">
            <InputGroup title={t('branding.communication.subject')}>
              <Input
                register={register}
                name="email_candidate_subject"
                placeholder={t('branding.communication.emailCandidateSubjectPlaceholder')}
              />
            </InputGroup>

            <InputGroup title={t('branding.communication.body')}>
              <Textarea
                name="email_candidate_copy"
                register={register}
                placeholder={t('branding.communication.emailCandidateBodyPlaceholder')}
                inputProps={{ rows: 10 }}
              />
            </InputGroup>

            <div className={cn('u-flex-box', 'u-width-100', 'u-flex-justify-end')}>
              <Button
                type="secondary"
                className="u-margin-bottom--small"
                onClick={sendCandidateEmailPreview}>
                {t('branding.communication.previewCandidateEmail')}
              </Button>
            </div>
          </EditableSettings>

          <EditableSettings
            title={t('branding.communication.useCustomizedEmailsForReferee')}
            open={emailRefereeActiveValue}
            toggleable
            register={register}
            name="email_referee_active">
            <InputGroup title={t('branding.communication.body')}>
              <Textarea
                name="email_referee_copy"
                register={register}
                placeholder={t('branding.communication.emailRefereeBodyPlaceholder')}
                inputProps={{ rows: 10 }}
              />
            </InputGroup>

            <div className={cn('u-flex-box', 'u-width-100', 'u-flex-justify-end')}>
              <Button
                type="secondary"
                className="u-margin-bottom--small"
                onClick={sendRefereeEmailPreview}>
                {t('branding.communication.previewRefereeEmail')}
              </Button>
            </div>
          </EditableSettings>
        </EditableSettings>

        <EditableSettings
          title={t('branding.communication.useCustomizedSms')}
          open={smsActiveValue}
          toggleable
          register={register}
          name="sms_active"
          className="u-margin-bottom">
          <EditableSettings
            title={t('branding.communication.useCustomizedSmsForCandidate')}
            className="u-margin-bottom"
            open={smsCandidateActiveValue}
            toggleable
            register={register}
            name="sms_candidate_active">
            <InputGroup title={t('branding.communication.body')}>
              <Textarea
                name="sms_candidate_copy"
                register={register}
                placeholder={t('branding.communication.smsCandidateBodyPlaceholder')}
                inputProps={{ rows: 10 }}
              />
            </InputGroup>
          </EditableSettings>

          <EditableSettings
            title={t('branding.communication.useCustomizedSmsForReferee')}
            open={smsRefereeActiveValue}
            toggleable
            register={register}
            name="sms_referee_active">
            <InputGroup title={t('branding.communication.body')}>
              <Textarea
                name="sms_referee_copy"
                register={register}
                placeholder={t('branding.communication.smsRefereeBodyPlaceholder')}
                inputProps={{ rows: 10 }}
              />
            </InputGroup>
          </EditableSettings>
        </EditableSettings>
      </div>
    </div>
  );
};
