import { useState } from 'react';

import { useForm } from 'react-hook-form';

import { AnimatedModal } from 'components/AnimatedModal/AnimatedModal';
import { Button, InputGroup, MentionInput, Select } from 'components/FormComponents';
import { HistoryRow } from 'components/HistoryRow/HistoryRow';

import styles from './AddHistoryModal.module.scss';

export const AddHistoryModal = ({
  visible,
  candidate,
  company,
  user,
  companyChecks,
  companyUsers,
  onSave = () => {},
  onClose = () => {},
}) => {
  const {
    register,
    watch,
    reset,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  const [taggedUsers, setTaggedUsers] = useState([]);

  const onSelectUser = user => {
    setTaggedUsers(prev => [...new Set([...prev, user?.email])]);
  };

  const onSubmit = data => {
    onSave({ ...data, user_name: user.name, tagged_users: taggedUsers });
    reset();
    onClose();
  };

  return (
    <AnimatedModal
      title="Add history record"
      visible={visible}
      onClose={onClose}
      className={styles.modal}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputGroup title="Type" className="u-margin-bottom--large">
          <Select name="type" register={register} value={watch('type')} useDefault>
            <Select.Item value={27}>Note</Select.Item>
            <Select.Item value={28}>Info</Select.Item>
            <Select.Item value={29}>Success</Select.Item>
            <Select.Item value={30}>Warning</Select.Item>
            <Select.Item value={31}>Error</Select.Item>
          </Select>
        </InputGroup>
        {companyUsers && (
          <MentionInput
            control={control}
            name="message"
            companyUsers={companyUsers}
            validators={{ required: true }}
            error={errors?.message?.type}
            placeholder="Tag someone with @ to mention them"
            onSelect={onSelectUser}
          />
        )}
        <div className={styles.previewText}>preview</div>
        <HistoryRow
          company={company}
          candidate={candidate}
          entry={{ user_name: user.name, message: watch('message'), type: watch('type') }}
          companyChecks={companyChecks}
          companyUsers={companyUsers || []}
          className={styles.previewRow}
        />

        <div className="u-flex-box u-flex-justify-end">
          <Button submit className="u-margin-top--large u-width-100">
            Save
          </Button>
        </div>
      </form>
    </AnimatedModal>
  );
};
