import { useState } from 'react';

import cn from 'classnames';
import { FormTypes } from 'constants/forms';
import { useTranslation } from 'react-i18next';

import { AnimatedModal } from 'components/AnimatedModal/AnimatedModal';
import { FormEdit } from 'components/Form/FormEdit/FormEdit';
import { Button, FileContentsPicker, InputGroup } from 'components/FormComponents';
import { List } from 'components/List/List';

import { useGetFormFromFileMutation } from 'api/forms-api';

export const ReferenceForms = ({
  forms,
  company,
  teams,
  onSave = () => {},
  deleteForm = () => {},
  onArchive = () => {},
  setLoading = () => {},
}) => {
  const { t } = useTranslation();
  const [form, setForm] = useState();
  const [showJDAIGenerator, setShowJDAIGenerator] = useState(false);
  const [showQuestionnaireGenerator, setShowQuestionnaireGenerator] = useState(false);

  const [createFromFile] = useGetFormFromFileMutation();

  const createNewForm = () => {
    setForm({ name: 'New Form', team_id: null, type: FormTypes.REFEREE, fields: [] });
  };

  const onCreateFromFormDescription = async fileData => {
    setLoading(true);
    setShowJDAIGenerator(false);

    const { data: questions } = await createFromFile({
      from: 'job_description',
      file_data: fileData,
    });

    setForm({ ...form, fields: questions });
    setLoading(false);
  };

  const onCreateFromFormQuestionnaire = async fileData => {
    setLoading(true);
    setShowQuestionnaireGenerator(false);

    const { data: questions } = await createFromFile({
      from: 'questionnaire',
      file_data: fileData,
    });

    setForm({ ...form, fields: questions });
    setLoading(false);
  };

  const handleSave = async data => {
    const updatedForm = await onSave(data);
    if (updatedForm) setForm(updatedForm);
  };

  const copyFromForm = copyFrom => {
    if (!copyFrom) return;

    let newForm = { ...copyFrom };
    delete newForm.id;

    newForm.name += ' (Copy)';

    newForm.fields.map(field => {
      let newField = { ...field };
      delete newField.id;
      return newField;
    });

    setForm(newForm);
  };

  const handleArchive = () => {
    if (form?.id) {
      onArchive(form);
      setForm(null);
    }
  };

  return (
    <div className={cn('u-flex-box', 'u-flex-align-start')}>
      <FileSubmittionModal
        title={t('forms.referencePlus.aiGenerator')}
        description={t('forms.referencePlus.aiGeneratorDescriptionOne')}
        visible={showJDAIGenerator}
        onClose={() => setShowJDAIGenerator(false)}
        onSubmit={onCreateFromFormDescription}
      />

      <FileSubmittionModal
        title={t('forms.referencePlus.aiGenerator')}
        description={t('forms.referencePlus.aiGeneratorDescriptionTwo')}
        visible={showQuestionnaireGenerator}
        onClose={() => setShowQuestionnaireGenerator(false)}
        onSubmit={onCreateFromFormQuestionnaire}
      />

      <List
        title={t('common.forms')}
        subtitle={t('forms.referencePlus.subtitle')}
        className="u-margin-right--large u-flex-align-self-normal"
        onNew={createNewForm}>
        <List.Category title={t('forms.typequestionnaire')}>
          {forms.map(form => (
            <List.Item key={form.id} title={form.name} value={form} onClick={setForm} />
          ))}
        </List.Category>
      </List>
      {form && (
        <FormEdit
          company={company}
          form={form}
          teams={teams}
          onCopy={copyFromForm}
          onSave={handleSave}
          onDelete={deleteForm}
          onClose={() => setForm(null)}
          onArchive={handleArchive}
          aiGenerators
          onJDAIGenerator={() => setShowJDAIGenerator(true)}
          onAIQuestionnaireGenerator={() => setShowQuestionnaireGenerator(true)}
        />
      )}
    </div>
  );
};

const FileSubmittionModal = ({
  title,
  description,
  visible,
  onClose = () => {},
  onSubmit = () => {},
}) => {
  const [fileData, setFileData] = useState();

  const handleSubmittion = () => onSubmit(fileData);

  return (
    <AnimatedModal title={title} visible={visible} onClose={onClose}>
      <div>
        <InputGroup>
          <p className="t-small u-margin-bottom--large u-text-centered">{description}</p>
          <FileContentsPicker
            accept={{ 'application/pdf': ['.pdf'], 'application/msword': ['.doc', '.docx'] }}
            onChange={setFileData}
          />
        </InputGroup>
        <div className="d-flex u-width-100 justify-content-end">
          <Button
            type="secondary"
            onClick={onClose}
            className="u-width-100 u-margin-top u-margin-right">
            Cancel
          </Button>
          <Button icon="search" className="u-width-100 u-margin-top" onClick={handleSubmittion}>
            Process Document
          </Button>
        </div>
      </div>
    </AnimatedModal>
  );
};
